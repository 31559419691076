import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

const QuestionsStateContext = createContext({
  selected: 0 as number | string | boolean | undefined,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setSelectedState: (_value: number | string | boolean | undefined) => {},
  selectedTwo: "",
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setSelectedStateTwo: (_value: string) => {},
  hasMedicalCondition: undefined as boolean | undefined,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setHasMedicalConditionState: (_value: boolean | undefined) => {},
});

export const useQuestionsState = () => useContext(QuestionsStateContext);

function QuestionsStateProvider({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
  const [selected, setSelected] = useState<
    number | string | boolean | undefined
  >(0);
  const [selectedTwo, setSelectedValueTwo] = useState<string>("");
  const [hasMedicalCondition, setHasMedicalCondition] = useState<
    boolean | undefined
  >(undefined);

  const setSelectedState = useCallback(
    (value: number | string | boolean | undefined) => {
      setSelected(value);
    },
    []
  );

  const setSelectedStateTwo = useCallback((value: string) => {
    setSelectedValueTwo(value);
  }, []);

  const setHasMedicalConditionState = useCallback(
    (value: boolean | undefined) => {
      setHasMedicalCondition(value);
    },
    []
  );

  const value = useMemo(
    () => ({
      selected,
      setSelectedState,
      selectedTwo,
      setSelectedStateTwo,
      hasMedicalCondition,
      setHasMedicalConditionState,
    }),
    [
      selected,
      selectedTwo,
      setSelectedState,
      setSelectedStateTwo,
      hasMedicalCondition,
      setHasMedicalConditionState,
    ]
  );

  return (
    <QuestionsStateContext.Provider value={value}>
      {children}
    </QuestionsStateContext.Provider>
  );
}

export default QuestionsStateProvider;
