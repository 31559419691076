/* eslint-disable no-param-reassign */
// eslint-disable-next-line check-file/filename-naming-convention
import { useEffect, useMemo, useState } from "react";
import {
  DateRangerPicker,
  Modal,
  SingleDatePicker,
} from "staysure-component-library";

import { TextButton } from "@/components/molecules/cta-button/cta-buttons";
import withBrand from "@/components/with-brand";
import {
  AnnualMultiTrip,
  NotSure,
  QuestionFive,
  SameDay,
  SameLocation,
  SingleTrip,
} from "@/config/app-constant";
import useGtmPopup from "@/hooks/gtm/useGtmPopup";
import { TravelDateAnswer, DateRange } from "@/types/TravelDateType";
import {
  checkCurentLocation,
  isValidDate,
  maxDateForTraveller,
  returnCalculateDate,
  setAMTEndDate,
  stripTimeFromDate,
} from "@/utils/travel-date.utils";

import Styles from "./travel-date-answer-page.module.css";
import useWindowSize from "@/hooks/useWindowSize/useWindowSize";
import { MOBILE_BREAKPOINT } from "@/constants";

type TravelDateAswerProps = {
  setSelectedAnswer: (answer: TravelDateAnswer) => void;
  selectedAnswer?: number | null;
  policyType?: string;
  fromLocation?: string;
  toLocation?: string;
  startDateSaved?: string;
  endDateSaved?: string;
  sdtButtonText?: string;
  sdtHeaderText?: string;
  sdtSubText?: string;
  thcButtonText?: string;
  cancelBtnText?: string;
  thcHeaderText?: string;
  thcSubText?: string;
  tripStartDateSaved?: string | undefined;
  tripEndDateSaved?: string | undefined;
  departureDateErrorPastDateST?: string;
  departureDateErrorFutureDateST?: string;
  returnDateErrorPastDateST?: string;
  returnDateErrorFutureDateST?: string;
  departureDateErrorPastDateAMT?: string;
  departureDateErrorFutureDateAMT?: string;
  departureReturnDateError?: string;
  brand?: "avanti" | "staysure";
};

function TravelDateAnswerPage({
  setSelectedAnswer,
  policyType,
  fromLocation,
  toLocation,
  sdtButtonText,
  sdtHeaderText,
  sdtSubText,
  thcButtonText,
  cancelBtnText,
  thcHeaderText,
  thcSubText,
  tripStartDateSaved,
  tripEndDateSaved,
  departureDateErrorPastDateST = "Please enter a valid depature date",
  departureDateErrorFutureDateST = "Please enter a valid depature date",
  returnDateErrorPastDateST = "Please enter a valid return date",
  returnDateErrorFutureDateST = "Please enter a valid return date",
  departureDateErrorPastDateAMT = "Please enter a valid start date",
  departureDateErrorFutureDateAMT = "Please select when you would like your policy to start (up to 90 days from today)",
  // departureReturnDateError = "Please enter a valid departure and return date",
  brand = "staysure",
}: Readonly<TravelDateAswerProps>) {
  const maxFromDate = useMemo(() => {
    const maxDate = returnCalculateDate(
      new Date(),
      null,
      null,
      maxDateForTraveller
    );
    return maxDate;
  }, []);

  const maxFromDateReturn = useMemo(() => {
    const maxDate = returnCalculateDate(
      maxFromDate,
      null,
      null,
      maxDateForTraveller
    );
    return maxDate;
  }, [maxFromDate]);

  const [maxDateLimit, setMaxDateLimit] = useState<Date>(maxFromDate);
  const [tripStartDate, setTripStartDate] = useState<Date | null>();
  const [tripEndDate, setTripEndDate] = useState<Date | null>();
  const [tripStartDateAMT, setTripStartDateAMT] = useState<Date | null>();
  const [isError, setIsError] = useState<boolean>(false);
  const [testInputError, setTestInputError] = useState<string>("");

  const [cancelBtnClick, setCancelBtnClick] = useState<boolean>(false);

  const [modalType, setModalType] = useState<
    "SameLocation" | "SameDay" | undefined
  >(undefined);

  const object: TravelDateAnswer = {
    validate: false,
    startDate: null,
  };

  useEffect(() => {
    if (tripStartDateSaved && tripEndDateSaved && policyType) {
      const modifiedStartDate = new Date(tripStartDateSaved);
      const modifiedEndDate = new Date(tripEndDateSaved);

      if (policyType === AnnualMultiTrip) {
        setTripStartDateAMT(new Date(tripStartDateSaved));
      } else {
        setTripStartDate(modifiedStartDate);
        setTripEndDate(modifiedEndDate);
      }
      if (
        stripTimeFromDate(modifiedStartDate) >= stripTimeFromDate(new Date())
      ) {
        object.validate = true;
        object.startDate = modifiedStartDate;
        object.endDate = modifiedEndDate;
        setSelectedAnswer(object);
      } else {
        setIsError(true);
        setTestInputError("Please enter a valid departure date");
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tripStartDateSaved, tripEndDateSaved, policyType]);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const dateDifferenceInDays = (startDate: Date, endDate: Date): number => {
    if (!startDate || !endDate) {
      return NaN;
    }
    const diffInTime = endDate.getTime() - startDate.getTime();
    const diffInDays = diffInTime / (1000 * 3600 * 24);
    if (diffInDays < 0) {
      return NaN;
    }
    return Math.round(diffInDays);
  };

  const handleDateChangeForOnChange = (date: Date[] | null) => {
    setCancelBtnClick(false);
    setIsError(false);
    setTestInputError("");
    let validate = false;
    const currentDate = new Date();

    if (date && date[0]) {
      setTripStartDate(date[0]);
      const newMaxDateLimit = returnCalculateDate(
        date[0],
        null,
        null,
        maxDateForTraveller
      );
      setMaxDateLimit(newMaxDateLimit);
    }

    if (date && !date[0]) {
      setTripStartDate(null);
    }
    if (date && !date[1]) {
      setTripEndDate(null);
    }

    if (date && date[0] && date[1] && date[1] >= date[0]) {
      // if (date && date[0]) {
      //   setTripStartDate(date[0]);
      //   const newMaxDateLimit = returnCalculateDate(date[0], null, 18, null);
      //   // eslint-disable-next-line prefer-destructuring
      //   setMaxDateLimit(newMaxDateLimit);
      // }
      if (date[0] > maxFromDate) {
        setIsError(true);
        setTestInputError(departureDateErrorFutureDateST || "");
        setTripStartDate(null);
        validate = false;
      } else if (date[1] > maxFromDateReturn) {
        setIsError(true);
        setTestInputError(returnDateErrorFutureDateST || "");
        setTripEndDate(null);
        validate = false;
      } else if (
        isValidDate(date[0]) &&
        isValidDate(date[1]) &&
        stripTimeFromDate(date[0]) > stripTimeFromDate(date[1])
      ) {
        setIsError(true);
        setTestInputError(returnDateErrorFutureDateST || "");
        setTripStartDate(null);
        setTripEndDate(null);
        validate = false;
      } else if (
        date &&
        date[0] &&
        (policyType === SingleTrip || policyType === NotSure) &&
        setAMTEndDate(currentDate) === setAMTEndDate(date[0])
      ) {
        setTripEndDate(date[1]);
        setModalType(SameDay);
        setIsModalOpen(true);
      } else if (
        date &&
        (policyType === SingleTrip || policyType === NotSure) &&
        date[0] &&
        date[1] &&
        checkCurentLocation(fromLocation || "", toLocation || "") &&
        dateDifferenceInDays(date[0], date[1]) < 2
      ) {
        setModalType(SameLocation);
        setIsModalOpen(true);
        setTripStartDate(date[0]);
        setTripEndDate(date[1]);
        validate = false;
      } else if (date && date[0] && date[1]) {
        setTripEndDate(date[1]);
        validate = true;
      }

      // eslint-disable-next-line prefer-destructuring
    }

    if (date && date[0] && date[1]) {
      object.validate = validate;
      object.startDate = date[0] || "";
      object.endDate = date[1] || "";
    } else {
      object.validate = false;
      object.startDate = undefined;
      object.endDate = undefined;
    }
    setSelectedAnswer(object);
  };

  useGtmPopup({
    tripType: QuestionFive,
    isPopupOpen: isModalOpen,
    popupName: modalType === SameDay ? sdtHeaderText : thcHeaderText,
  });

  const handleDateChangeForTextChange = (date: Date[] | null) => {
    setCancelBtnClick(false);
    const currentDate = new Date();
    setTripStartDate(null);
    setTripEndDate(null);
    let validate = true;

    if (date && date[0] && isValidDate(date[0])) {
      if (stripTimeFromDate(date[0]) < stripTimeFromDate(currentDate)) {
        setIsError(true);
        setTestInputError(departureDateErrorPastDateST || "");
        setTripStartDate(null);
        setTripEndDate(null);
        validate = false;
      } else if (stripTimeFromDate(date[0]) > stripTimeFromDate(maxDateLimit)) {
        setIsError(true);
        setTestInputError(departureDateErrorFutureDateST || "");
        setTripStartDate(null);
        setTripEndDate(null);
        validate = false;
      } else if (
        stripTimeFromDate(date[0]) <= stripTimeFromDate(maxDateLimit)
      ) {
        setIsError(false);
        setTestInputError("");
        const newMaxDateLimit = returnCalculateDate(
          date[0],
          null,
          null,
          maxDateForTraveller
        );
        setMaxDateLimit(newMaxDateLimit);
        setTripStartDate(date[0]);
        object.startDate = date[0] || "";
      }
    } else {
      setIsError(true);
      setTestInputError(departureDateErrorPastDateST || "");
      setTripStartDate(null);
      setTripEndDate(null);
      validate = false;
    }

    if (validate && date && date[1] && !isValidDate(date[1])) {
      setIsError(true);
      setTestInputError(returnDateErrorFutureDateST || "");
      setTripEndDate(null);
      validate = false;
    }

    // To check whether the date[1] is more than the 18 months
    if (date && date[1] && validate && isValidDate(date[1])) {
      if (date[1] > maxDateLimit) {
        setIsError(true);
        setTestInputError(returnDateErrorFutureDateST || "");
        setTripEndDate(null);
        validate = false;
      }
    }

    // To avoid selecting dates more than the maxFromDate
    if (date && validate && date[0] > maxFromDate) {
      setIsError(true);
      setTestInputError(departureDateErrorFutureDateST || "");
      setTripStartDate(null);
      validate = false;
    }

    if (
      date &&
      validate &&
      isValidDate(date[1]) &&
      stripTimeFromDate(date[1]) < stripTimeFromDate(currentDate)
    ) {
      setIsError(true);
      setTestInputError(returnDateErrorPastDateST || "");
      setTripStartDate(null);
      validate = false;
    }

    if (
      date &&
      validate &&
      isValidDate(date[0]) &&
      isValidDate(date[1]) &&
      stripTimeFromDate(date[0]) > stripTimeFromDate(date[1])
    ) {
      setIsError(true);
      setTestInputError(returnDateErrorPastDateST || "");
      setTripStartDate(null);
      validate = false;
    }

    if (
      validate &&
      date &&
      date[0] &&
      date[1] &&
      isValidDate(date[0]) &&
      isValidDate(date[1])
    ) {
      // Check whether from date is in the past
      if (
        isValidDate(date[0]) &&
        isValidDate(date[1]) &&
        stripTimeFromDate(date[1]) < stripTimeFromDate(currentDate)
      ) {
        setIsError(true);
        setTestInputError(returnDateErrorPastDateST || "");
        setTripEndDate(null);
        validate = false;
      } else if (
        isValidDate(date[0]) &&
        isValidDate(date[1]) &&
        stripTimeFromDate(date[0]) > stripTimeFromDate(date[1])
      ) {
        setIsError(true);
        setTestInputError(returnDateErrorFutureDateST || "");
        setTripStartDate(null);
        setTripEndDate(null);
        validate = false;
      } else if (
        (policyType === SingleTrip || policyType === NotSure) &&
        isValidDate(date[0]) &&
        setAMTEndDate(currentDate) === setAMTEndDate(date[0])
      ) {
        setTripEndDate(date[1]);
        setModalType(SameDay);
        setIsModalOpen(true);
        validate = false;
      } else if (
        (policyType === SingleTrip || policyType === NotSure) &&
        isValidDate(date[0]) &&
        isValidDate(date[1]) &&
        checkCurentLocation(fromLocation || "", toLocation || "") &&
        dateDifferenceInDays(date[0], date[1]) < 2
      ) {
        setModalType(SameLocation);
        setIsModalOpen(true);
        setTripStartDate(date[0]);
        setTripEndDate(date[1]);
        validate = false;
      } else {
        validate = true;
      }
    }

    if (
      date &&
      date[0] &&
      date[1] &&
      isValidDate(date[0]) &&
      isValidDate(date[1])
    ) {
      object.validate = validate;
      object.startDate = date[0] || "";
      object.endDate = date[1] || "";
      setSelectedAnswer(object);
    } else {
      object.validate = validate;
      object.startDate = undefined;
      object.endDate = undefined;
      setSelectedAnswer(object);
    }
  };

  // const handleInvalidDates = (e: DateRangeValidationError) => {
  //   if (e[0] === null && e[1] === null) {
  //     setTestInputError("");
  //   } else {
  //     setTestInputError("Invalid date");
  //   }
  // };

  // For AMT
  const maxFromDateAMT = useMemo(() => {
    const maxDate = returnCalculateDate(new Date(), null, null, 90);
    return maxDate;
  }, []);

  const [isErrorAMT, setIsErrorAMT] = useState<boolean>(false);
  const [tripStartDateErrorMessageAMT, setTripStartDateErrorMessageAMT] =
    useState<string>("");

  const handleDateChangeForAMT = (date: Date) => {
    setCancelBtnClick(false);
    const currentDate = new Date();

    if (setAMTEndDate(date) < setAMTEndDate(currentDate)) {
      setIsErrorAMT(true);
      setTripStartDateErrorMessageAMT(departureDateErrorPastDateAMT || "");
      // Remove the date becoming null when the date is invalid
      // setTripStartDateAMT(null);
      object.startDate = undefined;
      object.endDate = undefined;
      setSelectedAnswer(object);
      return;
    }

    if (isValidDate(date)) {
      let validate = false;
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth();
      const currentDay = currentDate.getDate();

      const selectedYear = date.getFullYear();
      const selectedMonth = date.getMonth();
      const selectedDay = date.getDate();

      if (date > maxFromDateAMT) {
        setIsErrorAMT(true);
        setTripStartDateErrorMessageAMT(departureDateErrorFutureDateAMT || "");
        setTripStartDateAMT(null);
        validate = false;
      } else if (
        selectedYear === currentYear &&
        selectedMonth === currentMonth &&
        selectedDay === currentDay
      ) {
        setIsErrorAMT(false);
        setTripStartDateErrorMessageAMT("");
        setTripStartDateAMT(date);
        setModalType(SameDay);
        setIsModalOpen(true);

        validate = false;
      } else {
        setIsErrorAMT(false);
        setTripStartDateErrorMessageAMT("");
        setTripStartDateAMT(date);

        // Reset modal if a different date is selected
        if (modalType === SameDay && isModalOpen) setIsModalOpen(false);

        validate = true;
      }
      if (date) {
        object.validate = validate;
        // newDate.setDate(newDate.getDate() + 364);
        if (validate) {
          object.startDate = date || "";
          object.endDate = date || "";
          setSelectedAnswer(object);
        } else {
          object.startDate = undefined;
          object.endDate = undefined;
          setSelectedAnswer(object);
        }
      }
    } else {
      setIsErrorAMT(true);
      setTripStartDateErrorMessageAMT(departureDateErrorPastDateAMT || "");
      setTripStartDateAMT(null);
      object.startDate = undefined;
      object.endDate = undefined;
      setSelectedAnswer(object);
    }
  };

  const [isCalenderOpen, setIsCalenderOpen] = useState(false);

  const screenWidth = useWindowSize();
  const isMobile = !!screenWidth && screenWidth <= MOBILE_BREAKPOINT;

  return (
    <div
      data-testid="travel-date-answer-page-test-id"
      className={`${Styles.date_picker_wrapper} flex flex-col gap-x-2 md:mt-spacing-l mt-spacing-m 
      w-full relative `}
    >
      {(policyType === SingleTrip || policyType === NotSure) && (
        <DateRangerPicker
          isScroolTop
          onErrorMessage={() => {}}
          defaultDate={
            tripStartDate && tripEndDate
              ? [tripStartDate, tripEndDate]
              : undefined
          }
          calendarType="type1"
          dateRangeMaxLimit={maxDateLimit}
          onChange={(e: DateRange<unknown>) => {
            handleDateChangeForOnChange(e as Date[]);
          }}
          onChangeDateTyping={(e: DateRange<unknown>) => {
            handleDateChangeForTextChange(e as Date[]);
          }}
          customizedErrorMessage={testInputError}
          isError={isError}
          isDateClear={cancelBtnClick}
          isReadOnly={isMobile}
          // onErrorMessage={(e: DateRangeValidationError) =>
          //   handleInvalidDates(e)
          // }
        />
      )}

      <Modal
        className={`${Styles.date_picker_modal} md:p-spacing-xxxl  p-spacing-l md:max-w-[416px] 
        max-w-[327px] w-full`}
        isOpen={
          modalType !== SameDay ? isModalOpen : isModalOpen && !isCalenderOpen
        }
        onOpenChange={(e: boolean) => setIsModalOpen(e)}
        underlayStyles={{
          zIndex: 9999,
          background: "rgba(26, 26, 26, 0.6)",
        }}
        modalStyles={{
          display: "flex",
          background: "white",
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          borderRadius: 8,
        }}
      >
        <div data-testid="modal-data-test-id">
          <h1
            className="text-text-title font-header-font md:text-3xl text-[23px] text-center 
          md:leading-[34.1px] leading-[27.6px] md:mb-spacing-s mb-spacing-xs"
          >
            {modalType === SameDay && sdtHeaderText}
            {modalType === SameLocation && thcHeaderText}
          </h1>
          <div className={` ${Styles.text_content}`}>
            {modalType === SameDay && sdtSubText}
            {modalType === SameLocation && thcSubText}
          </div>
          {modalType === SameLocation && (
            <div className="flex flex-col">
              <TextButton
                data-testid="label-button-wrapper-test-id"
                hierarchy="primary"
                label={thcButtonText || "Confirm"}
                onClick={() => {
                  setIsModalOpen(false);
                }}
                className="m-0 h-[48px] text-text-on-primary font-lg leading-[18px] font-semibold "
              />
            </div>
          )}
          {modalType === SameDay && (
            <div className="flex md:flex-row flex-col">
              <TextButton
                hierarchy="primary"
                data-testid="confirm-button"
                label={(sdtButtonText as string) || "Confirm"}
                onClick={() => {
                  setIsModalOpen(false);
                  if (modalType === SameDay) {
                    object.validate = true;
                    if (tripStartDateAMT && policyType === AnnualMultiTrip) {
                      object.startDate = tripStartDateAMT || "";
                      object.endDate = tripStartDateAMT || "";
                    } else if (
                      tripStartDate &&
                      tripEndDate &&
                      (policyType === SingleTrip || policyType === NotSure)
                    ) {
                      if (
                        setAMTEndDate(tripStartDate) ===
                          setAMTEndDate(new Date()) &&
                        dateDifferenceInDays(tripStartDate, tripEndDate) <= 1 &&
                        checkCurentLocation(
                          fromLocation || "",
                          toLocation || ""
                        )
                      ) {
                        setIsModalOpen(true);
                        setModalType(SameLocation);
                        object.validate = false;
                      } else {
                        object.startDate = tripStartDate || "";
                        object.endDate = tripEndDate || "";
                      }
                    } else {
                      object.validate = false;
                    }
                    setSelectedAnswer(object);
                  }
                }}
                className={` md:mr-2 md:mb-0 mb-2 !h-[48px] md:w-[148px] leading-[18px] w-full text-text-on-primary`}
              />
              <TextButton
                data-testid="cancel-button"
                hierarchy="secondary"
                label={cancelBtnText || "Cancel"}
                onClick={() => {
                  setIsModalOpen(false);
                  if (modalType === SameDay) {
                    object.validate = false; // when cancel button is clicked, continue should disabled
                    setCancelBtnClick(true);
                  }
                }}
                isLeftIcon={false}
                className=" !h-[48px] md:w-[148px] leading-[18px] w-full"
              />
            </div>
          )}
        </div>
      </Modal>

      {policyType === AnnualMultiTrip && (
        <SingleDatePicker
          maximumDate={maxFromDateAMT}
          defaultDate={tripStartDateAMT || undefined}
          onChange={(e: unknown) => handleDateChangeForAMT(e as Date)}
          isError={isErrorAMT}
          customizedMessage={tripStartDateErrorMessageAMT}
          disablePastDates
          isDateClear={cancelBtnClick}
          brand={brand}
          onCalenderViewUpdate={setIsCalenderOpen}
          isReadOnly={isMobile}
          isMobile={isMobile}
        />
      )}
    </div>
  );
}

export default withBrand(TravelDateAnswerPage);
