/* eslint-disable check-file/filename-naming-convention */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Modal } from "staysure-component-library";

import { TextButton } from "@/components/molecules/cta-button/cta-buttons";

type ConfirmationModalProps = {
  isOpen: boolean;
  modalTitleText?: string;
  modalSubText?: string;
  modalConfirmBtnText?: string;
  modalCancelBtnText?: string;
  modalConfirmBtnClick?: (click: boolean) => void;
};

function ConfirmationModal({
  modalTitleText,
  modalSubText,
  modalConfirmBtnText,
  modalCancelBtnText,
  isOpen,
  modalConfirmBtnClick,
}: Readonly<ConfirmationModalProps>) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setIsModalOpen(isOpen);
  }, [isOpen]);

  return (
    <Modal
      className="md:p-spacing-xxxl p-spacing-l md:max-w-[416px] max-w-[327px] w-full"
      data-testid="modal"
      isOpen={isModalOpen}
      isDismissable={false}
      onOpenChange={(open: boolean) => {
        if (!open) {
          setIsModalOpen(false);
        }
      }}
      underlayStyles={{
        zIndex: 9999,
        background: "rgba(26, 26, 26, 0.6)",
      }}
      modalStyles={{
        background: "white",
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        borderRadius: 8,
      }}
    >
      <div className=" flex justify-center w-full flex-col">
        <h1
          className="text-text-title font-header-font md:text-3xl text-[23px] text-center 
           md:leading-[34.1px] leading-[27.6px] md:mb-spacing-s mb-spacing-xs"
          data-testid="toggleBtnLabelWrapper-testid"
        >
          {modalTitleText}
        </h1>
        <p className="text-center mb-spacing-l text-xs leading-[27px] font-normal">
          {modalSubText}
        </p>

        <div className="flex md:flex-row flex-col">
          <TextButton
            id="modal-confirm-button"
            hierarchy="primary"
            data-testid="confirm-button"
            label={modalConfirmBtnText as string}
            onClick={() => {
              setIsModalOpen(false);
              if (modalConfirmBtnClick) {
                modalConfirmBtnClick(true);
              }
            }}
            className={` md:mr-2 md:mb-0 mb-2 !h-[48px] md:w-[148px] leading-[18px] w-full text-text-on-primary`}
          />
          <TextButton
            id="modal-cancel-button"
            hierarchy="secondary"
            data-testid="cancel-button"
            label={modalCancelBtnText as string}
            onClick={() => {
              setIsModalOpen(false);
              if (modalConfirmBtnClick) {
                modalConfirmBtnClick(false);
              }
            }}
            isLeftIcon={false}
            className=" !h-[48px] md:w-[148px] leading-[18px] w-full"
          />
        </div>
      </div>
    </Modal>
  );
}

export default ConfirmationModal;
