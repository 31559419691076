"use client";

import { unwrapResult } from "@reduxjs/toolkit";
import { useRouter, useSearchParams } from "next/navigation";
import React, { useEffect, useState } from "react";
import {
  InlineInfoBox,
  Modal,
  TagSearchField,
} from "staysure-component-library";

import FadeAnimation from "@/components/atoms/animation/fade-animation";
import TransformAnimation from "@/components/atoms/animation/transform-animation";
import ButtonGroup from "@/components/atoms/button-group/button-group";
import LabelButtonWrapper from "@/components/atoms/button-wrapper/button-wrapper";
import { MagnoliaImage } from "@/components/atoms/image/image";
import Loader from "@/components/atoms/loader/loader";
import ConfirmationModal from "@/components/atoms/modal/confirmation-modal";
import RichText from "@/components/atoms/rich-text/rich-text";
import withBrand from "@/components/with-brand";
import {
  AnnualMultiTrip,
  CoverEdit,
  DestinationEdit,
  Europe,
  FinishEdit,
  QuestionFour,
  QuestionThree,
  QuestionTwo,
  Quote,
  QuoteEdit,
  SingleTrip,
  UKOnly,
  Worldwide,
} from "@/config/app-constant";
import { Routes } from "@/config/page-view-constants";
import { PRODUCT_DOMAIN } from "@/constants";
import { useSearchCountry } from "@/hooks/api/addressApi/addressApi";
import useDebounce from "@/hooks/debounce/debounce";
import useGtmPopup from "@/hooks/gtm/useGtmPopup";
import useGtmQuestionSet from "@/hooks/gtm/useGtmQuestionSet";
import useResetStateTravelerEdit from "@/hooks/use-reset-state-traveler-edit/user-reset-state-traveler-edit";
import { useQuestionsState } from "@/providers/questions-state-provider";
import { useAppDispatch } from "@/store/hook";
import {
  saveQuestionDataBFF,
  sessionCheck,
  updateQuoteOnQuestion,
} from "@/store/slices/question/question.action";
import { setQuestionData } from "@/store/slices/question/question.slice";
import { QuoteCountryType } from "@/types/AddressTypes";
import { OptionListProps } from "@/types/LocationFromType";
import { LocationToContainerProps } from "@/types/LocationToType";
import {
  QuestionPayload,
  STCountryArray,
  STCountryObj,
  TripDetails,
} from "@/types/QuestionPayloadType";
import selectLoationHookFlash, {
  modifyPayloadSave,
} from "@/utils/common.utils";
import scrollInputToTop from "@/utils/input-anchoring";
import {
  getRouterPathBackButton,
  getRouterPathMainButton,
} from "@/utils/page-routing.utils";
import {
  travelFromCountryData,
  showLocationFrom,
} from "@/utils/question-location-from.utils";
import selectedBrandImpact, {
  answersQTwoEurope,
  filteredAnswersQTwoWhereGoing,
  answersQTwoWorldwide,
  findAnswerById,
  findAnswerForEuropeId,
  findAnswerWorldwideById,
  findCountryIdByValue,
  findDuplicatesByProperty,
  findFCDOCountry,
  findIdByEuropeValue,
  findIdByWorldWideValue,
  generateTagSearchArray,
  getQuestionTwoState,
} from "@/utils/question-location-to.utils";
import { checkCurentLocation } from "@/utils/travel-date.utils";

import styleComponent from "./location-to-container.module.css";
import ActionButton from "../../action-button/action-button";
import { TextButton } from "../../cta-button/cta-buttons";
import ConfirmationsModal from "../../quote-section/edit-quote-section/confirmation-modal";
import editQuoteStyle from "../../quote-section/edit-quote-section/edit-quote.module.css";
import LocationFromContainer from "../location-from/location-from-container";
import styles from "../question.module.css";

const initialPayload: QuestionPayload = {
  type: "IntermediateData",
  channel: "ONLINE",
  attributes: {
    domain: PRODUCT_DOMAIN,
    lastVisitedPage: "test",
  },
};

function LocationToContainer(props: Readonly<LocationToContainerProps>) {
  const router = useRouter();
  const {
    tripDetails,
    quoteResponse,
    quoteLoading,
    brand,
    formRef,
    ctaRef,
    selectedpolicyType,
    pageContent,
    toLocationCountryDetails,
    expatText,
    expatStatus,
  } = props;

  const {
    questionTwoText,
    questionTwoPlaceHolder,
    modalTitleText,
    modalSubText,
    modalConfirmBtnText,
    modalCancelBtnText,
    sameCountryModalHeaderText,
    sameCountryModalSubText,
    agreeBtnText,
    closeBtnText,
    cancelEditingBtnTextWorldWide,
    cancelEditingBtnTextEurope,
    finishEditingBtnText = FinishEdit,
    popularCountryHeaderText,
    popularCountryList,
  } = pageContent;

  const locationFromContent = pageContent?.locationFrom?.["0"];

  const {
    selected: stateToLocation,
    setSelectedState,
    setSelectedStateTwo,
  } = useQuestionsState();
  const { setToLocationCountryDetails } = props;
  const dispatch = useAppDispatch();
  const searchParams = useSearchParams();
  const editPage = searchParams.get(DestinationEdit);
  const coverEdit = searchParams.get(CoverEdit);
  const [editModal, setEditModal] = useState(false);
  const [valueFilter, setValue] = useState<string | null>(null);
  const [selectedSTCountry, setSelectedSTCountry] = useState<[] | undefined>(
    undefined
  );
  const [sameCountrySelected, setSameCountrySelected] = useState<
    [] | undefined
  >(undefined);

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [selectedAMTEuropeWorldwide, setSelectedAMTEuropeWorldwide] = useState<
    string | undefined
  >(undefined);
  const [addressList, setAddressList] = useState<OptionListProps[] | undefined>(
    undefined
  );

  const [fromCountry, setFomCountry] = useState<string | undefined>(undefined);
  const [fromCountryCode, setFomCountryCode] = useState<string | undefined>(
    undefined
  );
  const [buttonDisableFromLocation, setButtonDisableFromLocation] =
    useState<boolean>(true);
  const { usagePage, buttonDisable } = props;

  const [payload, modifyPayload] = useState<QuestionPayload>(initialPayload);
  const [questionState, getQuestionState] = useState<{
    fromCountrySelected: string | null;
    toCountrySelected: string | null;
    valueSelected: string | null;
    countryArray: [];
  }>({
    fromCountrySelected: null,
    toCountrySelected: null,
    valueSelected: null,
    countryArray: [],
  });
  const [freeSoloTag, setFreeSoloTag] = useState<boolean>(true);
  const [optionListText, setOptionListText] = useState<string>("");

  const [showSameCountryModal, setShowSameCountryModal] = useState(false);
  const [isDisable, setIsDisable] = useState<boolean>(false);

  const [isClient, setIsClient] = useState(false);
  const { resetQuestionData } = useResetStateTravelerEdit();
  useEffect(() => {
    // This code will run only on the client side
    setIsClient(true);
  }, []);

  interface CountryData {
    countryName: string;
    countryCode: string;
    displayName: string;
    countryImage: MagnoliaImage | string;
  }

  const processCountryList = (countryList: Record<string, CountryData>) => {
    return Object.values(countryList).map((countryData) => {
      const { countryName, countryCode, displayName, countryImage } =
        countryData;
      const popularCountryImage: MagnoliaImage =
        typeof countryImage === "string"
          ? {
              "@id": "",
              "@link": countryImage,
              "@name": "",
              "@path": "",
              "@type": "",
              "@uuid": "",
            }
          : countryImage;

      const srcImage = `${process.env.NEXT_PUBLIC_MGNL_HOST}/dam/${popularCountryImage["@id"]}${popularCountryImage["@path"]}`;

      return {
        displayLabel: displayName,
        parentCode: countryCode,
        parentName: countryName,
        countryImage: srcImage,
      };
    });
  };

  const modifyPayloadToLocation = (
    fromLocation?: string | undefined,
    toLocation?: string | undefined,
    area?: string | undefined,
    stCountryList?: STCountryArray
  ) => {
    if (area) {
      // eslint-disable-next-line no-param-reassign
      toLocation = undefined;
    }
    if (toLocation) {
      // eslint-disable-next-line no-param-reassign
      area = undefined;
    }
    const tripDetailsModifiled: TripDetails = {
      ...tripDetails,
      ...{ fromLocation },
      ...{ toLocation },
      ...{ area },
      ...{ toLocationCountryDetails: stCountryList },
    };
    modifyPayload(modifyPayloadSave(quoteResponse, tripDetailsModifiled));
  };

  const { toCountrySelected, valueSelected, countryArray } = questionState;
  const [isSubmitBtnClicked, setSubmitBtnClicked] = useState<boolean>(false);
  useEffect(() => {
    if (stateToLocation && selectedpolicyType) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const data: any = getQuestionTwoState(
        stateToLocation as string,
        selectedpolicyType,
        toLocationCountryDetails
      );
      getQuestionState(data);
    }
  }, [selectedpolicyType, stateToLocation, toLocationCountryDetails]);

  useEffect(() => {
    if (fromCountry) {
      const countryCode = travelFromCountryData.find(
        (country) => country.displayName === fromCountry
      );
      setFomCountryCode(countryCode?.code);

      if (selectedpolicyType === AnnualMultiTrip) {
        modifyPayloadToLocation(
          countryCode?.code as string,
          undefined,
          stateToLocation as string
        );
      } else {
        modifyPayloadToLocation(
          countryCode?.code as string,
          stateToLocation as string,
          undefined,
          toLocationCountryDetails
        );
      }
    } else if (selectedpolicyType === AnnualMultiTrip) {
      modifyPayloadToLocation(
        quoteResponse?.attributes?.tripDetails?.fromLocation ?? undefined,
        undefined,
        stateToLocation as string
      );
    } else {
      modifyPayloadToLocation(
        quoteResponse?.attributes?.tripDetails?.fromLocation ?? undefined,
        stateToLocation as string,
        undefined,
        toLocationCountryDetails
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromCountry, selectedpolicyType, stateToLocation, undefined]);

  useEffect(() => {
    if (toCountrySelected) {
      setSelectedAMTEuropeWorldwide(toCountrySelected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toCountrySelected]);

  useEffect(() => {
    if (countryArray) {
      setSelectedSTCountry(countryArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryArray]);

  const debouncedSearchTerm = useDebounce(valueFilter, 600);
  const [addresses, loading, fetchAddress]: [
    QuoteCountryType[],
    boolean,
    () => void,
  ] = useSearchCountry(debouncedSearchTerm);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useEffect(() => {
    if (valueFilter) {
      setAddressList(generateTagSearchArray(addresses));
      setOptionListText(addresses.length === 0 ? "No result found" : "");
    } else {
      setAddressList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addresses]);

  useEffect(() => {
    if (debouncedSearchTerm && debouncedSearchTerm.length > 2) {
      setFreeSoloTag(loading);
    } else {
      setFreeSoloTag(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm, loading]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useEffect(() => {
    if (valueFilter) {
      fetchAddress();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  const selectedEurope = (answerNo: number) => {
    const qtAnswer = findAnswerForEuropeId(answerNo)?.value;

    if (qtAnswer === stateToLocation) {
      getQuestionState({ ...questionState, valueSelected: null });
      setSelectedState(Europe);
      return;
    }

    setSelectedState(qtAnswer || "");
    modifyPayloadToLocation(fromCountryCode, undefined, qtAnswer || "");
  };

  const selectedWorldWide = (answerNo: number) => {
    const qtAnswer = findAnswerWorldwideById(answerNo)?.value;

    if (qtAnswer === stateToLocation) {
      getQuestionState({ ...questionState, valueSelected: null });
      setSelectedState(Worldwide);
      return;
    }

    setSelectedState(qtAnswer || "");
    modifyPayloadToLocation(fromCountryCode, undefined, qtAnswer || "");
  };

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedUKModal, setSelectedUKModal] = useState<number | null>(null);

  const selectedCountry = (answerNo: number) => {
    const selectedCountryAMT = findAnswerById(answerNo)?.value || "";

    if (selectedCountryAMT === toCountrySelected) {
      setSelectedUKModal(null);
      setSelectedAMTEuropeWorldwide(undefined);
      getQuestionState({
        ...questionState,
        toCountrySelected: null,
        valueSelected: null,
      });
      setSelectedState("");
      return;
    }

    if (selectedCountryAMT === UKOnly && selectedBrandImpact) {
      if (!toCountrySelected || toCountrySelected === UKOnly) {
        setSelectedUKModal(null);
      }

      setModalOpen(true);
    } else if (selectedCountryAMT === Europe && !selectedBrandImpact) {
      if (!toCountrySelected || toCountrySelected === Europe) {
        setSelectedUKModal(null);
      }
      setModalOpen(true);
    } else {
      setSelectedAMTEuropeWorldwide(selectedCountryAMT);
      setSelectedState(selectedCountryAMT);
      modifyPayloadToLocation(fromCountryCode, undefined, selectedCountryAMT);
    }
  };

  const modifyModalButtonClick = (buttonClick: boolean) => {
    setModalOpen(!modalOpen);
    if (buttonClick && selectedBrandImpact) {
      setSelectedUKModal(1);
      setSelectedAMTEuropeWorldwide(UKOnly);
      setSelectedState(UKOnly);
      modifyPayloadToLocation(fromCountryCode, undefined, UKOnly);
    } else if (buttonClick) {
      setSelectedUKModal(1);
      setSelectedAMTEuropeWorldwide(Europe);
      setSelectedState(Europe);
      modifyPayloadToLocation(fromCountryCode, undefined, Europe);
    } else {
      // eslint-disable-next-line no-lonely-if
      if (
        !toCountrySelected ||
        toCountrySelected === UKOnly ||
        toCountrySelected === Europe
      ) {
        setSelectedUKModal(null);
        setSelectedAMTEuropeWorldwide(undefined);
        setSelectedState("");
        modifyPayloadToLocation(undefined, undefined);
      }
    }
  };

  const handleCancelEdit = () => {
    setEditModal(true);
  };

  const fontConfig = [
    {
      brand: "staysure",
      fontWeightClass: "font-bold",
      fontColorClass: "text-text-accent-1-primary",
      fontFamilyClass: "font-body-font",
    },
    {
      brand: "avanti",
      fontWeightClass: "font-bold",
      fontColorClass: "text-text-body",
      fontFamilyClass: "font-body-font",
    },
  ];

  function getFontClasses(lBrand: string | undefined): string {
    let fontClasses = "";
    fontConfig.forEach((config) => {
      if (config.brand === lBrand) {
        fontClasses = `${config.fontColorClass} ${config.fontWeightClass} ${config.fontFamilyClass}`;
      }
    });
    return fontClasses;
  }

  useGtmPopup({
    tripType: QuestionThree,
    isPopupOpen: modalOpen,
    popupName: "UK Destination Confirm",
  });

  const handleBackButton = () => {
    dispatch(setQuestionData(payload as QuestionPayload));
    let path = getRouterPathBackButton(QuestionTwo);
    if (editPage || coverEdit) {
      path = `${path}?${editPage ? DestinationEdit : CoverEdit}=true`;
    }
    router.push(path);
  };

  const handleSubmitButton = async () => {
    if (!props?.buttonDisable) {
      setSubmitBtnClicked(true);
      setButtonLoading(true);
      try {
        dispatch(setQuestionData(payload as QuestionPayload));
        if (editPage && quoteResponse?.referenceId) {
          const result = await dispatch(
            updateQuoteOnQuestion({ ...payload, viewPage: Routes.TravellingTo })
          );
          const originalPromiseResult = unwrapResult(result);
          if (originalPromiseResult) {
            router.push(`/${Quote}`);
          }
        } else if (coverEdit && quoteResponse?.referenceId) {
          let path = getRouterPathMainButton(QuestionFour);
          path = `${path}/?${CoverEdit}=true`;
          router.push(path);
        } else if (!quoteResponse?.referenceId) {
          const questionData = await dispatch(
            saveQuestionDataBFF({ ...payload, viewPage: Routes.TravellingTo })
          );
          const originalPromiseResult = unwrapResult(questionData);
          if (originalPromiseResult) {
            router.push(getRouterPathMainButton(QuestionThree));
          }
        } else {
          dispatch(sessionCheck());
          router.push(getRouterPathMainButton(QuestionThree));
        }
        setButtonLoading(false);
      } catch (error) {
        setButtonLoading(false);
      }
    }
  };
  const toLocationData = selectLoationHookFlash(
    payload?.attributes?.tripDetails
  );

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [fcdoCountry, setFCDOCountryModal] = useState<boolean>(false);

  const tagSearchFieldOnChange = (data: OptionListProps[]) => {
    let formCountries = "";
    const stCountryArray: STCountryArray = [];

    if (data && data.length > 0) {
      const existFCDOCountry = findFCDOCountry(
        data[data.length - 1].parentCode
      );

      if (!existFCDOCountry) {
        // eslint-disable-next-line array-callback-return, @typescript-eslint/no-explicit-any
        data.map((country: OptionListProps, index) => {
          formCountries += `${country?.parentCode}${
            index !== data.length - 1 ? "," : ""
          }`;
          const stCountryObj: STCountryObj = {
            attributes: {
              countryCode: country?.parentCode,
              countryName: country?.parentName,
            },
          };
          stCountryArray.push(stCountryObj);
        });

        setToLocationCountryDetails(stCountryArray);
        modifyPayloadToLocation(
          fromCountryCode,
          formCountries,
          undefined,
          stCountryArray
        );
        setSelectedState(formCountries);
      } else {
        if (existFCDOCountry) {
          // eslint-disable-next-line no-param-reassign
          data.splice(-1, 1);
          setSelectedSTCountry(data as []);
          setFCDOCountryModal(true);
        }
        setIsModalOpen(true);
      }
    } else {
      modifyPayloadToLocation(undefined, undefined, undefined);
      setSelectedState("");
    }
  };

  useGtmPopup({
    tripType: QuestionThree,
    isPopupOpen: showSameCountryModal,
    popupName: sameCountryModalHeaderText || "Information",
  });

  const renderModal = () => {
    return (
      <Modal
        className={`${styleComponent.same_country_modal} md:p-spacing-xxxl p-spacing-l md:max-w-[416px] max-w-[327px] w-full`}
        isOpen={showSameCountryModal}
        onOpenChange={(e: boolean) => setShowSameCountryModal(e)}
        underlayStyles={{
          zIndex: 9999,
          background: "rgba(26, 26, 26, 0.6)",
        }}
        modalStyles={{
          display: "flex",
          background: "white",
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          borderRadius: 8,
        }}
      >
        <div data-testid="modal-data-test-id">
          <h1 className="text-text-title font-header-font md:text-3xl text-[23px] text-center md:leading-[34.1px] leading-[27.6px] md:mb-spacing-s mb-spacing-xs">
            {sameCountryModalHeaderText}
          </h1>
          <div className={styleComponent.text_content}>
            {sameCountryModalSubText}
          </div>
          <div className="flex md:flex-row flex-col">
            <TextButton
              data-testid="location-to-agree-button"
              id="location-to-agree-button"
              hierarchy="primary"
              label={agreeBtnText || "Agree"}
              onClick={() => {
                setShowSameCountryModal(false);
                if (sameCountrySelected && sameCountrySelected.length > 0) {
                  let modifiedValue = [...sameCountrySelected];
                  if (selectedSTCountry) {
                    modifiedValue = selectedSTCountry?.concat(modifiedValue);
                  }
                  setSelectedSTCountry(modifiedValue as []);

                  tagSearchFieldOnChange(modifiedValue as []);
                }
                // setUserAgree(false);
                setIsDisable(true);
                setTimeout(() => {
                  setIsDisable(false);
                }, 100);
              }}
              className="md:mr-2 md:mb-0 mb-2 !h-[48px] md:w-[148px] leading-[18px] w-full text-text-on-primary"
            />
            <TextButton
              data-testid="location-to-close-button"
              id="location-to-close-button"
              hierarchy="secondary"
              label={closeBtnText || "Close"}
              onClick={() => {
                setShowSameCountryModal(false);
                // setUserAgree(true);
                setIsDisable(true);
                setTimeout(() => {
                  setIsDisable(false);
                }, 100);
              }}
              className="!h-[48px] md:w-[148px] leading-[18px] w-full"
            />
          </div>
        </div>
      </Modal>
    );
  };

  useGtmQuestionSet({
    pageTitle: QuestionThree,
    tripType: selectedpolicyType,
    pageUrl: typeof window !== "undefined" ? window.location.href : "",
    destination: toLocationData.destination,
    destinationAdditions: toLocationData.destinationAdditions,
    travelFrom: payload?.attributes?.tripDetails?.fromLocation || "",
    travelGroupType: undefined,
    isSubmitBtnClicked,
  });

  useGtmPopup({
    tripType: QuestionThree,
    isPopupOpen: isModalOpen,
    popupName: fcdoCountry
      ? props?.pageContent?.fcdoCountryHeaderText
      : props?.pageContent?.sameCountryHeaderText || "Information",
  });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-redeclare

  const renderQuestionTwoSectionAMT = () => {
    const { amtQuestionOneText } = pageContent;

    const countryId =
      !toCountrySelected || !selectedAMTEuropeWorldwide
        ? selectedUKModal
        : findCountryIdByValue(toCountrySelected || "")?.id;
    return (
      <div
        className=" md:mb-spacing-xxl"
        data-testid="multi-trip-support-content"
      >
        {" "}
        {(editPage || coverEdit) && (
          <LabelButtonWrapper
            hierarchy="tertiary"
            label={cancelEditingBtnTextEurope || "Cancel editing"}
            id="cancel-edit-travel-europe"
            className={`${editQuoteStyle.cancelEditButton}`}
            onClick={handleCancelEdit}
          />
        )}
        {showLocationFrom && (
          <LocationFromContainer
            questionOneText={locationFromContent?.questionOneText}
            questionOnePlaceHolder={locationFromContent?.questionOnePlaceHolder}
            setFromCountry={setFomCountry}
            buttonDisable={setButtonDisableFromLocation}
          />
        )}
        <h1
          className=" font-header-font lg:text-[39px] md:text-3xl text-[26px] lg:leading-[44.85px] 
                md:leading-[34.1px] leading-[29.9px] text-text-title md:text-left text-center"
        >
          {amtQuestionOneText && amtQuestionOneText}
        </h1>
        {quoteLoading ? (
          <Loader />
        ) : (
          <ButtonGroup
            setSelectedAnswer={selectedCountry}
            selectedAnswer={countryId}
            answerArray={filteredAnswersQTwoWhereGoing}
            location={QuestionTwo}
            type="to-location"
            isDisable={isDisable}
          />
        )}
        <ConfirmationModal
          modalTitleText={modalTitleText}
          modalSubText={modalSubText}
          modalConfirmBtnText={modalConfirmBtnText}
          modalCancelBtnText={modalCancelBtnText}
          isOpen={modalOpen}
          modalConfirmBtnClick={modifyModalButtonClick}
        />
      </div>
    );
  };

  const renderQuestionTwoSection = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any

    const renderVisitingEuropeSection = () => {
      const { amtQuestionTwoText, amtQuestionTwoEuropeDiscrip } = pageContent;
      const selectAnswerId = findIdByEuropeValue(valueSelected || "")?.id;

      return (
        <TransformAnimation currentPage={usagePage}>
          <div className="md:mb-spacing-xxl md:mt-0 mt-spacing-l">
            <h1
              className=" font-header-font lg:text-[39px] md:text-3xl text-[26px] lg:leading-[44.85px] 
                  md:leading-[34.1px] leading-[29.9px] text-text-title md:text-left text-center"
            >
              {amtQuestionTwoText}
            </h1>
            <p
              className={` font-body-font md:text-[20px] mt-spacing-m mb-spacing-l text-[18px] 
                  md:leading-[23px] leading-[20.7px]  md:text-left text-center ${getFontClasses(brand)}`}
            >
              {amtQuestionTwoEuropeDiscrip && amtQuestionTwoEuropeDiscrip}
            </p>
            {quoteLoading ? (
              <Loader />
            ) : (
              <ButtonGroup
                setSelectedAnswer={selectedEurope}
                selectedAnswer={selectAnswerId}
                answerArray={answersQTwoEurope}
                location={Europe}
                type="europe-location"
                isDisable={isDisable}
              />
            )}
          </div>
        </TransformAnimation>
      );
    };

    const renderVisitingWorldwideSection = () => {
      const { amtQuestionTwoText, amtQuestionTwoWorldWideDiscrip } =
        pageContent;
      const countryId = findIdByWorldWideValue(valueSelected || "")?.id;
      return (
        <TransformAnimation currentPage={usagePage}>
          <div className=" md:mb-spacing-xxl md:mt-0 mt-spacing-l">
            {" "}
            <h1
              className=" font-header-font lg:text-[39px] md:text-3xl text-[26px] lg:leading-[44.85px] 
            md:leading-[34.1px] leading-[29.9px] text-text-title md:text-left text-center"
            >
              {amtQuestionTwoText}
            </h1>
            <p
              className={` font-body-font md:text-[20px] mt-spacing-m mb-spacing-l text-[18px] 
                  md:leading-[23px] leading-[20.7px]  md:text-left text-center ${getFontClasses(brand)}`}
            >
              {amtQuestionTwoWorldWideDiscrip}
            </p>
            {quoteLoading ? (
              <Loader />
            ) : (
              <ButtonGroup
                setSelectedAnswer={selectedWorldWide}
                selectedAnswer={countryId}
                answerArray={answersQTwoWorldwide}
                location={Worldwide}
                type="worldwide-location"
                isDisable={isDisable}
              />
            )}
          </div>
        </TransformAnimation>
      );
    };

    // if (!isClient) {
    //   // Render a fallback or null during SSR
    //   return null;
    // }

    return (
      <div className=" w-full">
        {expatText && (
          <div className=" mt-spacing-m md:mb-spacing-xxl mb-0">
            <InlineInfoBox
              id="location-to-expat-info-box"
              content={<p>{expatText}</p>}
              style={{
                width: "100%",
                backgroundColor: "#EDF2FF",
                borderColor: "#006BD6",
                padding: "8px 16px 8px 16px",
                borderRadius: "8px",
                color: "#272427",
                fontSize: "16px",
                lineHeight: "24px",
              }}
            />
          </div>
        )}

        {selectedpolicyType !== AnnualMultiTrip && isClient && (
          <div
            className=" md:mb-spacing-xxl"
            data-testid="single-trip-support-content"
          >
            {" "}
            {(editPage || coverEdit) && (
              <LabelButtonWrapper
                hierarchy="tertiary"
                label={cancelEditingBtnTextWorldWide || "Cancel editing"}
                id="cancel-edit-travel-worldwide"
                className={`${editQuoteStyle.cancelEditButton}`}
                onClick={handleCancelEdit}
              />
            )}
            {showLocationFrom && (
              <LocationFromContainer
                questionOneText={locationFromContent?.questionOneText}
                questionOnePlaceHolder={
                  locationFromContent?.questionOnePlaceHolder
                }
                setFromCountry={setFomCountry}
                buttonDisable={setButtonDisableFromLocation}
              />
            )}
            <h1
              className=" font-header-font  lg:text-[39px] md:text-3xl text-[26px] lg:leading-[44.85px] 
              md:leading-[34.1px] leading-[29.9px] text-text-title md:text-left text-center"
            >
              {questionTwoText && questionTwoText}
            </h1>
            {quoteLoading ? (
              <Loader />
            ) : (
              <div
                id="scrollableInput"
                className={` ${styles.country_tag_search_wrapper} lg:w-[414px] md:w-[400px] w-full mx-auto border hover:border-2 !border-interactive-secondary-default min-h-[56px] mt-spacing-l`}
              >
                {typeof window !== "undefined" && (
                  <TagSearchField
                    popularCountries={processCountryList(
                      popularCountryList ?? {}
                    )}
                    tagFieldPlaceholderClassName="!text-text-placeholder-secondary !font-semibold !font-body-font !text-[16px] leading-[24px]"
                    popularCountryTitle={popularCountryHeaderText}
                    popularCountryClassName={`text-[18px]  cursor-pointer ${getFontClasses(brand)} !font-normal !leading-[27px]`}
                    popularCountryHeaderClassName={`text-[16px] ${getFontClasses(brand)} !leading-[24px]`}
                    optionList={addressList}
                    width="200"
                    values={selectedSTCountry}
                    freeSolo={freeSoloTag}
                    $brand={brand}
                    noOptionsText={optionListText}
                    selectOnFocus={false}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    onChange={(event: unknown, values: any) => {
                      // scrollInputToTop();
                      if (values.length === 0) {
                        setSelectedSTCountry([]);
                      }

                      if (
                        typeof values[values.length - 1] !== "string" ||
                        values.length === 0
                      ) {
                        const duplicateCountry = findDuplicatesByProperty(
                          values,
                          "parentCode"
                        );

                        if (duplicateCountry.length === 0) {
                          const selectedCountryOnChange =
                            values.length > 0
                              ? values[values.length - 1].parentCode
                              : "";

                          let deleteFunction = false;
                          if (selectedSTCountry) {
                            if (selectedSTCountry.length > values.length) {
                              deleteFunction = true;
                            }
                          }
                          if (
                            !deleteFunction &&
                            selectedpolicyType === SingleTrip &&
                            checkCurentLocation(
                              fromCountryCode || "",
                              selectedCountryOnChange || ""
                            )
                          ) {
                            if (values.length > 1) {
                              const modifiedValue = [...values];
                              modifiedValue.splice(-1, 1);
                              setSelectedSTCountry(modifiedValue as []);
                            } else {
                              setSelectedSTCountry([]);
                            }
                            setShowSameCountryModal(true);
                            const newArray = [values[values.length - 1]];
                            setSameCountrySelected(newArray as []);
                          } else {
                            setShowSameCountryModal(false);
                            tagSearchFieldOnChange(values as []);
                          }
                        } else {
                          setIsModalOpen(true);
                        }
                      } else if (values && values.length > 0) {
                        const modifiedValue = [...values];
                        modifiedValue.splice(-1, 1);
                        setSelectedSTCountry(modifiedValue as []);
                        tagSearchFieldOnChange(modifiedValue);
                      } else {
                        setSelectedSTCountry([]);
                      }
                    }}
                    // eslint-disable-next-line @typescript-eslint/no-shadow
                    onInputChange={(e: unknown, value: unknown) => {
                      const query = value as string;
                      setSelectedStateTwo(query);
                      scrollInputToTop();
                      if (query.length > 2) {
                        if (
                          addresses &&
                          addresses.length > 0 &&
                          query === valueFilter
                        ) {
                          setAddressList(generateTagSearchArray(addresses));
                        }
                        setValue(query);
                      } else {
                        setFreeSoloTag(true);

                        setAddressList([]);
                      }
                      if (!query) {
                        setValue(null);
                      }
                    }}
                    $hasValues={false}
                    $width=""
                    $alertState="success"
                    tagFieldPlaceholderText={questionTwoPlaceHolder}
                  />
                )}
              </div>
            )}
            <Modal
              className={`date-picker-modal md:p-spacing-xxxl justify-center p-spacing-l ${
                fcdoCountry ? "md:max-w-[416px]" : "md:max-w-[327px]"
              } 
              ${fcdoCountry ? "max-w-[327px]" : "max-w-[267px]"}   w-full`}
              isOpen={isModalOpen}
              onOpenChange={(e: boolean) => setIsModalOpen(e)}
              underlayStyles={{
                zIndex: 9999,
                background: "rgba(26, 26, 26, 0.6)",
              }}
              modalStyles={{
                display: "flex",
                background: "white",
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                borderRadius: 8,
              }}
            >
              <div>
                <h1 className="text-text-title font-header-font md:text-3xl text-[23px] text-center md:leading-[34.1px] leading-[27.6px] md:mb-spacing-s mb-spacing-xs">
                  {fcdoCountry
                    ? props?.pageContent?.fcdoCountryHeaderText
                    : props?.pageContent?.sameCountryHeaderText ||
                      "Information"}
                </h1>

                <div className={` ${styleComponent.text_content}`}>
                  {fcdoCountry ? (
                    <RichText
                      richText={
                        props?.pageContent?.fcdoCountrySubTextText || ""
                      }
                    />
                  ) : (
                    props?.pageContent?.sameCountrySubTextText
                  )}
                </div>
                <div className="flex flex-row">
                  <TextButton
                    id="location-to-fcdo-country-button"
                    hierarchy="primary"
                    label={
                      fcdoCountry
                        ? props?.pageContent?.fcdoCountryBtnText || "Cancel"
                        : props?.pageContent?.sameCountryBtnText || "Cancel"
                    }
                    onClick={() => {
                      setFCDOCountryModal(false);
                      setIsModalOpen(false);
                    }}
                    className="m-0 h-[48px] text-text-on-primary font-lg leading-[18px] font-semibold "
                  />
                </div>
              </div>
            </Modal>
          </div>
        )}

        {selectedpolicyType === AnnualMultiTrip &&
          renderQuestionTwoSectionAMT()}
        {selectedAMTEuropeWorldwide === Europe && renderVisitingEuropeSection()}
        {selectedAMTEuropeWorldwide === Worldwide &&
          renderVisitingWorldwideSection()}
      </div>
    );
  };

  const { ctaButtonText } = pageContent;
  const editFinishStyle = editPage ? editQuoteStyle.editFinishButton : "";

  return (
    <div
      data-testid="question-two-test-id"
      className={` rounded-radius-xxl 
      bg-surface-neutral-option-1 rounded-b-radius-xxl  
           md:py-spacing-xxl py-spacing-m px-spacing-m h-full`}
    >
      <div
        className=" lg:max-w-[414px] md:max-w-[400px] max-w-[unset] w-full mx-auto relative h-full 
      flex flex-col justify-between md:min-h-[225px]"
      >
        <TransformAnimation animationRef={formRef} currentPage={usagePage}>
          {renderQuestionTwoSection()}
        </TransformAnimation>

        <FadeAnimation animationRef={ctaRef}>
          <div className={`${editFinishStyle}`}>
            <ActionButton
              page={usagePage}
              ctaButtonText={editPage ? finishEditingBtnText : ctaButtonText}
              disableMain={
                buttonDisable ||
                (showLocationFrom ? buttonDisableFromLocation : false)
              }
              expatButton={expatStatus}
              isLoading={buttonLoading}
              showBackBtn={!editPage}
              submitButtonClick={handleSubmitButton}
              backButtonClick={handleBackButton}
            />
          </div>
        </FadeAnimation>
      </div>

      {renderModal()}
      <ConfirmationsModal
        isOpen={editModal}
        handleConfirmBtnClick={() => {
          router.push(`/${Quote}/?${QuoteEdit}=true`);

          if (coverEdit) {
            resetQuestionData();
          }
        }}
        handleCancelmBtnClick={() => {
          setEditModal(false);
        }}
      />
    </div>
  );
}
export default withBrand(LocationToContainer);
