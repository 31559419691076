import React from "react";

import selectedColorBrand from "@/config/tailwind-colors/brand-colors";

import Styles from "./loading.module.css";

export default function Loader() {
  return (
    <div className="w-full lg:h-full h-auto flex justify-center items-center z-[1000] top-0 left-0 lg:absolute relative lg:mt-0 mt-spacing-l">
      <div
        className={`${Styles.loader}`}
        style={{
          border: "8px solid hwb(0 95% 5%)",
          borderTop: `10px solid ${selectedColorBrand["border-strong-2"]}`,
          borderRadius: "50%",
          width: "80px",
          height: "80px",
        }}
        data-testid="loader"
      />
    </div>
  );
}
