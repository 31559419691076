"use client";

import { unwrapResult } from "@reduxjs/toolkit";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { useEffect, useState } from "react";

import FadeAnimation from "@/components/atoms/animation/fade-animation";
import TransformAnimation from "@/components/atoms/animation/transform-animation";
import ButtonGroup from "@/components/atoms/button-group/button-group";
import LabelButtonWrapper from "@/components/atoms/button-wrapper/button-wrapper";
import {
  Family,
  QuestionFive,
  QuestionFour,
  QuestionSix,
  Quote,
  QuoteEdit,
  SingleParentFamily,
  TravellerEdit,
} from "@/config/app-constant";
import { Routes } from "@/config/page-view-constants";
import { PRODUCT_DOMAIN } from "@/constants";
import useGtmPopup from "@/hooks/gtm/useGtmPopup";
import useGtmQuestionSet from "@/hooks/gtm/useGtmQuestionSet";
import useReplaceRouterPath from "@/hooks/use-replace-router-path/use-replace-router-path";
import useResetStateTravelerEdit from "@/hooks/use-reset-state-traveler-edit/user-reset-state-traveler-edit";
import { useQuestionsState } from "@/providers/questions-state-provider";
import { useAppDispatch } from "@/store/hook";
import {
  saveQuestionDataBFF,
  sessionCheck,
} from "@/store/slices/question/question.action";
import { setQuestionData } from "@/store/slices/question/question.slice";
import { PartyTypeContainerProps } from "@/types/PartyType";
import { QuestionPayload, TripDetails } from "@/types/QuestionPayloadType";
import selectLoationHookFlash, {
  modifyPayloadSave,
} from "@/utils/common.utils";
import {
  getRouterPathBackButton,
  getRouterPathMainButton,
} from "@/utils/page-routing.utils";
import { findAnswerById, findIdByPartyValue } from "@/utils/party-type.utils";

import FamilyTypeModal from "./family-type-modal";
import ActionButton from "../../action-button/action-button";
import ConfirmationsModal from "../../quote-section/edit-quote-section/confirmation-modal";
import editQuoteStyle from "../../quote-section/edit-quote-section/edit-quote.module.css";

const initialPayload: QuestionPayload = {
  type: "IntermediateData",
  channel: "ONLINE",
  attributes: {
    domain: PRODUCT_DOMAIN,
    lastVisitedPage: "test",
  },
};

function PartyTypeContainer({
  buttonDisable,
  usagePage,
  answerArray,
  selectedParty,
  tripDetails,
  quoteResponse,
  pageContent,
  formRef,
  ctaRef,
}: Readonly<PartyTypeContainerProps>) {
  const {
    questionText = "Question Text",
    ctaButtonText = "Button Text",
    familyModalTitleText,
    familyModalSubTextEnable,
    familyModalSubText,
    dropDownPlaceHolder,
    confirmButtonText,
    backButtonText,
    cancelEditingBtnText = "Cancel Editing",
  } = pageContent;
  const router = useRouter();
  const dispatch = useAppDispatch();
  const searchParams = useSearchParams();
  const [editModal, setEditModal] = useState(false);
  const editPage = searchParams.get(TravellerEdit);

  const { setSelectedState } = useQuestionsState();
  const [payload, modifyPayload] = useState<QuestionPayload>(initialPayload);
  const [isSubmitBtnClicked, setSubmitBtnClicked] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedFamily, setSelectedFamily] = useState<number | null>(null);
  const { resetQuestionData } = useResetStateTravelerEdit();
  const pathname = usePathname();
  useReplaceRouterPath(pathname);

  const [isDisable, setIsDisable] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const accessSelectedAnswer = (answerNo: number) => {
    const selectedPartyUser = findAnswerById(answerArray, answerNo)?.value;

    // de select the previous selected answer
    if (
      selectedPartyUser === selectedParty ||
      (selectedPartyUser === Family && selectedParty === SingleParentFamily)
    ) {
      setSelectedFamily(null);
      setSelectedState(0);
      return;
    }

    if (selectedPartyUser === Family) {
      setSelectedFamily(null);
      setIsModalOpen(true);
    } else {
      const tripDetailsModified: TripDetails = {
        ...tripDetails,
        partyType: selectedPartyUser,
      };

      modifyPayload(modifyPayloadSave(quoteResponse, tripDetailsModified));

      setSelectedState(selectedPartyUser);
    }
  };

  useEffect(() => {
    if (selectedParty && tripDetails) {
      const tripDetailsModified: TripDetails = {
        ...tripDetails,
        partyType: selectedParty,
      };
      modifyPayload(modifyPayloadSave(quoteResponse, tripDetailsModified));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tripDetails, selectedParty]);

  useEffect(() => {
    if (selectedParty) {
      if (selectedParty === Family) {
        setSelectedFamily(2);
      } else if (selectedParty === SingleParentFamily) {
        setSelectedFamily(1);
      }
    }
  }, [selectedFamily, selectedParty]);

  const handleModalButtonClick = (
    buttonClick: boolean,
    selectedAnswer: number | null
  ) => {
    setIsModalOpen(!isModalOpen);

    setIsDisable(true);
    setTimeout(() => {
      setIsDisable(false);
    }, 100);

    if (buttonClick && selectedAnswer) {
      // const familyType = findAnswerFamilyById(selectedAnswer)?.value;
      // change the logic here
      const partType = selectedAnswer === 1 ? SingleParentFamily : Family;
      const tripDetailsModified: TripDetails = {
        ...tripDetails,
        ...{ partType },
      };

      modifyPayload(modifyPayloadSave(quoteResponse, tripDetailsModified));
      setSelectedState(partType);
      setSelectedFamily(selectedAnswer);
    } else {
      setSelectedFamily(null);
    }
    // else if (buttonClick && selectedNumberOfParents === 2) {
    //   // change the logic here
    //   setSelectedAnswer(Family);
    // }
  };

  const handleSubmitButton = async () => {
    if (!buttonDisable) {
      setSubmitBtnClicked(true);
      setButtonLoading(true);
      try {
        dispatch(setQuestionData(payload as QuestionPayload));
        if (!quoteResponse?.referenceId) {
          const questionData = await dispatch(
            saveQuestionDataBFF({ ...payload, viewPage: Routes.CoverFor })
          );
          const originalPromiseResult = unwrapResult(questionData);
          if (originalPromiseResult) {
            router.push(getRouterPathMainButton(QuestionSix));
          }
        } else {
          dispatch(sessionCheck());
          let path = getRouterPathMainButton(QuestionSix);
          if (editPage) {
            path = `${path}/?${TravellerEdit}=true`;
          }
          router.push(path);
        }
        setButtonLoading(false);
      } catch (error) {
        setButtonLoading(false);
      }
    }
  };

  const handleBackButton = () => {
    router.push(getRouterPathBackButton(QuestionSix));
  };
  const toLocationData = selectLoationHookFlash(
    payload?.attributes?.tripDetails
  );

  useGtmQuestionSet({
    pageTitle: QuestionSix,
    tripType: payload?.attributes?.tripDetails?.policyType,
    pageUrl: typeof window !== "undefined" ? window.location.href : "",
    destination: toLocationData.destination,
    destinationAdditions: toLocationData.destinationAdditions,
    tripExtra: payload?.attributes?.tripDetails?.goingOnCruise
      ? QuestionFour
      : undefined,
    coverStartDate: payload?.attributes?.tripDetails?.startDate || undefined,
    coverEndDate: payload?.attributes?.tripDetails?.endDate || undefined,
    travelFrom: payload?.attributes?.tripDetails?.fromLocation || undefined,
    travelGroupType: payload?.attributes?.tripDetails?.partyType || undefined,
    isSubmitBtnClicked,
  });

  useGtmPopup({
    tripType: QuestionSix,
    isPopupOpen: isModalOpen,
    popupName: "Family Travel Popup",
  });

  const handleCancelEdit = () => {
    setEditModal(true);
  };

  const renderQuestionOneSection = () => {
    const selectedPartyType =
      selectedParty === SingleParentFamily
        ? findIdByPartyValue(answerArray, Family)?.id
        : findIdByPartyValue(answerArray, selectedParty)?.id;

    return (
      <div className="" data-testid="party-type-support-content">
        {" "}
        {editPage && (
          <div>
            <LabelButtonWrapper
              hierarchy="tertiary"
              label={cancelEditingBtnText}
              id="cancel-edit-party-type"
              className={`${editQuoteStyle.cancelEditButton}`}
              onClick={handleCancelEdit}
            />
            <ConfirmationsModal
              isOpen={editModal}
              handleConfirmBtnClick={() => {
                router.push(`/${Quote}/?${QuoteEdit}=true`);
                resetQuestionData();
              }}
              handleCancelmBtnClick={() => {
                setEditModal(false);
              }}
            />
          </div>
        )}
        <h1
          className=" font-header-font lg:text-[39px] md:text-3xl text-[26px] lg:leading-[44.85px] 
                md:leading-[34.1px] leading-[29.9px] text-text-title md:text-left text-center"
        >
          {questionText && questionText}
        </h1>
        <ButtonGroup
          setSelectedAnswer={accessSelectedAnswer}
          answerArray={answerArray}
          location={QuestionFive}
          selectedAnswer={selectedPartyType}
          type="party-type"
          isDisable={isDisable}
        />
        <FamilyTypeModal
          selectedAnswer={selectedFamily}
          isOpen={isModalOpen}
          modalConfirmBtnClick={handleModalButtonClick}
          familyModalTitleText={familyModalTitleText}
          familyModalSubTextEnable={familyModalSubTextEnable}
          familyModalSubText={familyModalSubText}
          dropDownPlaceHolder={dropDownPlaceHolder}
          confirmButtonText={confirmButtonText}
          backButtonText={backButtonText}
        />
      </div>
    );
  };
  const editFinishStyle = editPage ? editQuoteStyle.editFinishButton : "";

  return (
    <div
      data-testid="party-type-test-id"
      className={`rounded-radius-xxl 
      bg-surface-neutral-option-1 rounded-b-radius-xxl  
           md:py-spacing-xxl py-spacing-m px-spacing-m h-full`}
    >
      <div className="lg:w-[414px] md:w-[400px] w-full mx-auto relative h-full flex flex-col justify-between">
        <TransformAnimation animationRef={formRef} currentPage={usagePage}>
          {renderQuestionOneSection()}
        </TransformAnimation>

        <FadeAnimation animationRef={ctaRef}>
          <div className={`${editFinishStyle}`}>
            <ActionButton
              page={usagePage}
              ctaButtonText={ctaButtonText}
              disableMain={buttonDisable}
              showBackBtn={!editPage}
              isLoading={buttonLoading}
              submitButtonClick={handleSubmitButton}
              backButtonClick={handleBackButton}
            />
          </div>
        </FadeAnimation>
      </div>
    </div>
  );
}
export default PartyTypeContainer;
