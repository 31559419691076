/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */

"use client";

import dayjs from "dayjs";
import { useSelector } from "react-redux";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Dropdown,
  DropdownItem,
  TextFieldSts,
  DateInput,
  Button,
  InlineInfoBox,
} from "staysure-component-library";

import FadeAnimation from "@/components/atoms/animation/fade-animation";
import TransformAnimation from "@/components/atoms/animation/transform-animation";
import ButtonGroup from "@/components/atoms/button-group/button-group";
import InputValidationWrapper from "@/components/atoms/input-validation-wrapper/input-validation-wrapper";
import withBrand from "@/components/with-brand";
import { Individual, QuestionThree } from "@/config/app-constant";
import { MAX_ADDRESS_LENGTH } from "@/constants";
import {
  useAddressApi,
  useFetchAddressData,
  useFetchNationalityData,
} from "@/hooks/api/addressApi/addressApi";
import useDebounce from "@/hooks/debounce/debounce";
import useInputFocus from "@/hooks/useInputFocus/use-input-focus";
import useMediaQuery from "@/hooks/useMediaQuery/use-media-query";
import { useQuestionsState } from "@/providers/questions-state-provider";
import { RootState } from "@/store/store";
import { AddressObj, AddressType } from "@/types/AddressTypes";
import {
  FormDataTypeOrganiser,
  OrganiserFormProps,
} from "@/types/OrganiserType";
import {
  answersQuestionThree,
  findAnswerQThreeById,
  findAnswerQThreeByValue,
} from "@/utils/cruise.utils";
import {
  checkValidation,
  getOrganiserDateValidation,
  getOrganiserDateValidationStates,
  getStrokeColor,
  ifAddressData,
  inputCharacterCount,
  isFormDataCompleteOrganiser,
  regexValidaton,
  showNationalityFieldInExpat,
} from "@/utils/organiser.utils";
import {
  travelFromCountryData,
  showLocationFrom,
} from "@/utils/question-location-from.utils";
import { isValidDate } from "@/utils/travel-date.utils";
import { titleData, validateName } from "@/utils/traveller.utils";

import Styles from "./organiser-form.module.css";
import TravellerMedicalAnswer from "../../traveller/traveller-medical-answer/traveller-medical-answer";

const formDataInitialValue = {
  id: 1,
  value1: "",
  value2: "",
  value3: "",
  value4: "",
  nationalityValue: undefined,
  dateValue: undefined,
  isDateCalenter: true,
  travelling: undefined,
  postCode: "",
  firstLineOfAddress: "",
  secondLineOfAddress: "",
  city: "",
  country: "",
  hasMedicalConditions: undefined,
};

const fontConfig = [
  {
    brand: "staysure",
    fontColorClass: "text-cyan-800",
  },
  {
    brand: "avanti",
    fontColorClass: "text-interactive-secondary-default",
  },
];

function OrganiserForm({
  inputOnePlaceholder,
  inputTwoPlaceholder,
  handleSubmitButton,
  inputOneErrorNumericvalues,
  inputOneErrorSpecialCharacters,
  inputOneErrorMaximumNumber,
  inputTwoErrorNumericvalues,
  inputTwoErrorSpecialCharacters,
  inputTwoErrorMaximumNumber,
  dobMinAge,
  dobMaxAge,
  dobPlaceholder,
  travellerType,
  personalStatusPlaceholder,
  nationalityPlaceholder,
  ageFamilyFullTimeEducation,
  ageSingleParentFullTimeEducation,
  dobMaxLimit,
  questionTwoText,
  organiserFormSaved,
  brand,
  newMedicalFlowEnabled,
  medicalQuestionText,
  medicalQuestionInfoText,
  medicalServiceDeniedText,
  medicalYesBtnText,
  medicalNoBtnText,
  alertMessageIncompleteSectionSingle,
  alertMessageIncompleteSection,
  alertMessageLinkSectionSingle,
  alertMessageLinkSection,
  showErrors,
  ...props
}: Readonly<OrganiserFormProps>) {
  const question = useSelector((state: RootState) => state.question);
  const { setSelectedState, setHasMedicalConditionState } = useQuestionsState();
  const [additionalData, setAdditionalData] = useState<boolean>(false);
  const [formData, setFormData] =
    useState<FormDataTypeOrganiser>(formDataInitialValue);
  const [firstNameErrors, setFirstNameErrors] = useState<string>("");
  const [lastNameErrors, setLastNameErrors] = useState<string>("");
  const [addressErrors, setAddressErrors] = useState<string>("");

  const [valueAddress, setValueAddress] = useState<string | null>("");
  const [suggestions, setSuggestions] = useState<AddressType[]>([]);
  const debouncedSearchTerm = useDebounce(valueAddress, 500);
  const [addressServiceCountry, setAddressServiceCountry] =
    useState<string>("");
  const fromCountry =
    question.quoteResponse?.attributes.tripDetails?.fromLocation;
  const [address, fetchAddress]: [AddressType[], () => void] = useAddressApi(
    debouncedSearchTerm,
    addressServiceCountry
  );

  useEffect(() => {
    if (fromCountry && fromCountry !== "") {
      const selectedCountry = travelFromCountryData.find(
        (country) => country.code === fromCountry
      );
      if (selectedCountry) {
        setAddressServiceCountry(selectedCountry?.addressServiceCode);
      } else {
        setAddressServiceCountry("");
      }
    }
  }, [fromCountry]);

  const [addressID, setAddressID] = useState<string>("");
  const [addressDetails, setAddressDetails] = useState<string>("");
  const [addressData, setAddressData] = useState<AddressType[]>([]);
  const [invalideDate, setInvalideDate] = useState<string>("");
  const [activeHoverd, setActiveHoverd] = useState<boolean>(true);
  const [postCode, setPostCode] = useState<string | null>("");
  const [inputDisable, setInputDisable] = useState<boolean>(false);
  const [invalidErrorMessage, setInvalidErrorMessage] = React.useState({
    enterAddress: "",
    line1: "",
    line2: "",
    city: "",
    postalCode: "",
  });

  const { getWarningStatus, handleFocus, setAllFocused, setFocusedInputs } =
    useInputFocus({
      value1: false,
      value2: false,
      value3: false,
      value4: false,
      nationalityValue: false,
      dateValue: false,
      postCode: false,
      postalCode: false,
      line1: false,
      line2: false,
      city: false,
      travelling: false,
      hasMedicalConditions: false,
    });
  const [isTitleFocused, setIsTitleFocused] = useState<boolean>(false);
  const [isNationalityFocused, setIsNationalityFocused] =
    useState<boolean>(false);

  const [isTabPressed, setIsTabPressed] = useState(false);
  const [addressDataFetch, fetchAddressDetails] =
    useFetchAddressData(addressID);

  const isdesktop = useMediaQuery("(min-width: 600px)");
  const { usagePage } = props;

  // Fetch nationality list data
  const nationalityData = useFetchNationalityData(showNationalityFieldInExpat);
  const nationalityId =
    question.quoteResponse?.attributes.organiser?.nationalityId;

  const validateFirstName = (form: FormDataTypeOrganiser) => {
    const errors: string[] = validateName(
      form.value2,
      inputOneErrorNumericvalues ?? "",
      inputOneErrorSpecialCharacters ?? "",
      inputOneErrorMaximumNumber ?? ""
    );

    setFirstNameErrors(errors.join(". "));
  };

  const validateLastName = (form: FormDataTypeOrganiser) => {
    const errors: string[] = validateName(
      form.value3,
      inputTwoErrorNumericvalues ?? "",
      inputTwoErrorSpecialCharacters ?? "",
      inputTwoErrorMaximumNumber ?? ""
    );

    setLastNameErrors(errors.join(". "));
  };

  const handleInputChange = (
    fieldName: string,
    value: string | boolean | undefined,
    addressArray?: AddressType[]
  ) => {
    let newFormData = formData;
    if (fieldName === "selectAddress") {
      if (ifAddressData(addressArray as AddressType[])) {
        const addressObj = addressArray?.[0];
        newFormData.firstLineOfAddress = addressObj?.attributes.line1 ?? "";
        newFormData.secondLineOfAddress = addressObj?.attributes?.line2;
        newFormData.city = addressObj?.attributes?.city ?? "";
        newFormData.postCode =
          addressObj?.attributes?.postalCode.replace("-", " ") || "";
        newFormData.country = addressObj?.attributes?.countryIso2 ?? "GB";
      } else {
        newFormData.firstLineOfAddress = "";
        newFormData.secondLineOfAddress = "";
        newFormData.city = "";
        newFormData.postCode = "";
        newFormData.country = "";
      }
    } else {
      newFormData = { ...formData, [fieldName]: value };
    }

    if (typeof value === "string") {
      // Remove the trailing space if present
      value = value.replace(/\s+$/, "");
    }
    if (fieldName === "line1") {
      newFormData.firstLineOfAddress = value as string;
    }
    if (fieldName === "line2") {
      newFormData.secondLineOfAddress = value as string;
    }
    if (fieldName === "city") {
      newFormData.city = value as string;
    }
    if (fieldName === "postalCode") {
      newFormData.postCode = value as string;
    }

    if (fieldName === "dateValue" || newFormData.dateValue) {
      if (isValidDate(newFormData.dateValue as Date)) {
        const birthDate = dayjs(newFormData.dateValue);
        const currentDate = dayjs();
        const age = currentDate.diff(birthDate, "year");
        // Check if the birth date is in the future
        const isFutureDate = birthDate.isAfter(currentDate);
        let ageValue = 0;
        if (isFutureDate) {
          if (age === 0) {
            ageValue = -0.5;
          } else if (age < 0) {
            ageValue = age;
          }
        } else {
          ageValue = age;
        }
        newFormData = {
          ...newFormData,
          value4: ageValue.toString(),
        };
      } else {
        newFormData = {
          ...newFormData,
          value4: "",
        };
      }
    }
    if (fieldName === "value2") {
      validateFirstName(newFormData);
    } else if (fieldName === "value3") {
      validateLastName(newFormData);
    }

    newFormData.country = showLocationFrom ? (fromCountry ?? "") : "GB";
    newFormData = {
      ...newFormData,
      ...{ [fieldName]: value },
    };

    if (fieldName === "nationalityValue") {
      const selectedNationality = nationalityData.find(
        (n) => n.name === value
      )?.id;
      newFormData.nationalityValue = selectedNationality ?? undefined;
    }
    setFormData(newFormData);

    const isComplete = isFormDataCompleteOrganiser(
      newFormData,
      travellerType ?? Individual
    );
    if (isComplete) {
      handleSubmitButton(newFormData);
    } else {
      handleSubmitButton({} as FormDataTypeOrganiser);
    }
  };

  const handleChange = (event: string, inputName: string) => {
    const isError = checkValidation(
      inputName,
      addressServiceCountry,
      props
    ).map((validation) => {
      if (validation.errorType === "regex") {
        return regexValidaton(event, validation.validation as RegExp)
          ? validation.errorMsg
          : null;
      }
      if (validation.errorType === "maxTextCount") {
        return inputCharacterCount(event, validation.validation as number)
          ? validation.errorMsg
          : null;
      }
      return null;
    });

    handleInputChange(inputName, event);

    if (isError.length > 0) {
      setInvalidErrorMessage((prev) => {
        return {
          ...prev,
          [inputName]: event ? (isError[0] ?? isError[1]) : null,
        };
      });
    }

    setAddressData((prev) => {
      return [
        {
          ...prev[0],
          attributes: {
            ...prev[0]?.attributes,
            [inputName]: event,
          },
        },
      ];
    });
  };

  const handleChange1 = (event: string) => {
    const trimSearchValue = event.trim();

    if (!trimSearchValue) {
      setAddressErrors("");
      handleInputChange("selectAddress", undefined, []);
    } else if (trimSearchValue && trimSearchValue.length < 3) {
      setSuggestions([]);
    }
    if (trimSearchValue && trimSearchValue.length > 2) {
      if (trimSearchValue.length === 3) setSuggestions([]); // clear existing data
      if (trimSearchValue === valueAddress) {
        setSuggestions(address);
      }
      setValueAddress(trimSearchValue);
    } else {
      setValueAddress("");
      setSuggestions([]);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSuggestionClick = (suggestion: any) => {
    if (addressID && addressID === suggestion.id) {
      if (addressDataFetch?.[0]) {
        setAddressData(addressDataFetch);

        if (addressDataFetch[0]) {
          const addressObj = addressDataFetch[0] as AddressType;
          const addressModifined: AddressObj = {
            line1: addressObj?.attributes.line1 || "",
            line2: addressObj?.attributes?.line2,
            city: addressObj?.attributes?.city || "",
            postalCode:
              addressObj?.attributes?.postalCode.replace("-", " ") || "",
            // countryIso2: addressObj?.attributes?.countryIso2 || "",
            countryIso2: addressObj?.attributes?.countryIso2 ?? "GB",
          };
          setPostCode(addressObj?.attributes?.postalCode.replace("-", " "));
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          let attrName: keyof AddressObj;

          // eslint-disable-next-line no-restricted-syntax, @typescript-eslint/no-shadow
          for (attrName in addressModifined) {
            if (
              Object.prototype.hasOwnProperty.call(addressModifined, attrName)
            ) {
              const propertyValue = addressModifined[attrName];
              if (
                (attrName === "line2" && propertyValue) ||
                attrName !== "line2"
              ) {
                handleChange(propertyValue, attrName);
              }
            }
          }
        }
        handleInputChange("selectAddress", undefined, addressDataFetch);
      }
    }

    setAddressID(suggestion.id);
    setAddressDetails(
      `${suggestion.attributes.address}, ${suggestion.attributes.description}`
    );

    setSuggestions([]);
  };

  const scrollInputToTop = () => {
    const inputField = document.getElementById("scrollableInput");
    if (inputField) {
      inputField.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const handleIsPersonTravelling = (answer: number) => {
    if (answer) {
      const isTraveling = findAnswerQThreeById(answer)?.value;

      // de select the previous selected answer
      if (formData.travelling === isTraveling) {
        setSelectedState(undefined);
        handleInputChange("travelling", undefined);
        return;
      }

      setSelectedState(isTraveling as boolean);
      handleInputChange("travelling", isTraveling || false);
    }
  };

  const getFocusedFields = useCallback((field: string) => {
    const titleValues = ["value1"];
    const firstNameValues = [...titleValues, "value2"];
    const lastNameValues = [...firstNameValues, "value3"];
    const nationalityValues = [...lastNameValues, "nationalityValue"];
    const dobValues = [...nationalityValues, "dateValue"];
    const withAddressLine1 = [...dobValues, "line1"];
    const extendedValues = [...withAddressLine1, "city", "postCode"];
    const withPostalCode = [...extendedValues, "postalCode"];

    const fieldMap: Record<string, string[]> = {
      value1: titleValues,
      value2: firstNameValues,
      value3: lastNameValues,
      nationalityValue: nationalityValues,
      dateValue: dobValues,
      postCode: [...dobValues, "postCode"],
      line1: withAddressLine1,
      line2: withAddressLine1,
      city: [...withAddressLine1, "city"],
      postalCode: withPostalCode,
      travelling: withPostalCode,
      hasMedicalConditions: withPostalCode,
    };
    return fieldMap[field] || [];
  }, []);

  const handleMedicalQuestion = (answer: number) => {
    if (answer && newMedicalFlowEnabled) {
      const isMedicalOk = findAnswerQThreeById(answer)?.value;
      if (formData.hasMedicalConditions === isMedicalOk) {
        // remove the previous selected answer
        setHasMedicalConditionState(undefined);
        handleInputChange("hasMedicalConditions", undefined);
        return;
      }
      setHasMedicalConditionState(isMedicalOk || false);
      handleInputChange("hasMedicalConditions", isMedicalOk || false);
      setFocusedInputs(getFocusedFields("hasMedicalConditions"));
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };

  const calculateAge = () => {
    const value = Number(formData.value4);
    return value <= 0 ? null : value;
  };

  function getTextColorClasses(lBrand: string | undefined): string {
    let fontClasses = "";
    fontConfig.forEach((config) => {
      if (config.brand === lBrand) {
        fontClasses = `${config.fontColorClass}`;
      }
    });
    return fontClasses;
  }

  useEffect(() => {
    if (organiserFormSaved) {
      setFormData(organiserFormSaved);
      const addressSaved = {
        attributes: {
          line1: organiserFormSaved.firstLineOfAddress,
          line2: organiserFormSaved.secondLineOfAddress,
          city: organiserFormSaved?.city,
          postalCode: organiserFormSaved?.postCode.replace("-", " "),
          // countryIso2: organiserFormSaved?.country,
          countryIso2: organiserFormSaved.country ?? "GB",
        },
      };
      setPostCode(organiserFormSaved?.postCode.replace("-", " "));
      setAdditionalData(true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setAddressData([addressSaved] as any);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organiserFormSaved]);

  useEffect(() => {
    if (valueAddress) {
      fetchAddress();
    } else {
      setSuggestions([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  useEffect(() => {
    setSuggestions(address);
    if (address && address.length > 0) {
      setAddressErrors("");
    } else if (valueAddress && valueAddress.length > 0) {
      setAddressErrors("Please enter a valid postcode");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  useEffect(() => {
    if (!valueAddress) {
      setAddressErrors("");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueAddress]);

  useEffect(() => {
    if (suggestions && suggestions.length > 0) {
      setAddressErrors("");
    }
  }, [suggestions]);

  useEffect(() => {
    if (addressDataFetch?.[0]) {
      setAddressData(addressDataFetch);

      if (addressDataFetch[0]) {
        const addressObj = addressDataFetch[0] as AddressType;
        const addressModifined: AddressObj = {
          line1: addressObj?.attributes.line1 || "",
          line2: addressObj?.attributes?.line2,
          city: addressObj?.attributes?.city || "",
          postalCode:
            addressObj?.attributes?.postalCode.replace("-", " ") || "",
          // countryIso2: addressObj?.attributes?.countryIso2 || "",
          countryIso2: addressObj?.attributes?.countryIso2 ?? "GB",
        };

        setPostCode(addressObj?.attributes?.postalCode.replace("-", " "));
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        let attrName: keyof AddressObj;

        // eslint-disable-next-line no-restricted-syntax, @typescript-eslint/no-shadow
        for (attrName in addressModifined) {
          if (
            Object.prototype.hasOwnProperty.call(addressModifined, attrName)
          ) {
            const propertyValue = addressModifined[attrName];
            if (
              (attrName === "line2" && propertyValue) ||
              attrName !== "line2"
            ) {
              handleChange(propertyValue, attrName);
            }
          }
        }
      }
      handleInputChange("selectAddress", undefined, addressDataFetch);
    } else {
      setAddressData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressDataFetch]);

  useEffect(() => {
    if (addressID) {
      fetchAddressDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressID]);

  useEffect(() => {
    function handleKeyDown(event: { key: string }) {
      if (event.key === "Tab") {
        setIsTabPressed(true);
      }
    }

    function handleKeyUp(event: { key: string }) {
      if (event.key === "Tab") {
        setIsTabPressed(false);
      }
    }

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  useEffect(() => {
    if (newMedicalFlowEnabled) {
      if (formData.travelling === undefined || formData.travelling === false) {
        handleInputChange("hasMedicalConditions", undefined);
        setHasMedicalConditionState(undefined);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.travelling]);

  useEffect(() => {
    if (showErrors) setAllFocused();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showErrors]);

  const titleStatus = useMemo(() => {
    return formData.value1 ? "success" : getWarningStatus("value1");
  }, [formData.value1, getWarningStatus]);

  const firstNameStatus = useMemo(() => {
    if (firstNameErrors) return "error";
    if (formData.value2) return "success";
    return getWarningStatus("value2");
  }, [firstNameErrors, formData.value2, getWarningStatus]);

  const lastNameStatus = useMemo(() => {
    if (lastNameErrors) return "error";
    if (formData.value3) return "success";
    return getWarningStatus("value3");
  }, [formData.value3, getWarningStatus, lastNameErrors]);

  const nationalityStatus = useMemo(() => {
    return formData.nationalityValue
      ? "success"
      : getWarningStatus("nationalityValue");
  }, [formData.nationalityValue, getWarningStatus]);

  const dobStatus = useMemo(() => {
    if (getOrganiserDateValidationStates(formData) || invalideDate)
      return "error";
    if (formData.dateValue && isValidDate(formData.dateValue)) return "success";
    return getWarningStatus("dateValue");
  }, [formData, invalideDate, getWarningStatus]);

  const postCodeStatus = useMemo(() => {
    if (!additionalData && !addressDetails) {
      if (addressErrors) return "error";
      if (formData.postCode) return "success";
      return getWarningStatus("postCode");
    }
    return null;
  }, [
    additionalData,
    addressDetails,
    addressErrors,
    formData.postCode,
    getWarningStatus,
  ]);

  const addressLine1Status = useMemo(() => {
    if (invalidErrorMessage.line1) return "error";
    if (addressData[0]?.attributes?.line1) return "success";
    return getWarningStatus("line1");
  }, [addressData, getWarningStatus, invalidErrorMessage.line1]);

  const addressLine2Status = useMemo(() => {
    if (invalidErrorMessage?.line2) return "error";
    if (addressData[0]?.attributes?.line2) return "success";
    return null;
  }, [addressData, invalidErrorMessage?.line2]);

  const addressCityStatus = useMemo(() => {
    if (invalidErrorMessage.city) return "error";
    if (addressData[0]?.attributes?.city) return "success";
    return getWarningStatus("city");
  }, [addressData, getWarningStatus, invalidErrorMessage.city]);

  const addressPostalCodeStatus = useMemo(() => {
    if (
      addressData[0]?.attributes?.postalCode?.length > 2 &&
      invalidErrorMessage.postalCode
    ) {
      return "error";
    }
    if (formData.postCode && formData.postCode.length > 2) return "success"; // no status until 3 chars
    return getWarningStatus("postalCode");
  }, [
    addressData,
    formData.postCode,
    getWarningStatus,
    invalidErrorMessage.postalCode,
  ]);

  const errorDetails: { count: number; firstError: string | null } =
    useMemo(() => {
      const errObj = { count: 0, firstError: "" };
      if (!showErrors) return errObj;
      // check title
      if (titleStatus === "warning") {
        errObj.count += 1;
        errObj.firstError = "value1";
      }

      // check first name
      if (firstNameStatus === "warning" || firstNameStatus === "error") {
        errObj.count += 1;
        if (!errObj.firstError) errObj.firstError = "value2";
      }

      // check last name
      if (lastNameStatus === "warning" || lastNameStatus === "error") {
        errObj.count += 1;
        if (!errObj.firstError) errObj.firstError = "value3";
      }

      // check nationality
      if (nationalityStatus === "warning" && showNationalityFieldInExpat) {
        errObj.count += 1;
        if (!errObj.firstError) errObj.firstError = "nationalityValue";
      }

      // check dob
      if (dobStatus === "warning" || dobStatus === "error") {
        errObj.count += 1;
        if (!errObj.firstError) errObj.firstError = "dateValue";
      }

      // address
      if (!additionalData && !addressDetails) {
        // if search selected
        // check post code
        if (postCodeStatus === "warning" || postCodeStatus === "error") {
          errObj.count += 1;
          if (!errObj.firstError) errObj.firstError = "postCode";
        }
      } else {
        //  if manually selected
        //    check address line 1
        if (
          addressLine1Status === "warning" ||
          addressLine1Status === "error"
        ) {
          errObj.count += 1;
          if (!errObj.firstError) errObj.firstError = "line1";
        }
        //    check address line 2
        if (addressLine2Status === "error") {
          errObj.count += 1;
          if (!errObj.firstError) errObj.firstError = "line2";
        }
        //    check city
        if (addressCityStatus === "warning" || addressCityStatus === "error") {
          errObj.count += 1;
          if (!errObj.firstError) errObj.firstError = "city";
        }
        //    check postcode
        if (
          addressPostalCodeStatus === "warning" ||
          addressPostalCodeStatus === "error"
        ) {
          errObj.count += 1;
          if (!errObj.firstError) errObj.firstError = "postCode";
        }
      }

      // check is organiser travelling
      if (formData.travelling === undefined) {
        errObj.count += 1;
        if (!errObj.firstError) errObj.firstError = "travelling";
      }

      // check medical conditions
      if (
        formData.hasMedicalConditions === undefined &&
        formData.travelling === true &&
        newMedicalFlowEnabled &&
        travellerType === Individual
      ) {
        errObj.count += 1;
        if (!errObj.firstError) errObj.firstError = "hasMedicalConditions";
      }
      return errObj;
    }, [
      showErrors,
      titleStatus,
      firstNameStatus,
      lastNameStatus,
      nationalityStatus,
      dobStatus,
      additionalData,
      addressDetails,
      formData.travelling,
      formData.hasMedicalConditions,
      newMedicalFlowEnabled,
      travellerType,
      postCodeStatus,
      addressLine1Status,
      addressLine2Status,
      addressCityStatus,
      addressPostalCodeStatus,
    ]);

  const getAlertMessageIncompleteSection = useCallback(() => {
    if (errorDetails.count === 1) {
      return alertMessageIncompleteSectionSingle.replace(
        "<ERROR_COUNT>",
        errorDetails.count.toString()
      );
    }
    return alertMessageIncompleteSection.replace(
      "<ERROR_COUNT>",
      errorDetails.count.toString()
    );
  }, [
    alertMessageIncompleteSection,
    alertMessageIncompleteSectionSingle,
    errorDetails.count,
  ]);

  const getAlertMessageLinkSection = useCallback(() => {
    if (errorDetails.count === 1) {
      return alertMessageLinkSectionSingle;
    }
    return alertMessageLinkSection;
  }, [
    alertMessageLinkSection,
    alertMessageLinkSectionSingle,
    errorDetails.count,
  ]);

  const firstErrorRef = useRef(null);

  const clearAutofillPostCodeData = () => {
    setSuggestions([]);
    handleChange1("");
    setPostCode("");
  };

  return (
    <div className=" flex flex-col">
      <div className=" flex flex-col gap-x-2 md:mt-spacing-l mt-spacing-m">
        <form
          data-testid="organiser-form"
          onSubmit={handleSubmit}
          className={`${Styles.trvellers_form} md:bg-surface-neutral-option-1 bg-surface-neutral-option-2`}
        >
          <div
            key={formData.id}
            className=" field-block md:!pb-spacing-s !px-0"
          >
            <InputValidationWrapper
              className="flex flex-row mb-spacing-s"
              status={titleStatus}
              childRef={
                errorDetails.firstError === "value1" ? firstErrorRef : undefined
              }
            >
              <div
                className={` ${Styles.country_wrapper} ${Styles.title_dropdown} ${isTitleFocused && Styles.dropdown_focused} flex flex-row items-start  max-w-[106px] w-full h-[56px]`}
                onFocus={() => {
                  if (isTabPressed) {
                    setIsTitleFocused(true);
                  }
                }}
                onBlur={() => setIsTitleFocused(false)}
              >
                <Dropdown
                  id="personal-status-dropdown"
                  defaultValue={formData.value1}
                  placeholderText={personalStatusPlaceholder}
                  strokeColor={getStrokeColor(brand)}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onSelectionChange={(e: any) => {
                    handleInputChange("value1", e);
                    if (isdesktop) {
                      setActiveHoverd(false);
                      setInputDisable(true);
                      setTimeout(() => {
                        setInputDisable(false);
                      }, 1);
                      setTimeout(() => {
                        setActiveHoverd(true);
                      }, 1200);
                    } else {
                      setActiveHoverd(false);
                      setInputDisable(true);
                      setTimeout(() => {
                        setInputDisable(false);
                      }, 1);
                    }
                  }}
                  onDropdownBlur={() => {
                    if (getWarningStatus("value1") === null) {
                      handleFocus("value1");
                    }
                  }}
                  className={`${Styles.droplistBox} ${titleStatus === "warning" ? Styles.warningMsgClass : ""} ${titleStatus === "success" ? Styles.successMsgClass : ""} border-interactive-secondary-default rounded-radius-lg 
                  max-h-spacing-xxxl`}
                  listBoxStyle={{
                    outline: "none",
                    margin: "0px 0px 0px 0px",
                    minWidth: "unset !important",
                    boxShadow: "0px 0px 12px 0px rgba(15, 23, 42, 0.11)",
                  }}
                  listClassName={` ${Styles.listStyle} mt-[10px] rounded-radius-s`}
                  unorderedListClassName="w-[100px] !mt-[3px] !md:ml-0 !ml-[-1px] !border-border-subdued !rounded-radius-lg 
               !px-spacing-m !py-spacing-xs"
                >
                  {titleData?.map((title) => (
                    <DropdownItem value={title.displayName} key={title.id}>
                      <span className="w-full leading-[24px] font-proximanova text-2xs font-semibold p-spacing-xs country-name">
                        {title.displayName}
                      </span>
                    </DropdownItem>
                  ))}
                </Dropdown>
              </div>
            </InputValidationWrapper>
            <InputValidationWrapper
              className={`${Styles.input_wrapper} flex flex-row items-start mb-spacing-s`}
              status={firstNameStatus}
              childRef={
                errorDetails.firstError === "value2" ? firstErrorRef : undefined
              }
            >
              <TextFieldSts
                isDisabled={inputDisable}
                activeHoverd={activeHoverd}
                label={inputOnePlaceholder ?? ""}
                data-testid="first-name-organiser"
                id="first-name-organiser"
                aria-label="first-name-organiser"
                width="100%"
                name="value2"
                maxLength={51}
                state={firstNameStatus ?? undefined}
                message={firstNameErrors}
                value={formData.value2}
                onChange={(value) => handleInputChange("value2", value)}
                onBlur={() => setFocusedInputs(getFocusedFields("value2"))}
                msgCustomStyles={{ width: "auto" }}
              />
            </InputValidationWrapper>
            <InputValidationWrapper
              className={`${Styles.input_wrapper} flex flex-row items-start mb-spacing-s`}
              status={lastNameStatus}
              childRef={
                errorDetails.firstError === "value3" ? firstErrorRef : undefined
              }
            >
              <TextFieldSts
                isDisabled={inputDisable}
                activeHoverd={activeHoverd}
                label={inputTwoPlaceholder ?? ""}
                data-testid="last-name-organiser"
                id="last-name-organiser"
                aria-label="last-name-organiser"
                width="100%"
                name="value3"
                maxLength={51}
                state={lastNameStatus ?? undefined}
                message={lastNameErrors}
                value={formData.value3}
                onChange={(value) => handleInputChange("value3", value)}
                onBlur={() => setFocusedInputs(getFocusedFields("value3"))}
                msgCustomStyles={{ width: "auto" }}
              />
            </InputValidationWrapper>

            {/* NATIONALITY */}
            {showNationalityFieldInExpat && (
              <InputValidationWrapper
                className="flex flex-row mb-spacing-s"
                status={nationalityStatus}
                childRef={
                  errorDetails.firstError === "nationalityValue"
                    ? firstErrorRef
                    : undefined
                }
              >
                <div
                  className={` ${Styles.country_wrapper} ${Styles.title_dropdown} ${isNationalityFocused && Styles.dropdown_focused} flex flex-row items-start w-full h-[56px]`}
                  onFocus={() => {
                    if (isTabPressed) {
                      setIsNationalityFocused(true);
                    }
                  }}
                  onBlur={() => setIsNationalityFocused(false)}
                >
                  <Dropdown
                    id="nationality-dropdown"
                    defaultValue={
                      nationalityData?.find((n) => n.id === nationalityId)
                        ?.name ?? undefined
                    }
                    placeholderTextAndLabel={
                      nationalityPlaceholder ?? "Nationality"
                    }
                    strokeColor={getStrokeColor(brand)}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    onSelectionChange={(e: any) => {
                      handleInputChange("nationalityValue", e);
                      if (isdesktop) {
                        setActiveHoverd(false);
                        setInputDisable(true);
                        setTimeout(() => {
                          setInputDisable(false);
                        }, 1);
                        setTimeout(() => {
                          setActiveHoverd(true);
                        }, 1200);
                      } else {
                        setActiveHoverd(false);
                        setInputDisable(true);
                        setTimeout(() => {
                          setInputDisable(false);
                        }, 1);
                      }
                    }}
                    onDropdownBlur={() => {
                      if (getWarningStatus("nationalityValue") === null) {
                        setFocusedInputs(getFocusedFields("nationalityValue"));
                      }
                    }}
                    className={`${Styles.droplistBox}  ${nationalityStatus === "warning" ? Styles.warningMsgClass : ""} ${nationalityStatus === "success" ? Styles.successMsgClass : ""} border-interactive-secondary-default rounded-radius-lg max-h-spacing-xxxl`}
                    placeholderLeftBeforeSelect={0}
                    placeholderLeftAfterSelect={-24}
                    listBoxStyle={{
                      outline: "none",
                      margin: "0px 0px 0px 0px",
                      minWidth: "unset !important",
                      maxHeight: "400px",
                      boxShadow: "0px 0px 12px 0px rgba(15, 23, 42, 0.11)",
                    }}
                    listClassName={`${Styles.listStyle} mt-[10px] rounded-radius-s`}
                    unorderedListClassName="w-full !mt-[3px] !md:ml-0 !ml-[-1px] !border-border-subdued !rounded-radius-lg !px-spacing-m !py-spacing-xs"
                  >
                    {nationalityData &&
                      nationalityData.map((nationality) => (
                        <DropdownItem
                          value={nationality.name}
                          key={nationality.id}
                        >
                          <span className=" w-full leading-[24px] font-proximanova text-2xs font-semibold p-spacing-xs country-name ">
                            {nationality.name}
                          </span>
                        </DropdownItem>
                      ))}
                  </Dropdown>
                </div>
              </InputValidationWrapper>
            )}

            <div
              className={`${Styles.date_picker_wrapper} flex flex-col relative items-start`}
            >
              <InputValidationWrapper
                className={`${Styles.date_picker_wrapper} flex flex-row sm:!justify-between md:!justify-normal relative mb-spacing-s`}
                status={dobStatus}
                childRef={
                  errorDetails.firstError === "dateValue"
                    ? firstErrorRef
                    : undefined
                }
                isDateInput
              >
                <DateInput
                  isDOBScrollable={!isdesktop}
                  defaultDate={
                    formData.dateValue && isValidDate(formData.dateValue)
                      ? new Date(formData.dateValue)
                      : undefined
                  }
                  hintLabel={dobPlaceholder}
                  brand={brand}
                  customizedMessage={
                    getOrganiserDateValidation(
                      formData,
                      travellerType ?? "",
                      dobMaxLimit ?? "",
                      dobMinAge ?? "",
                      dobMaxAge ?? ""
                    ) ?? invalideDate
                  }
                  dobErrorMsgClass={Styles.errorOfDob}
                  isError={dobStatus === "error"}
                  dobWrapperClass={`${Styles.dobWrapper} ${dobStatus === "warning" ? Styles.warningMsgClass : ""} ${dobStatus === "success" ? Styles.successMsgClass : ""}`}
                  onChange={(date: unknown) => {
                    if (isValidDate(date as Date)) {
                      handleInputChange("dateValue", date?.toString() ?? "");
                      setInvalideDate("");
                    } else {
                      handleInputChange(
                        "dateValue",
                        "Invalidate Date of Birth"
                      );
                      setInvalideDate("Invalidate Date of Birth");
                    }
                  }}
                  onBlur={() => setFocusedInputs(getFocusedFields("dateValue"))}
                  onDateComplete={(date: string) => {
                    if (date.length > 0 && date.length < 8) {
                      handleInputChange("dateValue", "");
                      setInvalideDate("");
                    }
                  }}
                />

                <div
                  className={`text-xs leading-[27px] font-semibold text-text-on-primary 
                   ml-3 md:ml-4 mt-4 min-w-[65px] sm:relative sm:right-0`}
                >
                  Age: {calculateAge()}
                </div>
              </InputValidationWrapper>
            </div>

            <div className={` ${Styles.address_block}`}>
              <div className="md:text-xs text-left text-2xs relative">
                {!additionalData && !addressDetails && (
                  <InputValidationWrapper
                    className="postcode-wrapper font-body-font flex flex-row"
                    status={postCodeStatus}
                    childRef={
                      errorDetails.firstError === "postCode"
                        ? firstErrorRef
                        : undefined
                    }
                  >
                    <TextFieldSts
                      activeHoverd={activeHoverd}
                      id="scrollableInput"
                      type="text"
                      name="address"
                      data-testid="address-organiser"
                      width="100%"
                      autoComplete="off"
                      state={postCodeStatus ?? undefined}
                      message={addressErrors || ""}
                      placeholder={props?.adressInputPlaceholder}
                      value={postCode ?? ""}
                      onChange={(e) => {
                        handleChange1(e);
                        scrollInputToTop();
                      }}
                      label="address"
                      labelLeftPadding="16px"
                      onBlur={() =>
                        setFocusedInputs(getFocusedFields("postCode"))
                      }
                      msgCustomStyles={{ width: "auto" }}
                    />
                  </InputValidationWrapper>
                )}
                {suggestions?.[0] && (
                  <ul
                    className={`${
                      Styles.scroll
                    } absolute z-10 bg-white w-full border-gray-300 rounded-lg mt-1 ${
                      suggestions.length > 5
                        ? "overflow-y-scroll md:max-h-[215px] sm:!max-h-[170px]"
                        : ""
                    }`}
                  >
                    {suggestions.map((suggestion) => (
                      <li
                        key={suggestion.id}
                        className="cursor-pointer p-2 hover:bg-gray-100"
                      >
                        <button
                          id={`suggestion-button-${suggestion.id}`}
                          type="button"
                          className="w-full h-full text-left "
                          onClick={() => {
                            handleSuggestionClick(suggestion);
                            setFocusedInputs(getFocusedFields("postCode"));
                            clearAutofillPostCodeData();
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              handleSuggestionClick(suggestion);
                              setFocusedInputs(getFocusedFields("postCode"));
                              clearAutofillPostCodeData();
                            }
                          }}
                        >
                          {`${suggestion.attributes.address}, ${suggestion.attributes.description}`}
                        </button>
                      </li>
                    ))}
                  </ul>
                )}
                {/* change */}
                {!additionalData && !addressDetails && (
                  <div className=" flex mt-spacing-xs">
                    <Button
                      id="enterManuallyButton"
                      btnType="label"
                      type="button"
                      label={props?.enterAddressButton ?? ""}
                      className="text-interactive-secondary-default text-2xs font-semibold font-['Proxima Nova'] underline leading-normal"
                      onClick={() => {
                        setAdditionalData(true);
                        clearAutofillPostCodeData();
                      }}
                      isOnlyActiveOnPress
                    />
                  </div>
                )}
              </div>

              {(additionalData || addressDetails) && (
                <div className=" md:text-xs text-left text-2xs mb-spacing-l">
                  <TransformAnimation
                    currentPage={usagePage as string}
                    isSubSectionFilled={
                      !!addressData[0]?.attributes?.postalCode
                    }
                  >
                    <InputValidationWrapper
                      className={`flex ${Styles.address_field} mt-spacing-s`}
                      status={addressLine1Status}
                      childRef={
                        errorDetails.firstError === "line1"
                          ? firstErrorRef
                          : undefined
                      }
                    >
                      <TextFieldSts
                        activeHoverd={activeHoverd}
                        label={props?.addressLineOne}
                        name="line1"
                        id="addressLineOne"
                        width="100%"
                        state={addressLine1Status ?? undefined}
                        message={invalidErrorMessage.line1 || ""}
                        onChange={(e) => {
                          handleChange(e, "line1");
                        }}
                        onBlur={() => {
                          setFocusedInputs(getFocusedFields("line1"));
                        }}
                        value={addressData[0]?.attributes?.line1 ?? ""}
                        maxLength={MAX_ADDRESS_LENGTH}
                        labelLeftPadding="16px"
                        msgCustomStyles={{ width: "auto" }}
                        data-testid="addressLineOne"
                      />
                    </InputValidationWrapper>
                    <InputValidationWrapper
                      className={`flex ${Styles.address_field} mt-spacing-s`}
                      status={addressLine2Status}
                      childRef={
                        errorDetails.firstError === "line2"
                          ? firstErrorRef
                          : undefined
                      }
                    >
                      <TextFieldSts
                        activeHoverd={activeHoverd}
                        label={props?.addressLineTwo}
                        name="line2"
                        id="addressLineTwo"
                        width="100%"
                        state={addressLine2Status ?? undefined}
                        message={invalidErrorMessage?.line2 || ""}
                        onChange={(e) => {
                          handleChange(e, "line2");
                        }}
                        onBlur={() => {
                          setFocusedInputs(getFocusedFields("line2"));
                        }}
                        value={addressData[0]?.attributes?.line2 ?? ""}
                        maxLength={MAX_ADDRESS_LENGTH}
                        labelLeftPadding="16px"
                        msgCustomStyles={{ width: "auto" }}
                        data-testid="addressLineTwo"
                      />
                    </InputValidationWrapper>
                    <InputValidationWrapper
                      className={`flex ${Styles.address_field} mt-spacing-s`}
                      status={addressCityStatus}
                      childRef={
                        errorDetails.firstError === "city"
                          ? firstErrorRef
                          : undefined
                      }
                    >
                      <TextFieldSts
                        activeHoverd={activeHoverd}
                        label={props?.TownCityPlaceholder}
                        name="city"
                        id="selectCity"
                        width="100%"
                        autoComplete="off"
                        state={addressCityStatus ?? undefined}
                        message={invalidErrorMessage.city || ""}
                        onBlur={() => {
                          setFocusedInputs(getFocusedFields("city"));
                        }}
                        onChange={(e) => {
                          handleChange(e, "city");
                        }}
                        value={addressData[0]?.attributes?.city ?? ""}
                        maxLength={40}
                        labelLeftPadding="16px"
                        msgCustomStyles={{ width: "auto" }}
                      />
                    </InputValidationWrapper>
                    <InputValidationWrapper
                      className="flex mt-spacing-s"
                      status={addressPostalCodeStatus}
                      dataTestid="postcode-wrapper"
                      childRef={
                        errorDetails.firstError === "postalCode"
                          ? firstErrorRef
                          : undefined
                      }
                    >
                      <TextFieldSts
                        label={props?.postcodePlaceholder}
                        name="postalCode"
                        id="postcodePlaceholder"
                        width="100%"
                        state={addressPostalCodeStatus ?? undefined}
                        onBlur={() => {
                          setFocusedInputs(getFocusedFields("postalCode"));
                        }}
                        message={
                          (addressData[0]?.attributes?.postalCode?.length > 2 &&
                            invalidErrorMessage.postalCode) ||
                          ""
                        }
                        onChange={(e) => {
                          handleChange(e, "postalCode");
                        }}
                        value={
                          postCode ??
                          (addressData[0] &&
                          addressData[0]?.attributes?.postalCode
                            ? addressData[0].attributes.postalCode
                            : "")
                        }
                        labelLeftPadding="16px"
                        msgCustomStyles={{ width: "auto" }}
                      />
                    </InputValidationWrapper>
                    <div className=" flex mt-spacing-xs">
                      <Button
                        id="searchByPostCodeButton"
                        btnType="label"
                        type="button"
                        label={props?.searchByPostCodeButton ?? ""}
                        className={`${getTextColorClasses(brand)} text-2xs font-semibold font-['Proxima Nova'] underline leading-normal`}
                        onClick={() => {
                          setAdditionalData(false);
                          setAddressDetails("");
                          // reset manual postcode data
                          handleChange("", "line1");
                          handleChange("", "line2");
                          handleChange("", "city");
                          handleChange("", "postalCode");
                          // reset autofill postcode data
                          clearAutofillPostCodeData();
                          // mark as touched
                          setFocusedInputs(getFocusedFields("postalCode"));
                        }}
                        isOnlyActiveOnPress
                      />
                    </div>
                  </TransformAnimation>
                </div>
              )}
            </div>

            <div className="md:mt-spacing-xxl mt-spacing-l text-left">
              <h1 className=" font-header-font lg:text-[39px] md:text-3xl text-[26px] lg:leading-[44.85px] md:leading-[34.1px] leading-[29.9px] text-text-title md:text-left text-center md:mb-spacing-l mb-spacing-m">
                {questionTwoText}
              </h1>
              <div
                className={`${Styles.traveller_btn_wrapper}`}
                ref={
                  errorDetails.firstError === "travelling"
                    ? firstErrorRef
                    : undefined
                }
              >
                <FadeAnimation>
                  <ButtonGroup
                    setSelectedAnswer={(answer: number) => {
                      handleIsPersonTravelling(answer);
                      setFocusedInputs(getFocusedFields("travelling"));
                    }}
                    selectedAnswer={
                      findAnswerQThreeByValue(formData.travelling)?.id
                    }
                    answerArray={answersQuestionThree}
                    location={QuestionThree}
                    type="organiser"
                  />
                </FadeAnimation>
              </div>
            </div>

            {newMedicalFlowEnabled && travellerType === Individual && (
              <>
                <div
                  ref={
                    errorDetails.firstError === "hasMedicalConditions"
                      ? firstErrorRef
                      : undefined
                  }
                />
                <TravellerMedicalAnswer
                  show={formData.travelling}
                  questionText={medicalQuestionText}
                  questionInfoText={medicalQuestionInfoText}
                  serviceDeniedText={medicalServiceDeniedText}
                  yesBtnText={medicalYesBtnText}
                  noBtnText={medicalNoBtnText}
                  location={QuestionThree}
                  selectedValue={formData?.hasMedicalConditions}
                  onChange={handleMedicalQuestion}
                />
              </>
            )}
            {showErrors &&
              errorDetails.count > 0 &&
              formData?.hasMedicalConditions !== true && (
                <div className="mt-spacing-l">
                  <InlineInfoBox
                    id="traveler-form-inline-info-box"
                    content={
                      <p className="text-text-info-1-primary">
                        {getAlertMessageIncompleteSection()}
                        &nbsp;
                        <button
                          type="button"
                          className="underline font-bold leading-[24px]"
                          onClick={() => {
                            const el = firstErrorRef?.current as Element | null;
                            if (el) {
                              const topOffset =
                                el.getBoundingClientRect().top +
                                window.scrollY -
                                20;
                              window.scrollTo({
                                top: topOffset,
                                behavior: "smooth",
                              });
                            }
                          }}
                        >
                          {getAlertMessageLinkSection()}
                        </button>
                      </p>
                    }
                    style={{
                      width: "100%",
                      backgroundColor: "#FFF0E9",
                      borderColor: "#D06D10",
                      padding: "8px 16px 8px 16px",
                      borderRadius: "8px",
                      color: "#272427",
                      fontSize: "16px",
                      lineHeight: "24px",
                    }}
                  />
                </div>
              )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default withBrand(OrganiserForm);
