import parse from "html-react-parser";
import { useMemo } from "react";
import { InlineInfoBox } from "staysure-component-library";

import FadeAnimation from "@/components/atoms/animation/fade-animation";
import ButtonGroup from "@/components/atoms/button-group/button-group";
import withBrand from "@/components/with-brand";
import { Brand } from "@/config/app-constant";
import {
  answersQuestionThree,
  findAnswerQThreeByValue,
} from "@/utils/cruise.utils";

import Styles from "./traveller-medical-answer.module.css";

const fontColourConfig = [
  {
    brand: Brand.STAYSURE,
    fontColour: "#2E2E2E",
    helpTextClass: Styles.cover_denied_staysure,
  },
  {
    brand: Brand.AVANTI,
    fontColour: "#2E2D2C",
    helpTextClass: Styles.cover_denied_avanti,
  },
];

type TravellerMedicalAnswerTypes = {
  questionText: string;
  questionInfoText: string;
  serviceDeniedText: string;
  yesBtnText: string;
  noBtnText: string;
  location: string;
  show: boolean | undefined;
  selectedValue: boolean | undefined;
  onChange: (val: number) => void;
  brand?: Brand;
};

function TravellerMedicalAnswer({
  questionText,
  questionInfoText,
  serviceDeniedText,
  yesBtnText,
  noBtnText,
  location,
  show,
  selectedValue,
  onChange,
  brand,
}: Readonly<TravellerMedicalAnswerTypes>) {
  const selectedOption = useMemo(
    () => findAnswerQThreeByValue(selectedValue)?.id,
    [selectedValue]
  );

  const answerArray = useMemo(() => {
    return answersQuestionThree.map((ans) => ({
      ...ans,
      label: ans.value ? yesBtnText : noBtnText,
    }));
  }, [yesBtnText, noBtnText]);

  const blockedOption = useMemo(() => {
    const filteredOptionsArr = answerArray.filter(
      (v) => v.label === yesBtnText
    );
    return filteredOptionsArr[0] ? filteredOptionsArr[0].id : null;
  }, [answerArray, yesBtnText]);

  const brandColourStyles = useMemo(() => {
    const selected = fontColourConfig.find((obj) => obj.brand === brand);
    return selected;
  }, [brand]);

  if (!questionText) {
    return null;
  }

  return (
    <div
      className={`md:mt-spacing-xxl mt-spacing-l text-left ${show ? "" : "hidden"}`}
    >
      <h1
        className=" font-header-font lg:text-[39px] md:text-3xl text-[26px] lg:leading-[44.85px] 
        md:leading-[34.1px] leading-[29.9px] text-text-title md:text-left text-center"
      >
        {questionText}
      </h1>
      <div className={`${Styles.medical_question_btn_wrapper}`}>
        <span
          className="block text-xs [&_ul]:pl-8 [&_li]:list-disc [&_li]:leading-[27px] [&_li]:mb-3 my-spacing-l"
          style={{ color: brandColourStyles?.fontColour ?? "" }}
        >
          {parse(questionInfoText)}
        </span>
        <FadeAnimation>
          <ButtonGroup
            setSelectedAnswer={onChange}
            selectedAnswer={selectedOption}
            answerArray={answerArray}
            location={location}
            type="organiser"
          />
        </FadeAnimation>
      </div>
      {blockedOption === selectedOption && (
        <div className="mt-spacing-l">
          <InlineInfoBox
            id="traveler-form-inline-info-box"
            content={
              <div
                className={brandColourStyles?.helpTextClass}
                style={{ color: brandColourStyles?.fontColour }}
              >
                {parse(serviceDeniedText)}
              </div>
            }
            style={{
              width: "100%",
              backgroundColor: "#EDF2FF",
              borderColor: "#006BD6",
              padding: "8px 16px 8px 16px",
              borderRadius: "8px",
              color: "#272427",
              fontSize: "16px",
              lineHeight: "24px",
            }}
          />
        </div>
      )}
    </div>
  );
}

export default withBrand(TravellerMedicalAnswer);
