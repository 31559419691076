import {
  AlertTriangleFilledIcon,
  CheckCircleIcon,
  XCircleFilledIcon,
} from "staysure-component-library";

export default function InputValidationWrapper({
  children,
  className,
  status,
  dataTestid,
  childRef,
  isDateInput,
}: Readonly<{
  children: React.ReactNode;
  className: string;
  status: "success" | "error" | "warning" | null;
  dataTestid?: string;
  isDateInput?: boolean;
  childRef?: React.MutableRefObject<HTMLDivElement | null>;
}>) {
  return (
    <div
      className={className ?? ""}
      data-testid={dataTestid ?? null}
      ref={childRef ?? null}
    >
      {children}
      <div
        className={
          isDateInput
            ? `sm:relative sm:left-[4px]  w-8 mt-4 md:relative md:!left-[12px]`
            : `ml-2 sm:ml-3 w-8 mt-4`
        }
      >
        {status === "success" && (
          <CheckCircleIcon state="check" fill="#008205" stroke="#f4f4f4" />
        )}
        {status === "error" && (
          <XCircleFilledIcon fill="#DF413C" stroke="#f4f4f4" />
        )}
        {status === "warning" && (
          <AlertTriangleFilledIcon fill="#D06D10" stroke="#f4f4f4" />
        )}
      </div>
    </div>
  );
}
