import { Brand } from "@/config/app-constant";

const coverTypeConfig = [
  {
    brand: Brand.STAYSURE,
    image_div_width_height: " md:w-[205px] md:h-[408px]",
    modal_title:
      "md:text-[31px] text-[23px] md:leading-[34px] leading-[28px] w-[190px] ",
    image_range: " md:object-[90%] ",
    check_cricle_color: "#306582",
    modal_description_color: "",
    load_more_btn_colors:
      "text-interactive-secondary-default hover:bg-interactive-secondary-pressed font-proxima-nova ",
  },
  {
    brand: Brand.AVANTI,
    image_div_width_height: " md:w-[205px] md:h-[486px]",
    modal_title:
      "md:text-[31px] text-[23px] md:leading-[34px] leading-[26px] w-[185px] ",
    image_range: "",
    check_cricle_color: "#6f8de4",
    modal_description_color: "#000000",
    load_more_btn_colors:
      "text-interactive-secondary-default hover:bg-interactive-secondary-hovered body-font ",
  },
];

export default coverTypeConfig;
