/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react-hooks/exhaustive-deps */

"use client";

import { Lookup, SpringRef } from "@react-spring/web";
import { usePathname } from "next/navigation"; // To get the route
import { Suspense, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import CoverTypeContainer from "@/components/molecules/questions-container/cover-type/cover-type-container";
import CruiseContainer from "@/components/molecules/questions-container/cruise/cruise-container";
import LocationToContainer from "@/components/molecules/questions-container/location-to/location-to-container";
import OrganiserContainer from "@/components/molecules/questions-container/organizer/organizer-container";
import PartyTypeContainer from "@/components/molecules/questions-container/party-type/party-type-container";
import TravelDateContainer from "@/components/molecules/questions-container/travel-date/travel-date-container";
import TravellerContainer from "@/components/molecules/questions-container/traveller/traveller-container";
import {
  QuestionEight,
  QuestionFive,
  QuestionFour,
  QuestionOne,
  QuestionSeven,
  QuestionSix,
  QuestionThree,
} from "@/config/app-constant";
import { useQuestionsState } from "@/providers/questions-state-provider";
import { RootState } from "@/store/root-reducer";
import {
  Organiser,
  QuestionPayload,
  STCountryArray,
  Traveller,
} from "@/types/QuestionPayloadType";
import { findIdbyAnswer } from "@/utils/cover-type.utils";
import { findAnswerQThreeByValue } from "@/utils/cruise.utils";
import { updateAnswerLabels } from "@/utils/party-type.utils";
import { getSubmitButtonState } from "@/utils/question-location-to.utils";
import { CoverTypeContent } from "@/utils/schema/cover-type-page-schema.utils";
import { CruiseContent } from "@/utils/schema/cruise-page-schema.utils";
import { LocationToContent } from "@/utils/schema/location-to-page-schema.utils";
import { OrganiserContent } from "@/utils/schema/organiser-page-schema.utils";
import { PartyTypeContent } from "@/utils/schema/party-type-page-schema.utils";
import { DateTypeContent } from "@/utils/schema/travel-date-page-schema.utils";
import { TravellersContent } from "@/utils/schema/travellers-page-schema.utils";

type CommonPageContent =
  | (LocationToContent & { page: "travelling-to" })
  | (CoverTypeContent & { page: "cover-type" })
  | (CruiseContent & { page: "cruise" })
  | (DateTypeContent & { page: "travel-dates" })
  | (PartyTypeContent & { page: "cover-for" })
  | (OrganiserContent & { page: "organiser" })
  | (TravellersContent & { page: "traveller" });

export default function QuestionsContainer({
  pageContent,
  formRef,
  ctaRef,
}: Readonly<{
  showondesktop?: string;
  pageContent: CommonPageContent;
  formRef?: SpringRef<Lookup>;
  ctaRef?: SpringRef<Lookup>;
}>) {
  const pathname = usePathname(); // To access current route
  const { selected, setSelectedState } = useQuestionsState();
  const { quoteResponse, loading } = useSelector(
    (state: RootState) => state.question
  );

  const [toLocationCountryDetails, setToLocationCountryDetails] = useState<
    STCountryArray | undefined
  >(undefined);
  const [policyType, setPolicyType] = useState<string | undefined>(undefined);
  const [toCountry, setToCountry] = useState<string | undefined>(undefined);
  const [organiser, setOrganiser] = useState<Organiser | undefined>(undefined);
  const [selectedAnswer, setSelectedAnswer] = useState(0);
  const [travellerDetails, setTravellerDetails] = useState<Traveller[]>([]);

  // for common things
  const handleCommonStates = (quoteResponseData: QuestionPayload | null) => {
    const selectedPolicyType =
      quoteResponseData?.attributes?.tripDetails?.policyType ?? "";

    setPolicyType(selectedPolicyType);
    if (selectedPolicyType === "ANNUAL_MULTI_TRIP") {
      const toLocation = quoteResponseData?.attributes?.tripDetails?.area;
      if (toLocation) setToCountry(toLocation);
    } else {
      const toLocation = quoteResponseData?.attributes?.tripDetails?.toLocation;
      if (toLocation)
        setToLocationCountryDetails(
          quoteResponseData?.attributes?.tripDetails?.toLocationCountryDetails
        );
      setToCountry(toLocation);
    }

    setOrganiser(quoteResponseData?.attributes?.organiser);

    if (quoteResponseData?.attributes?.travellerDetails?.traveller) {
      setTravellerDetails(
        quoteResponseData?.attributes?.travellerDetails?.traveller
      );
    }
  };

  // for change selected state

  const handleSelected = (quoteResponseData: QuestionPayload | null) => {
    if (quoteResponse) {
      if (pathname === "/") {
        const selectedType =
          quoteResponseData?.attributes?.tripDetails?.policyType;
        let policyId: number | undefined = 0;
        if (selectedType) {
          policyId = findIdbyAnswer(selectedType)?.id;
        }
        setSelectedState(policyId ?? 0);
      }

      if (pathname.replace(/^\/|\/$/g, "") === QuestionThree) {
        const selectedPolicyType =
          quoteResponseData?.attributes?.tripDetails?.policyType ?? "";
        if (selectedPolicyType === "ANNUAL_MULTI_TRIP") {
          const toLocation = quoteResponseData?.attributes?.tripDetails?.area;
          if (toLocation) setSelectedState(toLocation);
        } else {
          const toLocation =
            quoteResponseData?.attributes?.tripDetails?.toLocation;
          if (toLocation) setSelectedState(toLocation);
          setToLocationCountryDetails(
            quoteResponseData?.attributes?.tripDetails?.toLocationCountryDetails
          );
        }
      }

      if (pathname.replace(/^\/|\/$/g, "") === QuestionFour) {
        const goingOnCruise =
          quoteResponseData?.attributes?.tripDetails?.goingOnCruise;
        const cruiseId = findAnswerQThreeByValue(goingOnCruise)?.id;
        setSelectedState(cruiseId ?? 0);
      }

      if (pathname.replace(/^\/|\/$/g, "") === QuestionFive) {
        const startDateSaved: string | undefined =
          quoteResponseData?.attributes?.tripDetails?.startDate;

        if (startDateSaved) {
          setSelectedState(startDateSaved);
        }
      }

      if (pathname.replace(/^\/|\/$/g, "") === QuestionSix) {
        const partyType = quoteResponseData?.attributes?.tripDetails?.partyType;
        setSelectedState(partyType || "");
      }

      if (pathname.replace(/^\/|\/$/g, "") === QuestionSeven) {
        setSelectedState(
          quoteResponseData?.attributes?.organiser?.travelling || ""
        );
      }
    }
  };

  useEffect(() => {
    handleCommonStates(quoteResponse);
    handleSelected(quoteResponse);
  }, [quoteResponse]);

  const setAnswerNo = (answerNo: number) => {
    setSelectedAnswer(answerNo);
  };

  // Conditionally render either LocationToContainer or CoverTypeContainer based on the route
  const renderCurrentQuestion = () => {
    if (
      pathname.replace(/^\/|\/$/g, "") === QuestionThree &&
      pageContent.page === "travelling-to"
    ) {
      return (
        <LocationToContainer
          tripDetails={quoteResponse?.attributes?.tripDetails || {}}
          usagePage={QuestionThree}
          pageContent={{
            ...pageContent,
            cancelEditingBtnTextEurope: "Cancel editing",
            cancelEditingBtnTextWorldWide: "Cancel editing",
            finishEditingBtnText: "Finish editing",
          }}
          stateFromLocation={
            quoteResponse?.attributes?.tripDetails?.fromLocation ?? ""
          }
          toLocationCountryDetails={toLocationCountryDetails}
          selectedpolicyType={policyType}
          buttonDisable={getSubmitButtonState(
            policyType ?? "",
            (selected as string) || ""
          )}
          setToLocationCountryDetails={setToLocationCountryDetails}
          quoteResponse={quoteResponse}
          quoteLoading={loading}
          formRef={formRef}
          ctaRef={ctaRef}
        />
      );
    }
    if (pathname === "/" && pageContent.page === "cover-type") {
      return (
        <CoverTypeContainer
          tripDetails={quoteResponse?.attributes?.tripDetails || {}}
          quoteResponse={quoteResponse}
          quoteLoading={loading}
          formRef={formRef}
          ctaRef={ctaRef}
          usagePage={QuestionOne}
          pageContent={pageContent}
        />
      );
    }
    if (
      pathname.replace(/^\/|\/$/g, "") === QuestionFour &&
      pageContent.page === "cruise"
    ) {
      return (
        <CruiseContainer
          tripDetails={quoteResponse?.attributes?.tripDetails || {}}
          buttonDisable={selected === 0}
          usagePage={QuestionFour}
          quoteResponse={quoteResponse}
          quoteLoading={loading}
          formRef={formRef}
          ctaRef={ctaRef}
          pageContent={pageContent}
        />
      );
    }

    if (
      pathname.replace(/^\/|\/$/g, "") === QuestionFive &&
      pageContent.page === "travel-dates"
    ) {
      return (
        <TravelDateContainer
          tripDetails={quoteResponse?.attributes?.tripDetails || {}}
          pageContent={pageContent}
          usagePage={QuestionFive}
          policyType={policyType || ""}
          fromCountry={quoteResponse?.attributes?.tripDetails?.fromLocation}
          toCountry={toCountry}
          tripStartDate={
            quoteResponse?.attributes?.tripDetails?.startDate || undefined
          }
          tripEndDate={
            quoteResponse?.attributes?.tripDetails?.endDate || undefined
          }
          quoteResponse={quoteResponse}
          quoteLoading={loading}
          ctaRef={ctaRef}
          formRef={formRef}
        />
      );
    }

    if (
      pathname.replace(/^\/|\/$/g, "") === QuestionSix &&
      pageContent.page === "cover-for"
    ) {
      const qFiveAnwerArray = updateAnswerLabels(pageContent);
      return (
        <PartyTypeContainer
          tripDetails={quoteResponse?.attributes?.tripDetails || {}}
          answerArray={qFiveAnwerArray as []}
          pageContent={pageContent}
          buttonDisable={!selected}
          usagePage={QuestionSix}
          selectedParty={selected as string}
          quoteResponse={quoteResponse}
          quoteLoading={loading}
          ctaRef={ctaRef}
          formRef={formRef}
        />
      );
    }

    if (
      pathname.replace(/^\/|\/$/g, "") === QuestionSeven &&
      pageContent.page === "organiser"
    ) {
      return (
        <OrganiserContainer
          travellerType={
            quoteResponse?.attributes?.tripDetails?.partyType ?? "SINGLE"
          }
          usagePage={QuestionSeven}
          tripDetails={quoteResponse?.attributes?.tripDetails || {}}
          travellerDetails={quoteResponse?.attributes?.travellerDetails || {}}
          organiser={organiser}
          quoteResponse={quoteResponse}
          addressBlockButton={undefined}
          quoteLoading={loading}
          pageContent={pageContent}
          ctaRef={ctaRef}
          formRef={formRef}
        />
      );
    }

    if (
      pathname.replace(/^\/|\/$/g, "") === QuestionEight &&
      pageContent.page === "traveller"
    ) {
      return (
        <TravellerContainer
          pageContent={pageContent}
          travellerType={
            quoteResponse?.attributes?.tripDetails?.partyType ?? "SINGLE"
          }
          setSelectedAnswer={setAnswerNo}
          buttonDisable={selectedAnswer === 0}
          usagePage={QuestionEight}
          travellerDetailsEdit={travellerDetails}
          medicalTermsAndConditions={
            quoteResponse?.attributes?.travellerDetails
              ?.medicalTermsAndConditions || undefined
          }
          tripDetails={quoteResponse?.attributes?.tripDetails || {}}
          organizer={organiser}
          quoteResponse={quoteResponse}
          quoteLoading={loading}
          formRef={formRef}
          ctaRef={ctaRef}
        />
      );
    }

    return null;
  };

  return <Suspense>{renderCurrentQuestion()}</Suspense>;
}
