/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react-hooks/exhaustive-deps */

"use client";

import { Lookup, SpringRef } from "@react-spring/web";
import { usePathname } from "next/navigation"; // To get the route
import { Suspense, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import FadeAnimation from "@/components/atoms/animation/fade-animation";
import { MagnoliaImage } from "@/components/atoms/image/image";
import Loader from "@/components/atoms/loader/loader";
import { CoverTypeSupportSection } from "@/components/molecules/question-detail-section/assistant-description/cover-type-support/cover-type-special-support-section";
import QuestionDetailSection from "@/components/molecules/question-detail-section/question-detail-section";
import TrustPilot from "@/components/molecules/trustpilot-component/trustpilot";
import {
  QuestionEight,
  QuestionFive,
  QuestionFour,
  QuestionSeven,
  QuestionSix,
  QuestionThree,
} from "@/config/app-constant";
import { useQuestionsState } from "@/providers/questions-state-provider";
import { RootState } from "@/store/root-reducer";
import { QuestionPayload } from "@/types/QuestionPayloadType";
import {
  getSelectedSupportContent as getSelectedSupportContentCoverType,
  getValidateState,
} from "@/utils/cover-type.utils";
import { getSelectedSupportContent as getSelectedSupportContentCruise } from "@/utils/cruise.utils";
import { getSelectedSupportContent as getSelectedSupportContentOrganiser } from "@/utils/organiser.utils";
import { getSelectedSupportContent as getSelectedSupportContentPartType } from "@/utils/party-type.utils";
import { getSelectedLocationToSupport } from "@/utils/question-location-to.utils";
import { CoverTypeContent } from "@/utils/schema/cover-type-page-schema.utils";
import { CruiseContent } from "@/utils/schema/cruise-page-schema.utils";
import { LocationToContent } from "@/utils/schema/location-to-page-schema.utils";
import { OrganiserContent } from "@/utils/schema/organiser-page-schema.utils";
import { PartyTypeContent } from "@/utils/schema/party-type-page-schema.utils";
import { DateTypeContent } from "@/utils/schema/travel-date-page-schema.utils";
import { TravellersContent } from "@/utils/schema/travellers-page-schema.utils";
import { getSelectedSupportContent as getSelectedSupportContentTravelDates } from "@/utils/travel-date.utils";
import { getSelectedSupportContent as getSelectedSupportContentTraveller } from "@/utils/traveller.utils";

type CommonPageContent =
  | (LocationToContent & { page: "travelling-to" })
  | (CoverTypeContent & { page: "cover-type" })
  | (CruiseContent & { page: "cruise" })
  | (DateTypeContent & { page: "travel-dates" })
  | (PartyTypeContent & { page: "cover-for" })
  | (OrganiserContent & { page: "organiser" })
  | (TravellersContent & { page: "traveller" });

export default function QuestionsSupportSection({
  pageContent,
  showondesktop = "false",
  showontab = "false",
  loading,
  supportRef,
  brand,
}: Readonly<{
  showondesktop?: string;
  showontab?: string;
  pageContent: CommonPageContent;
  supportRef?: SpringRef<Lookup>;
  loading?: boolean;
  brand?: string;
}>) {
  const pathname = usePathname(); // To access current route
  const { quoteResponse } = useSelector((state: RootState) => state.question);
  const { selected, selectedTwo, setSelectedState, hasMedicalCondition } =
    useQuestionsState();
  const [q1Filled, setQ1Filled] = useState<boolean>(false);
  const [policyType, setPolicyType] = useState<string | undefined>();
  const [selectedPartyType, setSelectedPartyType] = useState<
    string | undefined
  >("");
  const [isClient, setIsClient] = useState(false);
  const { trustpilot } = pageContent;

  const handleSelected = (quoteResponseData: QuestionPayload | null) => {
    if (quoteResponse) {
      if (pathname.replace(/^\/|\/$/g, "") === QuestionFive) {
        const startDateSaved: string | undefined =
          quoteResponseData?.attributes?.tripDetails?.startDate;

        if (startDateSaved) {
          setSelectedState(startDateSaved);
        }
      }

      if (pathname.replace(/^\/|\/$/g, "") === QuestionSix) {
        const partyType = quoteResponseData?.attributes?.tripDetails?.partyType;
        setSelectedState(partyType || "");
      }

      if (pathname.replace(/^\/|\/$/g, "") === QuestionSeven) {
        setSelectedState(quoteResponse?.attributes?.organiser?.travelling);
      }
    }
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const policyType = quoteResponse?.attributes?.tripDetails?.policyType || "";
    setPolicyType(policyType);

    if (quoteResponse) {
      const selectedTypeEdited: string | undefined =
        quoteResponse?.attributes?.tripDetails?.partyType;

      setSelectedPartyType(selectedTypeEdited);
    }

    handleSelected(quoteResponse);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quoteResponse]);

  useEffect(() => {
    // This code will run only on the client side
    setIsClient(true);
  }, []);

  // Conditionally render based on the route
  const renderCurrentQuestion = () => {
    if (
      pathname.replace(/^\/|\/$/g, "") === QuestionThree &&
      pageContent.page === "travelling-to"
    ) {
      const { drawerContent, drawerEnable, rightContent, loadMoreButtonText } =
        getSelectedLocationToSupport(
          selected as string,
          pageContent,
          selectedTwo,
          policyType || ""
        );
      return (
        <QuestionDetailSection
          rightContent={rightContent}
          loadMoreButtonText={loadMoreButtonText}
          supportDrawer={drawerContent}
          loadMoreButtonEnable={drawerEnable}
        />
      );
    }

    if (pathname === "/" && pageContent.page === "cover-type") {
      const { drawerContent, drawerEnable, rightContent, loadMoreButtonText } =
        getSelectedSupportContentCoverType(selected as number, pageContent);

      const {
        backgroundImageDesktop,
        backgroundImageTabMobile,
        trustpilotDescription,
        navigateUrl,
        brandDomain,
        trustpilotSupportCopyText,
      } = pageContent;

      const selectedValidationParam: "showontab" | "showondesktop" | null =
        getValidateState(brand || "");

      const backgroundImageTab: MagnoliaImage | undefined | null =
        typeof backgroundImageTabMobile === "string"
          ? {
              "@id": "",
              "@link": backgroundImageTabMobile,
              "@name": "",
              "@path": "",
              "@type": "",
              "@uuid": "",
            }
          : backgroundImageTabMobile;

      const backgroundImageDesktopM: MagnoliaImage | undefined | null =
        typeof backgroundImageDesktop === "string"
          ? {
              "@id": "",
              "@link": backgroundImageDesktop,
              "@name": "",
              "@path": "",
              "@type": "",
              "@uuid": "",
            }
          : backgroundImageDesktop;

      const showDevice = {
        showondesktop,
        showontab,
      };

      if (selected !== 0 && loading === false && !q1Filled) {
        setQ1Filled(true);
      }

      if (selected === 0 && loading === false) {
        return (
          <CoverTypeSupportSection
            trustpilotDescription={trustpilotDescription}
            navigateUrl={navigateUrl}
            brandDomain={brandDomain}
            backGroundImage={
              selectedValidationParam &&
              showDevice[selectedValidationParam] === "false"
                ? backgroundImageTab
                : backgroundImageDesktopM
            }
            showondesktop={showondesktop}
            showontab={showontab}
            trustpilotSupportCopyText={trustpilotSupportCopyText}
          />
        );
      }

      return (
        <div
          data-testid="questions-support-content"
          className="rounded-radius-xxl bg-dark-surface-accent-1-primary flex lg:flex-col flex-col-reverse text-surface-neutral-option-2 h-full"
        >
          {showondesktop === "true" && trustpilot && q1Filled && (
            <TrustPilot
              trustpilotText={trustpilot["0"]?.trustpilotText["0"]}
              trustpilotImage={trustpilot["0"]?.trustpilotImage["0"]}
            />
          )}
          <QuestionDetailSection
            loadMoreButtonText={loadMoreButtonText}
            supportDrawer={drawerContent}
            rightContent={rightContent}
            loadMoreButtonEnable={drawerEnable}
          />
        </div>
      );
    }

    if (
      pathname.replace(/^\/|\/$/g, "") === QuestionFour &&
      pageContent.page === "cruise"
    ) {
      const { drawerContent, rightContent, loadMoreButtonText } =
        getSelectedSupportContentCruise(selected as number, pageContent);
      return (
        <QuestionDetailSection
          loadMoreButtonText={loadMoreButtonText}
          supportDrawer={drawerContent}
          rightContent={rightContent}
          loadMoreButtonEnable
        />
      );
    }

    if (
      pathname.replace(/^\/|\/$/g, "") === QuestionFive &&
      pageContent.page === "travel-dates"
    ) {
      const { drawerContent, drawerEnable, rightContent, loadMoreButtonText } =
        getSelectedSupportContentTravelDates(
          selected as string,
          pageContent,
          policyType || ""
        );
      return (
        <QuestionDetailSection
          rightContent={rightContent}
          loadMoreButtonText={loadMoreButtonText}
          supportDrawer={drawerContent}
          loadMoreButtonEnable={drawerEnable}
        />
      );
    }

    if (
      pathname.replace(/^\/|\/$/g, "") === QuestionSix &&
      pageContent.page === "cover-for"
    ) {
      const { drawerContent, drawerEnable, rightContent, loadMoreButtonText } =
        getSelectedSupportContentPartType(selected as string, pageContent);
      return (
        <QuestionDetailSection
          rightContent={rightContent}
          loadMoreButtonEnable={drawerEnable}
          supportDrawer={drawerContent}
          loadMoreButtonText={loadMoreButtonText}
        />
      );
    }

    if (
      pathname.replace(/^\/|\/$/g, "") === QuestionSeven &&
      pageContent.page === "organiser"
    ) {
      const { drawerContent, drawerEnable, rightContent, loadMoreButtonText } =
        getSelectedSupportContentOrganiser(
          selectedPartyType,
          pageContent,
          selected as boolean | undefined,
          hasMedicalCondition
        );

      return (
        <QuestionDetailSection
          loadMoreButtonText={loadMoreButtonText}
          supportDrawer={drawerContent}
          rightContent={rightContent}
          loadMoreButtonEnable={drawerEnable}
          styleClass=" text-2xs md:text-xs"
          showCompleted={hasMedicalCondition === false}
        />
      );
    }

    if (
      pathname.replace(/^\/|\/$/g, "") === QuestionEight &&
      pageContent.page === "traveller"
    ) {
      const { drawerContent, drawerEnable, rightContent, loadMoreButtonText } =
        getSelectedSupportContentTraveller(
          selected as boolean,
          pageContent,
          quoteResponse?.attributes?.organiser?.travelling,
          hasMedicalCondition
        );
      return (
        <QuestionDetailSection
          loadMoreButtonText={loadMoreButtonText}
          supportDrawer={drawerContent}
          rightContent={rightContent}
          loadMoreButtonEnable={drawerEnable}
          showCompleted={(selected as boolean) && hasMedicalCondition === false}
        />
      );
    }

    return null;
  };

  return (
    <Suspense>
      {pathname !== "/" ? (
        <div
          data-testid="questions-support-content"
          className="rounded-radius-xxl bg-dark-surface-accent-1-primary flex lg:flex-col flex-col-reverse text-surface-neutral-option-2 h-full"
        >
          {showondesktop === "true" && trustpilot && (
            <TrustPilot
              trustpilotText={trustpilot["0"]?.trustpilotText["0"]}
              trustpilotImage={trustpilot["0"]?.trustpilotImage["0"]}
            />
          )}
          <FadeAnimation animationRef={supportRef}>
            {!isClient ||
            (isClient &&
              (sessionStorage.getItem("pageReload") as unknown as boolean)) ? (
              <Loader />
            ) : (
              renderCurrentQuestion()
            )}
          </FadeAnimation>
        </div>
      ) : (
        <FadeAnimation animationRef={supportRef} className="h-full">
          {renderCurrentQuestion()}
        </FadeAnimation>
      )}
    </Suspense>
  );
}
