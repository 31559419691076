import { MagnoliaImage } from "@/components/atoms/image/image";
import coverTypeConfig from "@/components/molecules/questions-container/cover-type/cover-type-config";

import { CoverTypeContent } from "./schema/cover-type-page-schema.utils";

// eslint-disable-next-line import/prefer-default-export
export const answersQuestionOne = [
  {
    id: 1,
    label: "Single trip cover",
    value: "SINGLE_TRIP",
  },
  {
    id: 2,
    label: "Annual cover",
    value: "ANNUAL_MULTI_TRIP",
  },
  {
    id: 3,
    label: "I'll decide later",
    value: "NOT_SURE",
  },
];

export const notSureFilteredQuestionOne = [
  {
    id: 1,
    label: "Single trip cover",
    value: "SINGLE_TRIP",
  },
  {
    id: 2,
    label: "Annual cover",
    value: "ANNUAL_MULTI_TRIP",
  },
];

export function findAnswerById(id: number | string) {
  return answersQuestionOne.find((answer) => answer.id === id);
}

export function findIdbyAnswer(code: string | null) {
  return answersQuestionOne.find((answer) => answer.value === code);
}

export function getSelectedSupportContent(
  selectedAnswer: string | number | undefined,
  props: CoverTypeContent
) {
  let supportSectionContent: {
    drawerContent: object | null | undefined;
    drawerEnable: boolean | undefined;
    rightContent: object | null | undefined;
    loadMoreButtonText: string;
  } = {
    drawerContent: null,
    drawerEnable: false,
    rightContent: null,
    loadMoreButtonText: "",
  };

  // if (selectedAnswer === 0) {
  //   supportSectionContent = {
  //     drawerContent: props.supportDrawer,
  //     drawerEnable: props.loadMoreEnable,
  //     rightContent: props.assistantDescription,
  //     loadMoreButtonText: props.loadMoreButtonText,
  //   };
  // }
  if (selectedAnswer === 1) {
    supportSectionContent = {
      drawerContent: props.supportDrawerST,
      drawerEnable: props.loadMoreEnableST,
      rightContent: props.answerOneDescription,
      loadMoreButtonText: props.loadMoreButtonTextST,
    };
  }

  if (selectedAnswer === 2) {
    supportSectionContent = {
      drawerContent: props.supportDrawerAMT,
      drawerEnable: props.loadMoreEnableAMT,
      rightContent: props.answerTwoDescription,
      loadMoreButtonText: props.loadMoreButtonTextAMT,
    };
  }
  if (selectedAnswer === 3) {
    supportSectionContent = {
      drawerContent: props.supportDrawerDL,
      drawerEnable: props.loadMoreEnableDL,
      rightContent: props.answerThreeDescription,
      loadMoreButtonText: props.loadMoreButtonTextDL,
    };
  }
  return supportSectionContent;
}

export function modifyCoverTypeButtonArray(
  ctaButtonOneLabel: string | undefined,
  ctaButtonTwoLabel: string | undefined,
  ctaButtonThreeLabel: string | undefined,
  removeNotSureButton: boolean | undefined
) {
  const newArray = answersQuestionOne
    .filter((answer) => {
      // Filter out answer with id === 3 if removeNotSureButton is true
      return !(answer.id === 3 && removeNotSureButton);
    })
    .map((answer) => {
      if (answer.id === 1) {
        return { ...answer, label: ctaButtonOneLabel };
      }

      if (answer.id === 2) {
        return { ...answer, label: ctaButtonTwoLabel };
      }

      if (answer.id === 3) {
        return { ...answer, label: ctaButtonThreeLabel };
      }

      return answer;
    });

  return newArray;
}

const validationParams: {
  brand: string;
  validationParam: "showontab" | "showondesktop";
}[] = [
  {
    brand: "staysure",
    validationParam: "showontab",
  },
  {
    brand: "avanti",
    validationParam: "showondesktop",
  },
];

export function getValidateState(
  lBrand: string
): "showontab" | "showondesktop" | null {
  let selectedPramas: "showontab" | "showondesktop" | null = null;
  validationParams.forEach((params) => {
    if (params.brand === lBrand) {
      selectedPramas = params.validationParam;
    }
  });
  return selectedPramas;
}

export function createImageObject(imageUrl: string): MagnoliaImage {
  return {
    "@id": "",
    "@link": imageUrl,
    "@name": "",
    "@path": "",
    "@type": "",
    "@uuid": "",
  };
}

export function getDivStyle(lBrand: string) {
  const defaultStyle = {
    image_div_width_height: "",
    image_range: "",
    check_cricle_color: "",
    modal_description_color: "",
    load_more_btn_colors: "",
    modal_title: "",
  };
  return coverTypeConfig.find((obj) => obj.brand === lBrand) || defaultStyle;
}
