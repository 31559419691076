import { Key, useEffect, useState } from "react";
import { Dropdown, DropdownItem, Modal } from "staysure-component-library";

import FocusLock from "@/utils/FocusedLock";
import {
  findAnswerFamilyById,
  numberOfParents,
} from "@/utils/party-type.utils";

import classes from "./family-type-modal.module.css";
import { TextButton } from "../../cta-button/cta-buttons";
import styles from "../question.module.css";

type FamilyTypeModalProps = {
  isOpen: boolean;
  selectedAnswer: number | null;
  modalConfirmBtnClick?: (
    buttonClick: boolean,
    selectedAnswer: number | null
  ) => void;
  familyModalTitleText?: string;
  familyModalSubTextEnable?: boolean;
  familyModalSubText?: string;
  dropDownPlaceHolder?: string;
  confirmButtonText?: string;
  backButtonText?: string;
};

function FamilyTypeModal({
  isOpen,
  selectedAnswer,
  familyModalTitleText,
  familyModalSubTextEnable,
  familyModalSubText,
  dropDownPlaceHolder,
  confirmButtonText,
  backButtonText,
  modalConfirmBtnClick,
}: Readonly<FamilyTypeModalProps>) {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedNumberOfParents, setSelectedNumberOfParents] = useState<
    number | null
  >(null);

  useEffect(() => {
    setIsModalOpen(isOpen);
  }, [isOpen]);
  const defaultValue = findAnswerFamilyById(selectedAnswer || 0)?.label;

  useEffect(() => {
    // reset internal selectedNumberOfParents state when selectedAnswer prop changed
    if (!selectedAnswer) setSelectedNumberOfParents(null);
  }, [selectedAnswer]);

  return (
    <FocusLock disabled={!isModalOpen}>
      <Modal
        className="date-picker-modal md:p-spacing-xxxl  p-spacing-l md:max-w-[416px] 
        max-w-[327px] w-full"
        isOpen={isModalOpen}
        onOpenChange={(open: boolean) => {
          if (!open) {
            setIsModalOpen(false);
            // if modal is closed by outside click without confirmation, reset previous state
            if (modalConfirmBtnClick) {
              modalConfirmBtnClick(false, selectedAnswer);
            }
          }
        }}
        underlayStyles={{
          zIndex: 9999,
          background: "rgba(26, 26, 26, 0.6)",
        }}
        modalStyles={{
          display: "flex",
          background: "white",
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          borderRadius: 8,
        }}
      >
        <div data-testid="family-type-modal">
          <h1
            className="text-text-title font-header-font md:text-3xl text-[23px] 
          md:leading-[34.1px] leading-[27.6px] md:mb-spacing-s mb-spacing-xs"
          >
            {familyModalTitleText}
          </h1>
          {familyModalSubTextEnable && (
            <div className={` ${classes.text_content}`}>
              {familyModalSubText}
            </div>
          )}
          <div
            className={` ${styles.country_wrapper} md:w-[304px] w-[279px] mx-auto md:h-[56px] mt-spacing-l md:mb-spacing-l mb-spacing-l`}
          >
            <Dropdown
              id="family-type-modal-dropdown"
              className={`${styles.country_select} ${classes.dropdown_btn} py-spacing-m py px-spacing-m 
          flex space-between w-full`}
              defaultValue={defaultValue}
              strokeColor="#272427"
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              // onFocusChange={(e: any) => setAccesibilityClassName(e)}
              placeholderText={dropDownPlaceHolder}
              placeholderTextStyle={{
                color: "#272427",
              }}
              listBoxStyle={{
                outline: "none",
                margin: "0px 0px 0px 0px",
                minWidth: "unset !important",
                boxShadow: "0px 0px 12px 0px rgba(15, 23, 42, 0.11)",
              }}
              onSelectionChange={(e: Key) => {
                if (e) {
                  const selectedNumber = parseInt(e.toString(), 10);
                  setSelectedNumberOfParents(selectedNumber);
                }
              }}
              unorderedListClassName={`md:w-[304px] w-[279px] !md:ml-0 !ml-[-1px] !mt-[-4px] !border-border-subdued !rounded-radius-lg 
               !px-spacing-m !py-spacing-xs`}
            >
              {numberOfParents.map((parent) => (
                <DropdownItem value={parent.label} key={parent.label}>
                  <span
                    className={`w-full leading-[27px] Proxima Nova text-[18px] font-bold p-spacing-xs 
                    hover:bg-interactive-action-hovered country-name focus:bg-interactive-action-hovered text-text-on-primary`}
                  >
                    {parent.label}
                  </span>
                </DropdownItem>
              ))}
            </Dropdown>
          </div>
          <div className="flex md:flex-row flex-col">
            <TextButton
              id="family-type-modal-continue-button"
              isDisabled={!selectedNumberOfParents}
              hierarchy="primary"
              label={confirmButtonText || "Continue"}
              onClick={() => {
                setIsModalOpen(false);
                if (modalConfirmBtnClick) {
                  modalConfirmBtnClick(true, selectedNumberOfParents);
                }
              }}
              className="md:mr-2 md:mb-0 mb-2 !h-[48px] md:w-[148px] w-full leading-[18px] text-text-on-primary"
            />
            <TextButton
              id="family-type-modal-back-button"
              hierarchy="secondary"
              label={backButtonText || "Back"}
              onClick={() => {
                setSelectedNumberOfParents(null);
                setIsModalOpen(false);
                if (modalConfirmBtnClick) {
                  modalConfirmBtnClick(false, selectedNumberOfParents);
                }
              }}
              className=" !h-[48px] md:w-[148px] w-full leading-[18px]"
            />
          </div>
        </div>
      </Modal>
    </FocusLock>
  );
}

export default FamilyTypeModal;
