"use client";

import { useRouter } from "next/navigation";

import ButtonGroup from "@/components/atoms/button-group/button-group";
import LabelButtonWrapper from "@/components/atoms/button-wrapper/button-wrapper";
import RichText from "@/components/atoms/rich-text/rich-text";
import ConfirmationsModal from "@/components/molecules/quote-section/edit-quote-section/confirmation-modal";
import { QuestionOne, Quote, QuoteEdit } from "@/config/app-constant";
import { CoverTypePageMagnoliaContent } from "@/types/CoverType";

import editQuoteStyle from "../../../quote-section/edit-quote-section/edit-quote.module.css";
import Styles from "../cover-type.module.css";

type ReusedMagnoliaProps = Pick<
  CoverTypePageMagnoliaContent,
  "cancelEditingBtnText" | "questionText" | "coverTypeSupportText"
>;

interface QuestionOneSectionProps extends ReusedMagnoliaProps {
  editPage: string | null;
  modalOpen: boolean;
  selected: string | number | boolean | undefined;
  quoteLoading?: boolean;
  setModalOpen: (open: boolean) => void;
  handleCancelEdit: () => void;
  accessSelectedAnswer: (answerNo: number) => void;
  resetQuestionData: () => void;
  answerArray: { label: string | undefined; id: number; value: string }[];
  isClient: boolean;
}

function QuestionOneSection({
  editPage,
  cancelEditingBtnText = "Cancel Editing",
  questionText,
  coverTypeSupportText = "",
  modalOpen,
  selected,
  // quoteLoading,
  setModalOpen,
  handleCancelEdit,
  accessSelectedAnswer,
  resetQuestionData,
  answerArray,
  isClient,
}: Readonly<QuestionOneSectionProps>) {
  const router = useRouter();

  return (
    <div>
      {" "}
      {editPage && (
        <div>
          <LabelButtonWrapper
            hierarchy="tertiary"
            label={cancelEditingBtnText}
            className={editQuoteStyle.cancelEditButton}
            id="cancel-edit-cover-type"
            onClick={handleCancelEdit}
          />
          <ConfirmationsModal
            isOpen={modalOpen}
            handleConfirmBtnClick={() => {
              router.push(`/${Quote}/?${QuoteEdit}=true`);
              resetQuestionData();
            }}
            handleCancelmBtnClick={() => setModalOpen(false)}
          />
        </div>
      )}
      {isClient && (
        <h1
          data-testid="header-test-id"
          className=" font-header-font lg:text-[39px] md:text-3xl text-[26px] lg:leading-[44.85px]
              md:leading-[34.1px] leading-[29.9px] text-text-title md:text-left text-center"
        >
          {questionText}
        </h1>
      )}
      {isClient && (
        <ButtonGroup
          setSelectedAnswer={accessSelectedAnswer}
          selectedAnswer={selected as number}
          answerArray={answerArray}
          location={QuestionOne}
          type="cover-type"
          // isLoading={
          //   quoteLoading ||
          //   (isClient &&
          //     (sessionStorage.getItem("pageReload") as unknown as boolean))
          // }
        />
      )}
      <RichText
        richText={coverTypeSupportText}
        className={`${Styles.coverTypeRichText}`}
      />
    </div>
  );
}

export default QuestionOneSection;
