import { useState, useCallback } from "react";

type FocusState = {
  [key: string]: boolean;
};

function useInputFocus(initialState: FocusState = {}) {
  const [focusState, setFocusState] = useState<FocusState>(initialState);

  const handleBlur = useCallback((inputName: string) => {
    setFocusState((prevState) => ({
      ...prevState,
      [inputName]: false,
    }));
  }, []);

  const handleFocus = useCallback((inputName: string) => {
    setFocusState((prevState) => ({
      ...prevState,
      [inputName]: true,
    }));
  }, []);

  const getWarningStatus = useCallback(
    (inputName: string) => {
      return focusState[inputName] ? "warning" : null;
    },
    [focusState]
  );

  const setAllFocused = useCallback(() => {
    const allTrueState = Object.keys(focusState).reduce((acc, key) => {
      acc[key] = true;
      return acc;
    }, {} as FocusState);

    setFocusState(allTrueState);
  }, [focusState]);

  const setFocusedInputs = useCallback((inputNames: string[]) => {
    setFocusState((prevState) => {
      const updatedState = { ...prevState };
      inputNames.forEach((name) => {
        updatedState[name] = true;
      });
      return updatedState;
    });
  }, []);

  return {
    focusState,
    handleBlur,
    handleFocus,
    getWarningStatus,
    setAllFocused,
    setFocusedInputs,
  };
}

export default useInputFocus;
