"use client";

import { useChain, useSpringRef } from "@react-spring/web";
import dynamic from "next/dynamic";
import { usePathname, useSearchParams } from "next/navigation";
import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import setCookieForHeader from "@/hooks/api/cookie/getCookie";
import { GetQuoteFilter } from "@/hooks/api/questions/getQuote";
import useGtmPageView from "@/hooks/gtm/useGtmPageView";
import useCustomPageTracking from "@/hooks/page-tracking/use-custom-page-tracking";
import useUpdateProgressBar from "@/hooks/progress/use-update-progress-bar";
import useMediaQuery from "@/hooks/useMediaQuery/useMediaQueryEnhanced";
import QuestionsStateProvider from "@/providers/questions-state-provider";
import { getQuoteData } from "@/store/slices/question/question.action";
import { RootState } from "@/store/store";
import { storageAvailable } from "@/utils/common.utils";
import pages from "@/utils/page-routing.utils";

import withQuestionQuoteData from "../with-quote-question-modified-data";

const Footer = dynamic(
  () => import("@/components/organisms/footer/footer-section"),
  {
    ssr: false,
  }
);

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  helpButton: ReactElement;
  logo: ReactElement;
  pageName: string;
  formContentWithProps: ReactElement;
  supportSectionWithProp: ReactElement;
}

function PageLayoutWrapper({
  helpButton,
  logo,
  pageName,
  formContentWithProps,
  supportSectionWithProp,
}: Readonly<Props>) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [footer, setFooter] = useState<boolean>(false);
  const [isdesktop] = useMediaQuery("(min-width: 600px)");
  const [isdesktopTab] = useMediaQuery("(min-width: 904px)");
  const { loading } = useSelector((state: RootState) => state.question);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dispatch = useDispatch<any>();
  const searchParams = useSearchParams();
  const quoteRef = searchParams.get("quoteId");
  const affiliate = searchParams.get("affiliate");

  const percent = useMemo(() => {
    return pages.find((page) => page.name === pageName)?.progress ?? -1;
  }, [pageName]);

  const formContentSpringRef = useSpringRef();
  const formCtaSpringRef = useSpringRef();
  const supportContentSpringRef = useSpringRef();
  const pathname = usePathname();

  useChain(
    [formContentSpringRef, formCtaSpringRef, supportContentSpringRef],
    [0, 0.5, 1]
  );

  const formContentUpdatedProp = React.cloneElement(formContentWithProps, {
    showondesktop: isdesktop.toString(),
    showontab: isdesktopTab.toString(),
    formRef: formContentSpringRef,
    ctaRef: formCtaSpringRef,
  });

  const supportContentWithProps = React.cloneElement(supportSectionWithProp, {
    showondesktop: isdesktop.toString(),
    showontab: isdesktopTab.toString(),
    loading,
    supportRef: supportContentSpringRef,
  });

  useUpdateProgressBar(percent);

  const { pageReferrer, pageUrl, pageTitle } = useCustomPageTracking(pageName);

  useGtmPageView({
    userId: "",
    quoteId: "",
    pageReferrer,
    pageUrl,
    pageTitle,
  });

  useEffect(() => {
    if (typeof window !== "undefined" && window) {
      if (storageAvailable() && !sessionStorage.getItem("footer")) {
        setFooter(true);
      }
    }
  }, []);

  let getQuoteFilter: GetQuoteFilter = {
    affiliate: null,
    quoteId: null,
  };

  if (!affiliate && !quoteRef) {
    getQuoteFilter.affiliate = "quoteInit";
  } else {
    getQuoteFilter = {
      affiliate,
      quoteId: quoteRef,
    };
  }

  const fetchQuoteDataComponent = async () => {
    if (getQuoteFilter.affiliate) {
      if (pathname === "/") {
        await setCookieForHeader();
      }
      if (
        (storageAvailable() && sessionStorage.getItem("pageReload")) ||
        !sessionStorage.getItem("initialLoad")
      ) {
        sessionStorage.setItem("initialLoad", "true");
        sessionStorage.removeItem("pageReload");
        await dispatch(getQuoteData(getQuoteFilter));
      }
    }
  };

  useEffect(() => {
    fetchQuoteDataComponent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <QuestionsStateProvider>
      <div
        data-testid="questionPage-test-id"
        className="bg-surface-neutral-option-2"
      >
        <div className="max-w-[1200px] mx-auto my-0 w-[100%] relative min-h-[calc(100vh-12px)] lg:pb-0 pb-spacing-5xl ">
          <main className=" lg:py-spacing-xxl md:py-spacing-xl py-spacing-l">
            <div
              className=" grid md:grid-cols-12 grid-cols-6 md:gap-spacing-l gap-spacing-m xl:mx-auto lg:mx-[72px] 
      md:mx-[48px] mx-[24px] relative"
            >
              <div className="lg:col-span-7 col-span-12 bg-surface-neutral-option-1 rounded-radius-xxl">
                <div className=" flex flex-col h-full">
                  <div
                    className={` text-surface-neutral-option-2 rounded-radius-xxl relative`}
                  >
                    {helpButton}
                    {logo}
                  </div>

                  {formContentUpdatedProp}
                </div>
              </div>

              <div className=" lg:col-span-5 col-span-12">
                {supportContentWithProps}
              </div>
            </div>
          </main>
          {footer && (
            <footer>
              <Footer />
            </footer>
          )}
        </div>
      </div>
    </QuestionsStateProvider>
  );
}

export default withQuestionQuoteData(PageLayoutWrapper);
