/* eslint-disable @typescript-eslint/no-explicit-any */
const hideMessenger = () => {
  if (typeof window !== "undefined" && (window as any).zE) {
    const { zE } = window as any;
    zE("messenger", "hide");
    zE("messenger:on", "close", () => {
      zE("messenger", "hide");
    });
  }
};

const showMessenger = () => {
  if (typeof window !== "undefined" && (window as any).zE) {
    const { zE } = window as any;
    zE("messenger", "show");
    zE("messenger", "open");
  }
};

export { hideMessenger, showMessenger };
