"use client";

import { unwrapResult } from "@reduxjs/toolkit";
import { useRouter, useSearchParams } from "next/navigation";
import { useEffect, useState } from "react";

import FadeAnimation from "@/components/atoms/animation/fade-animation";
import TransformAnimation from "@/components/atoms/animation/transform-animation";
import ButtonGroup from "@/components/atoms/button-group/button-group";
import LabelButtonWrapper from "@/components/atoms/button-wrapper/button-wrapper";
import {
  CruiseEdit,
  QuestionFour,
  QuestionThree,
  QuoteEdit,
  Quote,
} from "@/config/app-constant";
import { Routes } from "@/config/page-view-constants";
import { PRODUCT_DOMAIN } from "@/constants";
import useGtmQuestionSet from "@/hooks/gtm/useGtmQuestionSet";
import { useQuestionsState } from "@/providers/questions-state-provider";
import { useAppDispatch } from "@/store/hook";
import {
  saveQuestionDataBFF,
  sessionCheck,
  updateQuoteOnQuestion,
} from "@/store/slices/question/question.action";
import { setQuestionData } from "@/store/slices/question/question.slice";
import { CruiseContainerProps } from "@/types/CruiseType";
import { QuestionPayload, TripDetails } from "@/types/QuestionPayloadType";
import selectLoationHookFlash, {
  modifyPayloadSave,
} from "@/utils/common.utils";
import {
  answersQuestionThree,
  findAnswerQThreeById,
} from "@/utils/cruise.utils";
import {
  getRouterPathBackButton,
  getRouterPathMainButton,
} from "@/utils/page-routing.utils";

import ActionButton from "../../action-button/action-button";
import ConfirmationsModal from "../../quote-section/edit-quote-section/confirmation-modal";
import editQuoteStyle from "../../quote-section/edit-quote-section/edit-quote.module.css";

const initialPayload: QuestionPayload = {
  type: "IntermediateData",
  channel: "ONLINE",
  attributes: {
    domain: PRODUCT_DOMAIN,
    lastVisitedPage: "test",
  },
};

function CruiseContainer({
  buttonDisable,
  usagePage,
  tripDetails,
  quoteResponse,
  pageContent,
  formRef,
  ctaRef,
}: Readonly<CruiseContainerProps>) {
  const {
    questionText = "Question Text",
    ctaButtonText = "Button Text",
    finishEditingBtnText = "Finish Editing",
    cancelEditingBtnText = "Cancel Editing",
  } = pageContent;
  const router = useRouter();
  const [modalOpen, setModalOpen] = useState(false);
  const searchParams = useSearchParams();
  const editPage = searchParams.get(CruiseEdit);
  const dispatch = useAppDispatch();

  const { selected, setSelectedState } = useQuestionsState();
  const [payload, modifyPayload] = useState<QuestionPayload>(initialPayload);

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [isSubmitBtnClicked, setSubmitBtnClicked] = useState<boolean>(false);

  const accessSelectedAnswer = (answerNo: number) => {
    if (answerNo) {
      if (selected === answerNo) {
        setSelectedState(0);
        return;
      }

      const tripDetailsModified: TripDetails = {
        ...tripDetails,
        goingOnCruise: findAnswerQThreeById(answerNo)?.value,
      };

      modifyPayload(modifyPayloadSave(quoteResponse, tripDetailsModified));
      setSelectedState(answerNo);
    }
  };

  useEffect(() => {
    if (selected && tripDetails) {
      const tripDetailsModified: TripDetails = {
        ...tripDetails,
        goingOnCruise: findAnswerQThreeById(selected as number)?.value,
      };
      modifyPayload(modifyPayloadSave(quoteResponse, tripDetailsModified));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, tripDetails]);

  const handleSubmitButton = async () => {
    if (!buttonDisable) {
      setSubmitBtnClicked(true);
      setButtonLoading(true);
      try {
        dispatch(setQuestionData(payload as QuestionPayload));

        if (editPage && quoteResponse?.referenceId) {
          const result = await dispatch(
            updateQuoteOnQuestion({ ...payload, viewPage: Routes.Cruise })
          );
          const originalPromiseResult = unwrapResult(result);
          if (originalPromiseResult) {
            router.push(`/${Quote}`);
          }
        } else if (!quoteResponse?.referenceId) {
          const questionData = await dispatch(
            saveQuestionDataBFF({ ...payload, viewPage: Routes.Cruise })
          );
          const originalPromiseResult = unwrapResult(questionData);
          if (originalPromiseResult) {
            router.push(getRouterPathMainButton(QuestionFour));
          }
        } else {
          dispatch(sessionCheck());
          router.push(getRouterPathMainButton(QuestionFour));
        }

        setButtonLoading(false);
      } catch (error) {
        setButtonLoading(false);
      }
    }
  };
  const toLocationData = selectLoationHookFlash(
    payload?.attributes?.tripDetails
  );
  useGtmQuestionSet({
    pageTitle: QuestionFour,
    tripType: payload?.attributes?.tripDetails?.policyType || undefined,
    pageUrl: typeof window !== "undefined" ? window.location.href : "",
    destination: toLocationData.destination,
    destinationAdditions: toLocationData.destinationAdditions,
    tripExtra: payload?.attributes?.tripDetails?.goingOnCruise
      ? QuestionFour
      : undefined,
    travelFrom: tripDetails?.fromLocation || undefined,
    isSubmitBtnClicked,
  });

  const handleBackButton = () => {
    router.push(getRouterPathBackButton(QuestionFour)); // const editFinishStyle = editPage ? editQuoteStyle.editFinishButton : "";
  };
  const handleCancelEdit = () => {
    setModalOpen(true);
  };
  const renderQuestionThreeSection = () => {
    return (
      <div className="" data-testid="renderQuestionThreeSection-test-id">
        {" "}
        {editPage && (
          <div>
            <LabelButtonWrapper
              hierarchy="tertiary"
              label={cancelEditingBtnText}
              id="cancel-edit-cruise"
              className={`${editQuoteStyle.cancelEditButton}`}
              onClick={handleCancelEdit}
            />
            <ConfirmationsModal
              isOpen={modalOpen}
              handleConfirmBtnClick={() => {
                router.push(`/${Quote}/?${QuoteEdit}=true`);
              }}
              handleCancelmBtnClick={() => {
                setModalOpen(false);
              }}
            />
          </div>
        )}
        <h1
          className=" font-header-font lg:text-[39px] md:text-3xl text-[26px] lg:leading-[44.85px] 
                md:leading-[34.1px] leading-[29.9px] text-text-title md:text-left text-center"
        >
          {questionText && questionText}
        </h1>
        <ButtonGroup
          setSelectedAnswer={accessSelectedAnswer}
          selectedAnswer={selected as number}
          answerArray={answersQuestionThree}
          location={QuestionThree}
          type="cruise"
        />
      </div>
    );
  };

  const editFinishStyle = editPage ? editQuoteStyle.editFinishButton : "";

  return (
    <div
      data-testid="question-three-test-id"
      className={`rounded-radius-xxl 
      bg-surface-neutral-option-1 rounded-b-radius-xxl  
           md:py-spacing-xxl py-spacing-m px-spacing-m h-full`}
    >
      <div className="lg:w-[414px] md:w-[400px] w-full mx-auto relative h-full flex flex-col justify-between">
        <TransformAnimation animationRef={formRef} currentPage={usagePage}>
          {renderQuestionThreeSection()}
        </TransformAnimation>

        <FadeAnimation animationRef={ctaRef}>
          <div className={`${editFinishStyle}`}>
            <ActionButton
              page={usagePage}
              ctaButtonText={editPage ? finishEditingBtnText : ctaButtonText}
              disableMain={buttonDisable}
              isLoading={buttonLoading}
              showBackBtn={!editPage}
              submitButtonClick={handleSubmitButton}
              backButtonClick={handleBackButton}
            />
          </div>
        </FadeAnimation>
      </div>
    </div>
  );
}
export default CruiseContainer;
