import { useEffect } from "react";

export interface ExtendedWindow extends Window {
  dataLayer?: {
    event?: string;
    event_name?: string;
    trip_type?: string;
    destination?: string | undefined;
    destination_additions?: string | undefined;
    trip_extra?: string | undefined;
    cover_start_date?: string | undefined;
    cover_end_date?: string | undefined;
    travel_from?: string | undefined;
    travel_group_type?: string | undefined;
    page_url?: string;
    page_title?: string;
    step_name?: string;
    popup_name?: string;
  }[];
}

type QuestionSetProps = {
  pageTitle?: string;
  tripType?: string;
  pageUrl?: string;
  destination?: string | undefined;
  destinationAdditions?: string | undefined;
  tripExtra?: string | undefined;
  coverStartDate?: string | undefined;
  coverEndDate?: string | undefined;
  travelFrom?: string | undefined;
  travelGroupType?: string | undefined;
  isSubmitBtnClicked?: boolean;
};

const useGtmQuestionSet = (props: QuestionSetProps) => {
  const {
    pageTitle,
    tripType,
    pageUrl,
    destination,
    destinationAdditions,
    tripExtra,
    coverEndDate,
    coverStartDate,
    travelFrom,
    travelGroupType,
    isSubmitBtnClicked,
  } = props;

  useEffect(() => {
    const triggerQuestionSet = () => {
      const extendedWindow = window as ExtendedWindow;
      extendedWindow.dataLayer = extendedWindow.dataLayer ?? [];
      extendedWindow.dataLayer.push({
        event: "quote_journey",
        event_name: "quote_trip_details",
        trip_type: tripType,
        destination,
        destination_additions: destinationAdditions,
        trip_extra: tripExtra,
        cover_start_date: coverStartDate,
        cover_end_date: coverEndDate,
        travel_from: travelFrom,
        travel_group_type: travelGroupType,
        page_url: pageUrl,
        page_title: pageTitle,
        step_name: pageTitle,
      });
    };
    if (isSubmitBtnClicked === true) triggerQuestionSet();
    return () => {
      // Cleanup code if needed
    };
  }, [
    pageTitle,
    tripType,
    pageUrl,
    destination,
    destinationAdditions,
    tripExtra,
    coverEndDate,
    coverStartDate,
    travelFrom,
    travelGroupType,
    isSubmitBtnClicked,
  ]);
};

export default useGtmQuestionSet;
