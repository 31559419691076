import { TrustpilotAPIKey } from "@/config/app-constant";
import axiosCMSInstance from "@/libs/axios-cms";

type AverageRatingJSONResponse = {
  score: {
    stars: number;
    trustScore: number;
  };
  numberOfReviews: {
    total: number;
  };
  links: Array<{
    herf: string;
  }>;
};

const apiKey = TrustpilotAPIKey;

export const fetchAverageRating = async (brandDomain: string) => {
  try {
    const response = await axiosCMSInstance.get(
      `https://api.trustpilot.com/v1/business-units/find?name=${brandDomain}`,
      {
        headers: {
          apiKey,
        },
      }
    );

    if (!response.data) {
      throw new Error(
        `Failed to retrieve Trustpilot average rating information: ${response.statusText}`
      );
    }

    const { score, numberOfReviews }: AverageRatingJSONResponse = response.data;
    const trustpilotData = {
      stars: score.stars,
      score: score.trustScore,
      totalReviews: numberOfReviews.total,
      link: {
        herf: `https://uk.trustpilot.com/review/www.${brandDomain}?utm_medium=trustbox&utm_source=Mini`,
      },
    };
    localStorage.setItem("trustpilot_data", JSON.stringify(trustpilotData));
    return trustpilotData;
  } catch (error) {
    const trustpilotResponse = localStorage.getItem("trustpilot_data");
    if (trustpilotResponse) {
      const trustpilotData = JSON.parse(trustpilotResponse);
      return trustpilotData;
    }
    return {
      stars: 4.8,
      score: 4.8,
      totalReviews: 40522,
      link: {
        herf: `https://uk.trustpilot.com/review/www.${brandDomain}?utm_medium=trustbox&utm_source=Mini`,
      },
    };
  }
};
