/* eslint-disable check-file/filename-naming-convention */
/* eslint-disable @typescript-eslint/no-use-before-define */
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  Modal,
  TextFieldSts,
  DateInput,
} from "staysure-component-library";

import ButtonGroup from "@/components/atoms/button-group/button-group";
import {
  AddButton,
  RemoveButton,
  TextButton,
} from "@/components/molecules/cta-button/cta-buttons";
import withBrand from "@/components/with-brand";
import {
  Family,
  Group,
  Individual,
  QuestionSeven,
  QuestionThree,
  SingleParentFamily,
} from "@/config/app-constant";
import useGtmPopup from "@/hooks/gtm/useGtmPopup";
import useMediaQuery from "@/hooks/useMediaQuery/use-media-query";
import { FormDataType, TravellerFormProps } from "@/types/TravellerType";
import {
  answersQuestionThree,
  findAnswerQThreeById,
  findAnswerQThreeByValue,
} from "@/utils/cruise.utils";
import {
  getStrokeColor,
  showNationalityFieldInExpat,
} from "@/utils/organiser.utils";
import { isValidDate } from "@/utils/travel-date.utils";
import {
  getDateValidation,
  getDateValidationStates,
  getFontClasses,
  getGroupValidate,
  getTravellerRole,
  isFamilyValidate,
  isFormDataComplete,
  isSingleFamilyValidate,
  titleData,
  validateDate,
  validateDateGroup,
  validateName,
} from "@/utils/traveller.utils";
// eslint-disable-next-line import/no-extraneous-dependencies

import Styles from "./traveller-form.module.css";
import NationalityStyles from "../../organizer/organiser-answer/organiser-form.module.css";
import TravellerMedicalAnswer from "../traveller-medical-answer/traveller-medical-answer";
import { useFetchNationalityData } from "@/hooks/api/addressApi/addressApi";

const formDataInitialValue = {
  id: 1,
  value1: "",
  value2: "",
  value3: "",
  value4: "",
  nationalityValue: undefined,
  dateValue: undefined,
  isDateCalenter: true,
};

function QSixForm({
  inputOnePlaceholder,
  inputTwoPlaceholder,
  addButtonLabel,
  removeButtonLabel,
  formNo,
  multipleForm,
  handleSubmitButton,
  inputOneErrorNumericvalues,
  inputOneErrorSpecialCharacters,
  inputOneErrorMaximumNumber,
  inputTwoErrorNumericvalues,
  inputTwoErrorSpecialCharacters,
  inputTwoErrorMaximumNumber,
  dobMinAge,
  dobMaxAge,
  popupMessage,
  travellerType,
  coupleMinAge,
  personalStatusPlaceholder,
  nationalityPlaceholder,
  ageParentFamily,
  ageFamilyFullTimeEducation,
  ageGroupUnderTwo,
  ageSingleParentFamily,
  ageSingleParentFullTimeEducation,
  travellerDetailsEdit,
  errorObjEdited,
  dobPlaceholder,
  organizer,
  brand,
  newMedicalFlowEnabled,
  hasMedicalConditions,
  medicalQuestionText,
  medicalQuestionInfoText,
  medicalServiceDeniedText,
  medicalYesBtnText,
  medicalNoBtnText,
  handleMedicalQuestion,
}: Readonly<TravellerFormProps>) {
  const [formData, setFormData] = useState<FormDataType[]>([
    formDataInitialValue,
  ]);
  const [firstNameErrors, setFirstNameErrors] = useState<string[]>([]);
  const [titleErrors, setTitleErrors] = useState<string[]>([]);
  const [lastNameErrors, setLastNameErrors] = useState<string[]>([]);
  const [ageErrors, setAgeErrors] = useState<string[]>([]);
  // const [ages] = useState<number[]>(new Array(formData.length).fill(0));
  const [activeHoverd, setActiveHoverd] = useState<boolean>(true);
  const [inputDisable, setInputDisable] = useState<boolean>(false);
  const [isTitleFocused, setIsTitleFocused] = useState<boolean>(false);
  const [focusedIndex, setFocusedIndex] = useState<number | null>(null);
  const [isTabPressed, setIsTabPressed] = useState(false);
  const isdesktop = useMediaQuery("(min-width: 600px)");
  const isLargeDesktop = useMediaQuery("(min-width: 1024px)");
  const inputRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [travellerAdded, setTravellerAdded] = useState(false);
  const [isNationalityFocused, setIsNationalityFocused] =
    useState<boolean>(false);

  // Fetch nationality list data
  const nationalityData = useFetchNationalityData(showNationalityFieldInExpat);

  const populateNationality = showNationalityFieldInExpat
    ? nationalityData.length > 0
    : true;

  useEffect(() => {
    if (formNo && formNo > 0) {
      const formDataEdited = Array.from({ length: formNo }, (_, index) => ({
        ...formDataInitialValue,
        id: index + 1, // Assuming IDs start from 1
      }));
      setAgeErrors([]);
      setFormData(formDataEdited);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formNo]);

  useEffect(() => {
    if (travellerDetailsEdit && travellerDetailsEdit.length > 0) {
      setFormData(travellerDetailsEdit);

      const isComplete = isFormDataComplete(
        travellerDetailsEdit,
        travellerType || Individual,
        organizer?.travelling || false
      );
      if (isComplete) {
        handleSubmitButton(travellerDetailsEdit);
      } else {
        handleSubmitButton([]);
      }

      // validateFrom(travellerDetailsEdited);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [travellerDetailsEdit]);

  // const setDobValue = (index: number, age: number) => {
  //   setAges((prevAges) => {
  //     const newAges = [...prevAges];
  //     newAges[index] = age;
  //     return newAges;
  //   });
  // };

  // useEffect(() => {
  //   const newAgeErrors = [...ageErrors];
  //   ages.forEach((age, index) => {
  //     if (age === 0) {
  //       newAgeErrors[index] = "";
  //     } else if (age > 120) {
  //       newAgeErrors[index] = dobMaxAge || "";
  //     }
  //   });

  //   setAgeErrors(newAgeErrors);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [ages]);

  useEffect(() => {
    if (errorObjEdited) {
      const {
        ageErrors: ageEditdErrors,
        firstNameErrors: firstNameEditedErrors,
        lastNameErrors: lastNameEditedErrors,
      } = errorObjEdited;
      setAgeErrors(ageEditdErrors);
      setFirstNameErrors(firstNameEditedErrors);
      setLastNameErrors(lastNameEditedErrors);
      // validateFrom(travellerDetailsEdited);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorObjEdited]);

  useEffect(() => {
    function handleKeyDown(event: { key: string }) {
      if (event.key === "Tab") {
        setIsTabPressed(true);
      }
    }

    function handleKeyUp(event: { key: string }) {
      if (event.key === "Tab") {
        setIsTabPressed(false);
      }
    }

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  const [formCount, setFormCount] = useState<number>(1);
  const [removeIndex, setRemoveIndex] = useState<number>(formCount);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isAddTravelerBtnClick, setIsAddTravelerBtnClick] =
    useState<boolean>(false);

  useEffect(() => {
    if (travellerAdded && formData.length > 0 && !isLargeDesktop) {
      const lastElement = inputRefs.current[formData.length - 1];
      if (lastElement !== null) {
        lastElement.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
        setTravellerAdded(false);
      }
    }
  }, [formData.length, travellerAdded, isLargeDesktop]);

  const handleInputChange = (
    index: number,
    fieldName: string,
    value: string | boolean
  ) => {
    const newFormData = [...formData];
    const updatedForm = { ...newFormData[index], [fieldName]: value };
    newFormData[index] = updatedForm;
    if (fieldName === "dateValue" || newFormData[index].dateValue) {
      if (isValidDate(newFormData[index].dateValue as Date)) {
        const birthDate = dayjs(newFormData[index].dateValue);
        const currentDate = dayjs();
        const age = currentDate.diff(birthDate, "year");
        // Check if the birth date is in the future
        const isFutureDate = birthDate.isAfter(currentDate);
        let ageValue = 0;
        if (isFutureDate) {
          if (age === 0) {
            ageValue = -0.5;
          } else if (age < 0) {
            ageValue = age;
          }
        } else {
          ageValue = age;
        }
        newFormData[index] = {
          ...newFormData[index],
          ...{ value4: ageValue.toString() },
        };

        if (travellerType === Family) {
          if (
            !ageValue ||
            (ageValue && Number(ageValue) < 17) ||
            Number(ageValue) > 21
          ) {
            newFormData[index] = {
              ...newFormData[index],
              ...{ fullTimeEducation: undefined },
            };
          }
        } else if (travellerType === SingleParentFamily) {
          if (
            !ageValue ||
            (ageValue && Number(ageValue) < 17) ||
            Number(ageValue) > 21
          ) {
            newFormData[index] = {
              ...newFormData[index],
              ...{ fullTimeEducation: undefined },
            };
          }
        }
      } else {
        newFormData[index] = {
          ...newFormData[index],
          ...{ value4: "" },
        };
      }
    }
    if (fieldName === "value2") {
      validateFirstName(updatedForm, index);
    } else if (fieldName === "value3") {
      validateLastName(updatedForm, index);
    } else if (fieldName === "dateValue") {
      if (travellerType === Group) {
        validateGroupData(newFormData, updatedForm);
      } else {
        validateAge(updatedForm, index);
      }
    } else if (fieldName === "value4") {
      if (travellerType === Family) {
        if (!value || (value && Number(value) < 17) || Number(value) > 21) {
          newFormData[index] = {
            ...newFormData[index],
            ...{ fullTimeEducation: undefined },
          };
        }
        validateFamilyData(updatedForm, index);
      } else if (travellerType === SingleParentFamily) {
        if (!value || (value && Number(value) < 17) || Number(value) > 21) {
          newFormData[index] = {
            ...newFormData[index],
            ...{ fullTimeEducation: undefined },
          };
        }
        validateSingleFamilyData(updatedForm, index);
      } else if (travellerType === Group) {
        validateGroupData(newFormData, updatedForm);
      } else {
        validateAge(updatedForm, index);
      }
    } else if (fieldName === "fullTimeEducation") {
      if (travellerType === Family) {
        validateFamilyData(updatedForm, index);
      } else if (travellerType === SingleParentFamily) {
        validateSingleFamilyData(updatedForm, index);
      }
    }

    newFormData[index] = {
      ...newFormData[index],
      ...{ [fieldName]: value },
    };

    if (fieldName === "nationalityValue") {
      const selectedNationality = nationalityData.find(
        (n) => n.name === value
      )?.id;
      newFormData[index].nationalityValue = selectedNationality || undefined;
    }

    setFormData(newFormData);
    const isComplete = isFormDataComplete(
      newFormData,
      travellerType || Individual,
      organizer?.travelling || false
    );
    if (isComplete) {
      handleSubmitButton(newFormData);
    } else {
      handleSubmitButton([]);
    }
  };

  useGtmPopup({
    tripType: QuestionSeven,
    isPopupOpen: isModalOpen,
    popupName: popupMessage || "Remove traveller",
  });

  const validateFirstName = (form: FormDataType, index: number) => {
    const errors: string[] = validateName(
      form.value2,
      inputOneErrorNumericvalues || "",
      inputOneErrorSpecialCharacters || "",
      inputOneErrorMaximumNumber || ""
    );

    const newFirstNameErrors = [...firstNameErrors];
    newFirstNameErrors[index] = errors.join(". ");
    setFirstNameErrors(newFirstNameErrors);

    if (!form.value1) {
      const newTitleErrors = [...titleErrors];
      newTitleErrors[index] = "title Error";

      setTitleErrors(newTitleErrors);
    }
  };
  const handleFullTimeEducation = (index: number, answer: number) => {
    if (answer) {
      handleInputChange(
        index,
        "fullTimeEducation",
        findAnswerQThreeById(answer)?.value || false
      );
    }
  };

  const validateLastName = (form: FormDataType, index: number) => {
    const errors: string[] = validateName(
      form.value3,
      inputTwoErrorNumericvalues || "",
      inputTwoErrorSpecialCharacters || "",
      inputTwoErrorMaximumNumber || ""
    );

    const newLastNameErrors = [...lastNameErrors];
    newLastNameErrors[index] = errors.join(". ");
    setLastNameErrors(newLastNameErrors);
  };

  const validateAge = (form: FormDataType, index: number) => {
    const errors: string[] = validateDate(
      form,
      travellerType || "",
      dobMinAge || "",
      coupleMinAge || "",
      dobMaxAge || ""
    );
    const newAgeErrors = [...ageErrors];
    newAgeErrors[index] = errors.join(". ");
    setAgeErrors(newAgeErrors);
  };
  const validateFamilyData = (form: FormDataType, index: number) => {
    const errors: string[] = isFamilyValidate(
      index,
      form,
      organizer?.travelling || false,
      ageParentFamily,
      ageFamilyFullTimeEducation
    );
    const errors2: string[] = validateDate(
      form,
      travellerType || "",
      dobMinAge || "",
      coupleMinAge || "",
      dobMaxAge || ""
    );

    const newAgeErrors = [...ageErrors];
    if (errors2 && errors2.length > 0) {
      newAgeErrors[index] = errors2.join(". ");
    } else if (errors && errors.length > 0) {
      newAgeErrors[index] = errors.join(". ");
    } else {
      newAgeErrors[index] = "";
    }

    setAgeErrors(newAgeErrors);
  };

  const validateSingleFamilyData = (form: FormDataType, index: number) => {
    const errors: string[] = isSingleFamilyValidate(
      index,
      form,
      organizer?.travelling || false,
      ageSingleParentFamily,
      ageSingleParentFullTimeEducation
    );
    const errors2: string[] = validateDate(
      form,
      travellerType || "",
      dobMinAge || "",
      coupleMinAge || "",
      dobMaxAge || ""
    );

    const newAgeErrors = [...ageErrors];
    if (errors2 && errors2.length > 0) {
      newAgeErrors[index] = errors2.join(". ");
    } else if (errors && errors.length > 0) {
      newAgeErrors[index] = errors.join(". ");
    } else {
      newAgeErrors[index] = "";
    }
    setAgeErrors(newAgeErrors);
  };

  const validateGroupData = (
    newFormData: FormDataType[],
    form: FormDataType
  ) => {
    const errors: string[] = getGroupValidate(
      newFormData,
      form,
      ageGroupUnderTwo || ""
    );

    const errors2: string[] = validateDateGroup(
      form,
      newFormData,
      dobMinAge || "",
      dobMaxAge || ""
    );
    const newAgeErrors = [...ageErrors];

    if (errors2 && errors2.length > 0) {
      // eslint-disable-next-line array-callback-return
      errors2.map((error, indexCheck) => {
        if (error) {
          newAgeErrors[indexCheck] = error;
        } else {
          newAgeErrors[indexCheck] = "";
        }
      });
      setAgeErrors(newAgeErrors);
    } else if (errors && errors.length > 0 && !organizer?.travelling) {
      // eslint-disable-next-line array-callback-return
      errors.map((error, indexCheck) => {
        if (error) {
          newAgeErrors[indexCheck] = error;
        } else {
          newAgeErrors[indexCheck] = "";
        }
      });
      setAgeErrors(newAgeErrors);
    }

    if (errors2.length === 0 && errors.length === 0) {
      setAgeErrors([]);
    }
  };
  const handleAddForm = () => {
    setIsAddTravelerBtnClick(true);
    setTimeout(() => {
      setIsAddTravelerBtnClick(false);
    }, 500);
    setFormCount(formCount + 1);
    setFormData([
      ...formData,
      { ...formDataInitialValue, ...{ id: formData.length + 1 } },
    ]);
    setFirstNameErrors([...firstNameErrors, ""]);
    setLastNameErrors([...lastNameErrors, ""]);
    setTravellerAdded(true);
  };

  const handleRemoveForm = () => {
    const newFormData = [...formData];
    newFormData.splice(removeIndex, 1);
    const newFirstNameErrors = [...firstNameErrors];
    newFirstNameErrors.splice(removeIndex, 1);
    const newLastNameErrors = [...lastNameErrors];
    newLastNameErrors.splice(removeIndex, 1);
    setFormData(newFormData);
    setFirstNameErrors(newFirstNameErrors);
    setLastNameErrors(newLastNameErrors);
    setFormCount(formCount - 1);
    const isComplete = isFormDataComplete(
      newFormData,
      travellerType || Individual,
      organizer?.travelling || false
    );
    if (isComplete) {
      handleSubmitButton(newFormData);
    } else {
      handleSubmitButton([]);
    }
    setTravellerAdded(true);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // handleSubmitButton('newFormDatas');
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleAgeButtonPressed = (index: number) => {
    const newFormData = [...formData];
    newFormData[index].value4 = "";
    newFormData[index].dateValue = undefined;
    newFormData[index].isDateCalenter = !newFormData[index].isDateCalenter;
    setFormData(newFormData);
    const updatedErrors = [...ageErrors];
    updatedErrors[index] = "";
    setAgeErrors(updatedErrors);
    // setAgeErrors([]);
    handleSubmitButton([]);
  };

  const handleFocus = (index: number) => {
    setFocusedIndex(index);
    const focusableIndices = [0, 1, 2, 3, 4, 5, 6, 7, 8];
    if (focusedIndex === null || focusableIndices.includes(index)) {
      setIsTitleFocused(true);
    }
  };

  return (
    <div
      data-testid="traveller-form-id"
      className=" flex flex-col gap-x-2 mt-spacing-l"
    >
      {/* <h2
        data-testid="traveller-form-subheading-id"
        className=" font-body-font text-2xs leading-[24px] font-semibold 
      md:mb-spacing-s mb-spacing-xs text-text-body md:pl-0 pl-spacing-m"
      >
        {subText}
      </h2>  */}
      <form
        data-testid="traveller-form"
        onSubmit={handleSubmit}
        className={`${Styles.trvellers_form} md:bg-surface-neutral-option-1 bg-surface-neutral-option-2`}
      >
        <div className="field-block md:!pb-0">
          {populateNationality &&
            formData.map((form, index) => (
              <div
                key={form.id}
                id={`form-scrollableInput-${index + 1}`}
                ref={(el) => {
                  inputRefs.current[index] = el;
                }}
                className={`${Styles.form_wrapper} 
              ${index === 0 ? "border-b pt-0 pb-spacing-l" : "border-b pt-spacing-l pb-spacing-l "}             
              ${index === formData.length - 1 ? "border-b-0 py-0 mb-[-24px]" : ""}`}
              >
                <div
                  data-testid="traveller-form-family-label"
                  className={`${getFontClasses(brand, isdesktop)} text-text-title font-header-font text-[20px] mb-spacing-s`}
                >
                  {getTravellerRole(organizer, index, travellerType)}
                </div>
                <div
                  data-testid={`traveller-form-dropdown-${index}`}
                  className={` ${Styles.country_wrapper} ${isTitleFocused && focusedIndex === index && Styles.dropdown_focused} pointer-events-${isAddTravelerBtnClick === true ? "none" : "auto"} flex flex-row items-start max-w-[106px] w-full 
                  h-[56px] mb-spacing-s`}
                  onFocus={() => {
                    if (isTabPressed) {
                      handleFocus(index);
                    }
                  }}
                  onBlur={() => {
                    setIsTitleFocused(false);
                  }}
                >
                  <Dropdown
                    id="traveler-form-dropdown"
                    alertState={titleErrors[index] ? "error" : undefined}
                    defaultValue={form.value1}
                    placeholderText={personalStatusPlaceholder}
                    strokeColor={getStrokeColor(brand)}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    onSelectionChange={(e: any) => {
                      handleInputChange(index, "value1", e);
                      if (isdesktop) {
                        setActiveHoverd(false);
                        setInputDisable(true);
                        setTimeout(() => {
                          setInputDisable(false);
                        }, 1);
                        setTimeout(() => {
                          setActiveHoverd(true);
                        }, 1200);
                      } else {
                        setActiveHoverd(false);
                        setInputDisable(true);
                        setTimeout(() => {
                          setInputDisable(false);
                        }, 1);
                      }
                    }}
                    className={`${Styles.country_select} ${Styles.dropdown_btn} lg:pl-[24px] py-spacing-m px-spacing-m flex 
                  space-between max-w-[106px] w-full border border-interactive-secondary-default rounded-radius-lg 
                  max-h-spacing-xxxl`}
                    listBoxStyle={{
                      outline: "none",
                      margin: "0px 0px 0px 0px",
                      minWidth: "unset !important",
                      boxShadow: "0px 0px 12px 0px rgba(15, 23, 42, 0.11)",
                    }}
                    brand={brand}
                    listClassName={` ${Styles.listStyle} rounded-radius-s`}
                    unorderedListClassName="lg:w-[414px] md:w-[400px] w-[calc(100vw-80px)] !md:ml-0 !ml-[-1px] !mt-[-4px] !border-border-subdued !rounded-radius-lg 
               !px-spacing-m !py-spacing-xs"
                  >
                    {titleData &&
                      titleData.map((title) => (
                        <DropdownItem value={title.displayName} key={title.id}>
                          <span
                            className=" w-full leading-[24px] font-proximanova text-2xs font-semibold p-spacing-xs 
                     country-name "
                          >
                            {title.displayName}
                          </span>
                        </DropdownItem>
                      ))}
                  </Dropdown>
                </div>
                <div
                  className={`${Styles.input_wrapper} flex flex-row items-start mb-spacing-s`}
                  data-testid={`fist-name-wrapper-${index}`}
                >
                  <TextFieldSts
                    isDisabled={inputDisable}
                    activeHoverd={activeHoverd}
                    label={inputOnePlaceholder}
                    id={`traveller-first-name-${index + 1}`}
                    aria-label={`first-name-${index + 1}`}
                    width="100%"
                    name="value2"
                    maxLength={51}
                    state={firstNameErrors[index] ? "error" : undefined}
                    message={firstNameErrors[index]}
                    value={form.value2}
                    onChange={(value) =>
                      handleInputChange(index, "value2", value)
                    }
                    brand={brand}
                  />
                </div>
                <div
                  className={`${Styles.input_wrapper} flex flex-row items-start mb-spacing-s`}
                  data-testid={`lastname-wrapper-${index}`}
                >
                  <TextFieldSts
                    isDisabled={inputDisable}
                    activeHoverd={activeHoverd}
                    label={inputTwoPlaceholder}
                    id={`traveller-last-name-${form.id}-${index + 1}`}
                    aria-label={`last-name-${form.id}-${index + 1}`}
                    width="100%"
                    name="value3"
                    maxLength={51}
                    state={lastNameErrors[index] ? "error" : undefined}
                    message={lastNameErrors[index]}
                    value={form.value3}
                    onChange={(value) =>
                      handleInputChange(index, "value3", value)
                    }
                    brand={brand}
                  />
                </div>

                {/* NATIONALITY */}
                {showNationalityFieldInExpat && (
                  <div
                    className={` ${NationalityStyles.country_wrapper} ${NationalityStyles.title_dropdown} ${isNationalityFocused && NationalityStyles.dropdown_focused} flex flex-row items-start w-full h-[56px] mb-spacing-s`}
                    onFocus={() => {
                      if (isTabPressed) {
                        setIsNationalityFocused(true);
                      }
                    }}
                    onBlur={() => setIsNationalityFocused(false)}
                  >
                    <Dropdown
                      id="nationality-dropdown"
                      defaultValue={
                        nationalityData.find(
                          (n) => n.id === formData[index].nationalityValue
                        )?.name ?? undefined
                      }
                      placeholderTextAndLabel={
                        nationalityPlaceholder ?? "Nationality"
                      }
                      strokeColor={getStrokeColor(brand)}
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      onSelectionChange={(e: any) => {
                        handleInputChange(index, "nationalityValue", e);
                        if (isdesktop) {
                          setActiveHoverd(false);
                          setInputDisable(true);
                          setTimeout(() => {
                            setInputDisable(false);
                          }, 1);
                          setTimeout(() => {
                            setActiveHoverd(true);
                          }, 1200);
                        } else {
                          setActiveHoverd(false);
                          setInputDisable(true);
                          setTimeout(() => {
                            setInputDisable(false);
                          }, 1);
                        }
                      }}
                      className={`${NationalityStyles.droplistBox}  border-interactive-secondary-default rounded-radius-lg max-h-spacing-xxxl`}
                      placeholderLeftBeforeSelect={0}
                      placeholderLeftAfterSelect={-24}
                      listBoxStyle={{
                        outline: "none",
                        margin: "0px 0px 0px 0px",
                        minWidth: "unset !important",
                        maxHeight: "400px",
                        boxShadow: "0px 0px 12px 0px rgba(15, 23, 42, 0.11)",
                      }}
                      listClassName={`${NationalityStyles.listStyle} mt-[10px] rounded-radius-s`}
                      unorderedListClassName="w-full !mt-[3px] !md:ml-0 !ml-[-1px] !border-border-subdued !rounded-radius-lg !px-spacing-m !py-spacing-xs"
                    >
                      {nationalityData &&
                        nationalityData.map((nationality) => (
                          <DropdownItem
                            value={nationality.name}
                            key={nationality.id}
                          >
                            <span
                              className=" w-full leading-[24px] font-proximanova text-2xs font-semibold p-spacing-xs 
                     country-name "
                            >
                              {nationality.name}
                            </span>
                          </DropdownItem>
                        ))}
                    </Dropdown>
                  </div>
                )}

                {form.isDateCalenter ? (
                  <div
                    className={`${Styles.date_picker_wrapper} flex flex-col relative items-start`}
                  >
                    <DateInput
                      isDOBScrollable={!isdesktop}
                      scrollableInputId={`scrollableInput-${index}`}
                      defaultDate={
                        form.dateValue && isValidDate(form.dateValue)
                          ? new Date(form.dateValue)
                          : undefined
                      }
                      hintLabel={dobPlaceholder}
                      customizedMessage={getDateValidation(
                        form,
                        index,
                        travellerType || Individual,
                        organizer?.travelling || false,
                        coupleMinAge || "",
                        dobMinAge || "",
                        dobMaxAge || "",
                        ageParentFamily || "",
                        ageFamilyFullTimeEducation || "",
                        ageGroupUnderTwo || "",
                        ageSingleParentFamily || "",
                        ageSingleParentFullTimeEducation || "",
                        formData
                      )}
                      isError={getDateValidationStates(
                        form,
                        index,
                        travellerType || Individual,
                        organizer?.travelling || false,
                        formData
                      )}
                      dobWrapperClass={`${Styles.dobWrapper}`}
                      dobErrorMsgClass={Styles.errorOfDob}
                      onChange={(date: Date | unknown) => {
                        if (date && isValidDate(date as Date)) {
                          handleInputChange(
                            index,
                            "dateValue",
                            date?.toString() || ""
                          );
                          // setInvalideDate("");
                        } else if (date === "Invalid Date") {
                          handleInputChange(
                            index,
                            "dateValue",
                            "Invalidate Date of Birth"
                          );
                          // setInvalideDate("Invalidate Date of Birth");
                        } else {
                          handleInputChange(index, "dateValue", "");
                        }

                        // if (isValidDate(date as Date)) {
                        //   const birthDate = dayjs(date as Date);
                        //   const currentDate = dayjs();
                        //   const age = currentDate.diff(birthDate, "year");
                        //   setDobValue(index, age);
                        //   handleInputChange(index, "value4", age.toString());
                        // }
                      }}
                      brand={brand}
                    />

                    <div
                      className="text-xs leading-[27px] font-semibold text-text-on-primary 
                  absolute left-[205px] top-[16px]"
                    >
                      Age: {Number(form.value4) === -0.5 ? 0 : form.value4}
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className=" flex flex-row items-center mt-2">
                      <TextFieldSts
                        activeHoverd={activeHoverd}
                        label="Age"
                        id={`traveller-age-${index}`}
                        width="78px"
                        name="value4"
                        pattern="[0-9]{1-3}"
                        state={ageErrors[index] ? "error" : undefined}
                        message={ageErrors[index]}
                        type="number"
                        inputMode="numeric"
                        value={form.value4}
                        // onChange={(value) => {
                        //   // const sanitizedValue = value.replace(/[^0-9]/g, "");
                        //   // handleInputChange(index, "value4", sanitizedValue);

                        // }}
                        onChange={(
                          value: string,
                          event?: React.FocusEvent<HTMLInputElement>
                        ) => {
                          if (event) {
                            const invalidCharacters = /[^0-9]/.test(value);
                            if (invalidCharacters) {
                              event.preventDefault();
                            } else {
                              const sanitizedValue = value.replace(
                                /[^0-9]/g,
                                ""
                              );
                              handleInputChange(
                                index,
                                "value4",
                                sanitizedValue
                              );
                            }
                          }
                        }}
                        brand={brand}
                        inputFieldClassName={`${Styles.dobManualEnterField}`}
                        onKeyDown={(e) => {
                          if (
                            e.key === "." ||
                            e.key === "," ||
                            e.key === "e" ||
                            e.key === "E" ||
                            ((e.key < "0" || e.key > "9") && e.key.length === 1)
                          ) {
                            e.preventDefault();
                          }
                        }}
                        onInput={(e) => {
                          const inputElement = e.target as HTMLInputElement;
                          if (inputElement.value.includes(".")) {
                            inputElement.value = inputElement.value.replace(
                              ".",
                              ""
                            );
                          }
                        }}
                      />
                    </div>
                  </div>
                )}

                {/* <div className=" mt-spacing-s">
                <InlineInfoBox
                  id="traveler-form-inline-info-box"
                  content={
                    <div>
                      <p>
                        {form.isDateCalenter
                          ? "Don’t know their date of birth?"
                          : ""}
                      </p>
                      <button
                        id="inline-info-box-button"
                        type="button"
                        onClick={() => {
                          handleAgeButtonPressed(index);
                        }}
                        className="text-interactive-secondary-default underline font-semibold"
                      >
                        {form.isDateCalenter
                          ? "Enter age instead"
                          : "Enter date of birth instead"}
                      </button>
                    </div>
                  }
                  style={{
                    width: "100%",
                    backgroundColor: "#EDF2FF",
                    borderColor: "#006BD6",
                    padding: "8px 16px 8px 16px",
                    borderRadius: "8px",
                    color: "#272427",
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}
                />
              </div> */}

                {(travellerType === Family ||
                  travellerType === SingleParentFamily) &&
                  Number(form.value4) > 17 &&
                  Number(form.value4) < 21 && (
                    <div className="md:mt-spacing-l mt-spacing-m text-left">
                      <h2 className=" font-header-font md:text-3xl text-[26px] md:leading-[34.1px] leading-[29.9px] text-text-title text-left md:mb-spacing-l mb-spacing-m">
                        Is this traveller in full-time education?
                      </h2>
                      <div className={`${Styles.traveller_btn_wrapper}`}>
                        <ButtonGroup
                          setSelectedAnswer={(answer: number) => {
                            handleFullTimeEducation(index, answer);
                          }}
                          selectedAnswer={
                            findAnswerQThreeByValue(form.fullTimeEducation)?.id
                          }
                          answerArray={answersQuestionThree}
                          location={QuestionThree}
                          type="traveller-from"
                        />
                      </div>
                    </div>
                  )}

                {multipleForm &&
                  travellerType === Family &&
                  ((index > 2 && !organizer?.travelling) ||
                    (index > 1 && organizer?.travelling)) && (
                    <div className=" remove-button text-xs leading-[18px] font-semibold">
                      <RemoveButton
                        hierarchy="tertiary"
                        id={`removeButton-${index}`}
                        label={removeButtonLabel}
                        onClick={() => {
                          setIsModalOpen(true);
                          setRemoveIndex(index);
                        }}
                      />
                    </div>
                  )}
                {multipleForm &&
                  (travellerType === Group ||
                    travellerType === SingleParentFamily) &&
                  ((index > 1 && !organizer?.travelling) ||
                    (index > 0 && organizer?.travelling)) && (
                    <div className="remove-button text-xs leading-[18px] font-semibold">
                      <RemoveButton
                        hierarchy="tertiary"
                        id={`removeButton-${index}`}
                        label={removeButtonLabel}
                        onClick={() => {
                          setIsModalOpen(true);
                          setRemoveIndex(index);
                        }}
                      />
                    </div>
                  )}
                <Modal
                  className="traveller-remove-modal md:p-spacing-xxxl p-spacing-l md:max-w-[416px] 
              max-w-[327px] w-full"
                  isOpen={isModalOpen}
                  onOpenChange={(e: boolean) => setIsModalOpen(e)}
                  underlayStyles={{
                    zIndex: 9999,
                    background: "rgba(26, 26, 26, 0.6)",
                  }}
                  modalStyles={{
                    display: "flex",
                    background: "white",
                    position: "fixed",
                    top: "30%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    borderRadius: 8,
                  }}
                >
                  <div>
                    <h1
                      className="text-text-title font-header-font md:text-3xl text-[23px] 
                md:leading-[34.1px] leading-[27px] mb-spacing-m"
                    >
                      {popupMessage}
                    </h1>
                    <div className="flex md:flex-row flex-col">
                      <TextButton
                        id={`traveler-remove-yes-button-${index}`}
                        hierarchy="primary"
                        label="Yes"
                        onClick={() => {
                          handleRemoveForm();
                          setIsModalOpen(false);
                        }}
                        className="md:mr-2 md:mb-0 mb-2 !h-[48px] md:w-[148px] w-full"
                      />
                      <TextButton
                        id={`traveler-remove-cancel-button-${index}`}
                        hierarchy="secondary"
                        label="Cancel"
                        onClick={() => setIsModalOpen(false)}
                        isLeftIcon={false}
                        className=" !h-[48px] md:w-[148px] w-full"
                      />
                    </div>
                  </div>
                </Modal>
              </div>
            ))}
          {!(
            multipleForm &&
            formData &&
            ((formData.length < 9 && !organizer?.travelling) ||
              (formData.length < 8 && organizer?.travelling))
          ) && (
            <div
              data-testid="paddinDiv"
              className="md:pb-spacing-l pb-spacing-xs"
            />
          )}
          {multipleForm &&
            formData &&
            ((formData.length < 9 && !organizer?.travelling) ||
              (formData.length < 8 && organizer?.travelling)) && (
              <div className=" add-more-btn-wrapper">
                <AddButton
                  hierarchy="secondary"
                  id={`loadmoreButton-automate-test-id-${formData.length}`}
                  label={addButtonLabel}
                  onClick={handleAddForm}
                  isRightIcon={false}
                  className=" bg-surface-neutral-option-1 text-xs leading-[18px] 
              font-semibold md:w-[247px] w-full focus:border-interactive-secondary-default"
                />
              </div>
            )}

          {newMedicalFlowEnabled && (
            <TravellerMedicalAnswer
              show
              questionText={medicalQuestionText}
              questionInfoText={medicalQuestionInfoText}
              serviceDeniedText={medicalServiceDeniedText}
              yesBtnText={medicalYesBtnText}
              noBtnText={medicalNoBtnText}
              location={QuestionThree}
              selectedValue={hasMedicalConditions}
              onChange={handleMedicalQuestion}
            />
          )}
        </div>
        {/* <button type="submit">Submit</button> */}
      </form>
    </div>
  );
}

export default withBrand(QSixForm);
