"use client";

import { useEffect, useState } from "react";
import { TrustpilotMini } from "staysure-component-library";

import RichText from "@/components/atoms/rich-text/rich-text";
import { PRODUCT } from "@/constants";
import { fetchAverageRating } from "@/hooks/api/trustpilot/trustpilot";

import Styles from "./cover-type-special-support-section.module.css";

type TrustpilotBlockProps = {
  brandDomain?: string;
  navigateUrl?: string;
  trustpilotDescription?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  backGroundImage?: any;
  showondesktop?: string;
  showontab?: string;
  trustpilotSupportCopyText?: string;
  rightContent?: object | null;
  isdesktop?: boolean;
};

export function CoverTypeSupportSection({
  trustpilotDescription = "Twice as many 5-star reviews on Trustpilot than any other travel insurance provider",
  backGroundImage,
  brandDomain,
  navigateUrl,
  trustpilotSupportCopyText,
}: Readonly<TrustpilotBlockProps>) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [reviewData, setReviewData] = useState<any>({
    stars: 0,
    score: 0,
    totalReviews: 0,
    link: {
      herf: "",
    },
  });

  useEffect(() => {
    Promise.resolve(fetchAverageRating(brandDomain as string))
      .then((res) => {
        setReviewData(res);
      })
      .catch(() => {
        // eslint-disable-next-line no-console
        console.log(
          "[TrustpilotBlock]: No Trustpilot data found. Skip rendering."
        );
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const srcImage = `${process.env.NEXT_PUBLIC_MGNL_HOST}/dam/${backGroundImage?.["@id"]}${backGroundImage?.["@path"]}`;

  const classNames = {
    avanti: {
      bgGradient: "  ",
      tlclasses: "!bg-bottom",
      trustpilotSupportRichText: " text-icon-on-dark ",
    },
    staysure: {
      bgGradient:
        "linear-gradient(0deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%),",
      // bgPosition: "lightgray 0px -255.941px / 100% 164.304% ",
      tlclasses: " lg:!bg-center !bg-bottom",
      trustpilotSupportRichText: " text-surface-neutral-option-2 ",
    },
  };

  const brand: "staysure" | "avanti" =
    (PRODUCT as "staysure" | "avanti") ?? "staysure";

  const brandClassNames = classNames[brand];

  const { bgGradient, tlclasses, trustpilotSupportRichText } = brandClassNames;

  const getBgStyles = () => {
    return {
      width: "100%",
      height: "100%",
      backgroundPosition: "center",
      background: `${bgGradient} url(${srcImage}) no-repeat`,
    };
  };

  return (
    <div
      data-testid="cover-type-support-content"
      className={` ${tlclasses} ${Styles.trustpilotWrapper} rounded-radius-xxl flex lg:flex-col !bg-cover
          flex-col-reverse text-surface-neutral-option-2 h-full lg:min-h-[586px] md:min-h-[500px] min-h-[325px]`}
      style={getBgStyles()}
    >
      <div className="m-spacing-l md:my-[64px] md:mx-[32px] h-full">
        {reviewData && (
          <TrustpilotMini
            stars={reviewData.stars}
            score={reviewData.score}
            link={navigateUrl ?? ""}
            totalReviews={reviewData.totalReviews}
          />
        )}
        <div
          data-testid="trustpilot-description"
          className="text-surface-neutral-option-1 text-center item-center text-[23px] md:text-[31px] justify-center font-header-font leading-[27.6px] md:leading-[34.1px] mt-spacing-m md:mt-[48px]"
        >
          <div className="px-[20px]">{trustpilotDescription}</div>
        </div>
        <div
          data-testid="trustpilot-support-copy-section"
          className="justify-items-center text-center"
        >
          <RichText
            richText={trustpilotSupportCopyText ?? ""}
            className={`${Styles.trustpilotSupportRichText} ${trustpilotSupportRichText}`}
          />
        </div>
      </div>
    </div>
  );
}
