/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { GetQuoteFilter } from "@/hooks/api/questions/getQuote";
import { RootState } from "@/store/root-reducer";
import { getQuoteData } from "@/store/slices/question/question.action";
import { QuoteData } from "@/types/quote-data";
import { storageAvailable } from "@/utils/common.utils";

const withQuestionQuoteData = <P extends object>(
  WrappedComponent: React.ComponentType<P>
) => {
  function WithSessionData(props: P) {
    const dispatch = useDispatch<any>();
    const { quoteResponse } = useSelector((state: RootState) => state.question);
    const stringWithoutSlashes =
      typeof window !== "undefined"
        ? window.location.pathname.replace(/\//g, "")
        : "";

    useEffect(() => {
      if (quoteResponse) {
        const { referenceId } = quoteResponse as QuoteData;

        const handleBeforeUnload = (event: { preventDefault: () => void }) => {
          if (storageAvailable()) {
            sessionStorage.setItem("pageReload", "true");
          }

          if (referenceId && storageAvailable()) {
            event.preventDefault();
            sessionStorage.removeItem("organiserTravellerId");
          }
        };

        const isOnIOS =
          navigator.userAgent.match(/iPad/i) ||
          navigator.userAgent.match(/iPhone/i);
        const eventName = isOnIOS ? "pagehide" : "beforeunload";

        window.addEventListener(eventName, handleBeforeUnload);
        return () => {
          return window.removeEventListener(eventName, handleBeforeUnload);
        };
      }

      return undefined;

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stringWithoutSlashes, quoteResponse]);

    function quoteCallCheck() {
      if (
        (storageAvailable() && sessionStorage.getItem("pageReload")) ||
        !sessionStorage.getItem("initialLoad")
      ) {
        return true;
      }

      return false;
    }

    const fetchQuoteDataComponent = async () => {
      const getQuoteFilter: GetQuoteFilter = {
        affiliate: null,
        quoteId: null,
      };

      if (quoteCallCheck()) {
        if (stringWithoutSlashes) {
          sessionStorage.setItem("initialLoad", "true");
          sessionStorage.removeItem("pageReload");
          await dispatch(getQuoteData(getQuoteFilter));
        }
      }
      return null; // Ensure the function returns null if no action is needed
    };

    useEffect(() => {
      fetchQuoteDataComponent();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <WrappedComponent {...props} />;
  }

  return WithSessionData;
};

export default withQuestionQuoteData;
