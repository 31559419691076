import { France, Portugal, SingleTrip, Spain } from "@/config/app-constant";
import { LocationFromProps } from "@/types/LocationFromType";
import SpainFlag from "@/public/images/flags/flag_spain.svg";
import FranceFlag from "@/public/images/flags/flag_france.svg";
import PortugalFlag from "@/public/images/flags/flag_portugal.svg";
import { SECONDARY_PRODUCT, PRODUCT } from "@/constants";

export const travelFromCountryData = [
  {
    code: "ES",
    addressServiceCode: "ESP",
    telCountryCode: "+34",
    flag: SpainFlag,
    displayName: "Spain",
    description:
      "The Spanish mainland and all minor outlying islands including the Balearic and Canary Islands along with Ceuta and Melilla",
  },
  {
    code: "FR",
    addressServiceCode: "FRA",
    telCountryCode: "+33",
    flag: FranceFlag,
    displayName: "France",
    description:
      "The French mainland and all minor outlying islands including Corsica",
  },
  {
    code: "PT",
    addressServiceCode: "PRT",
    telCountryCode: "+351",
    flag: PortugalFlag,
    displayName: "Portugal",
    description:
      "The Portuguese mainland and all minor outlying islands including Madeira and Azores",
  },
];
// eslint-disable-next-line import/prefer-default-export, @typescript-eslint/no-explicit-any
export function getSelectedSlideDrawer(
  fromLocation: string | undefined,
  prop: LocationFromProps
) {
  if (fromLocation === "UK1") {
    return {
      drawerContent: prop?.UKSupportDrawer,
      drawerEnable: prop?.UKLoadMoreButton,
    };
  }

  if (fromLocation === "UK3") {
    return {
      drawerContent: prop?.IOMSupportDrawer,
      drawerEnable: prop?.IOMLoadMoreButton,
    };
  }

  if (fromLocation === "UK4") {
    return {
      drawerContent: prop?.guernseySupportDrawer,
      drawerEnable: prop?.guernseyLoadMoreButton,
    };
  }

  if (fromLocation === "spain") {
    return {
      drawerContent: prop?.spainSupportDrawer,
      drawerEnable: prop?.spainLoadMoreButton,
    };
  }

  if (fromLocation === "france") {
    return {
      drawerContent: prop?.franceSupportDrawer,
      drawerEnable: prop?.franceLoadMoreButton,
    };
  }

  if (fromLocation === "portugal") {
    return {
      drawerContent: prop?.portugalSupportDrawer,
      drawerEnable: prop?.portugalLoadMoreButton,
    };
  }

  if (fromLocation === "UK5") {
    return {
      drawerContent: prop?.jerseySupportDrawer,
      drawerEnable: prop?.jerseyLoadMoreButton,
    };
  }

  return {
    drawerContent: prop?.assistantSupportDrawer,
    drawerEnable: prop?.assitantLoadMoreButton,
  };
}

const expatCountries = [Spain, France, Portugal];

export function checkExpatState(selectedCountry: string, policyType: string) {
  let expatStatus = false;
  if (policyType === SingleTrip) {
    if (expatCountries.find((country) => country === selectedCountry)) {
      expatStatus = true;
    }
  }

  return expatStatus;
}

export function getSubmitButtonState(selectedFromCountry: string) {
  let buttonDisable = true;
  const isCountryValid = travelFromCountryData.some(
    (country) => country.displayName === selectedFromCountry
  );
  if (isCountryValid) {
    buttonDisable = false;
  } else if (selectedFromCountry) {
    buttonDisable = false;
  }
  return buttonDisable;
}

export function getSubmitButtonText(
  selectedCountry: string,
  props: LocationFromProps
) {
  let buttonText = "Continue";
  const expatCountry = expatCountries.find(
    (country) => country === selectedCountry
  );
  if (!expatCountry) {
    buttonText = props?.ctaButtonText || "Continue";
  } else {
    buttonText = props?.expatButtonText || "Coutinue to Staysure Expact";
  }

  return buttonText;
}

export function getExpatInfoText(
  selectedCountry: string,
  props: LocationFromProps
) {
  let expatInforText = null;
  const expatCountry = expatCountries.find(
    (country) => country === selectedCountry
  );
  if (expatCountry) {
    if (expatCountry === "spain") {
      expatInforText = props?.spainExpatText;
    }
    if (expatCountry === "france") {
      expatInforText = props?.franceExpatText;
    }
    if (expatCountry === "portugal") {
      expatInforText = props?.portugalExpatText;
    }
  }

  return expatInforText;
}

export const jeCodes = ["JE1", "JE2", "JE3", "JE4", "JE5"];
export const gyCodes = [
  "GY1",
  "GY2",
  "GY3",
  "GY4",
  "GY5",
  "GY6",
  "GY7",
  "GY8",
  "GY9",
  "GY10",
];
export const imCodes = [
  "IM1",
  "IM2",
  "IM3",
  "IM4",
  "IM5",
  "IM6",
  "IM7",
  "IM8",
  "IM9",
  "IM86",
  "IM87",
  "IM99",
];

const locationFrom = {
  staysure: false,
  avanti: false,
  expat: true,
};

const brand: "staysure" | "avanti" | "expat" =
  SECONDARY_PRODUCT === "expat"
    ? "expat"
    : ((PRODUCT as "staysure" | "avanti") ?? "staysure");

export const showLocationFrom = locationFrom[brand];
