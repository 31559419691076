import {
  Couple,
  Family,
  Group,
  Individual,
  SingleParentFamily,
} from "@/config/app-constant";
import { PartyTypeContent } from "./schema/party-type-page-schema.utils";

// eslint-disable-next-line import/prefer-default-export
export const answersQuestionFive = [
  {
    id: 1,
    label: "An individual",
    description: "Cover for one",
    value: "INDIVIDUAL",
  },
  {
    id: 2,
    label: "A couple",
    description: "Partners who live together",
    value: "COUPLE",
  },
  {
    id: 3,
    label: "A Family",
    description: "Partner and children",
    value: "FAMILY",
  },
  {
    id: 4,
    label: "A Group",
    description: "Any number of people",
    value: "OTHER",
  },
];

export const numberOfParents = [
  {
    id: 1,
    label: "1",
    value: "SINGLE_PARENT_FAMILY",
  },
  {
    id: 2,
    label: "2",
    value: "FAMILY",
  },
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function findAnswerFamilyById(id: number) {
  return numberOfParents.find((answer) => answer.id === id);
}

export function updateAnswerLabels(props: PartyTypeContent) {
  return answersQuestionFive.map((entry) => {
    switch (entry.value) {
      case "INDIVIDUAL":
        return {
          ...entry,
          label: props?.individualHeadingText,
          description: props?.individualSubText,
        };
      case "COUPLE":
        return {
          ...entry,
          label: props?.coupleHeadingText,
          description: props?.coupleSubText,
        };
      case "FAMILY":
        return {
          ...entry,
          label: props?.familyHeadingText,
          description: props?.familySubText,
        };
      case "OTHER":
        return {
          ...entry,
          label: props?.groupHeadingText,
          description: props?.groupSubText,
        };
      default:
        return entry;
    }
  });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function findAnswerById(array: any[], id: number) {
  return array.find((answer) => answer.id === id);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function findIdByPartyValue(array: any[], value: string | undefined) {
  return array.find((answer) => answer.value === value);
}

export function getSelectedSupportContent(
  selectedAnswer: string | number | undefined,
  props: PartyTypeContent
) {
  let supportSectionContent: {
    drawerContent: object | null | undefined;
    drawerEnable: boolean | undefined;
    rightContent: object | null | undefined;
    loadMoreButtonText: string;
  } = {
    drawerContent: null,
    drawerEnable: false,
    rightContent: null,
    loadMoreButtonText: "",
  };

  if (!selectedAnswer) {
    supportSectionContent = {
      drawerContent: props.defaultSupportDrawer,
      drawerEnable: props.loadMoreButton,
      rightContent: props.assistantDescription,
      loadMoreButtonText: props.loadMoreButtonText,
    };
  }
  if (selectedAnswer === Individual) {
    supportSectionContent = {
      drawerContent: props.individualSupportDrawer,
      drawerEnable: props.individualLoadMoreButton,
      rightContent: props.individualDescription,
      loadMoreButtonText: props.loadMoreButtonText,
    };
  }
  if (selectedAnswer === Couple) {
    supportSectionContent = {
      drawerContent: props.coupleSupportDrawer,
      drawerEnable: props.coupleLoadMoreButton,
      rightContent: props.coupleDescription,
      loadMoreButtonText: props.loadMoreButtonText,
    };
  }

  if (selectedAnswer === Family || selectedAnswer === SingleParentFamily) {
    supportSectionContent = {
      drawerContent: props.familySupportDrawer,
      drawerEnable: props.familyLoadMoreButton,
      rightContent: props.familyDescription,
      loadMoreButtonText: props.loadMoreButtonText,
    };
  }

  if (selectedAnswer === Group) {
    supportSectionContent = {
      drawerContent: props.groupSupportDrawer,
      drawerEnable: props.groupLoadMoreButton,
      rightContent: props.groupDescription,
      loadMoreButtonText: props.loadMoreButtonText,
    };
  }
  return supportSectionContent;
}
