/* eslint-disable check-file/filename-naming-convention */
/* eslint-disable no-restricted-syntax */

"use client";

import { unwrapResult } from "@reduxjs/toolkit";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { useEffect, useState, useMemo } from "react";

import FadeAnimation from "@/components/atoms/animation/fade-animation";
import TransformAnimation from "@/components/atoms/animation/transform-animation";
import LabelButtonWrapper from "@/components/atoms/button-wrapper/button-wrapper";
import withMedicalFlow from "@/components/with-medical-flow-v2";
import {
  MedicalLandingPage,
  QuestionEight,
  QuestionFour,
  TravellerEdit,
  Quote,
  QuoteEdit,
  OrganizerEdit,
  travelerExceedingConfirmationModalTitle,
  travelerExceedingConfirmationModalDesc,
  travelerExceedingConfirmationModalCTABtnOne,
  travelerExceedingConfirmationModalCTABtnTwo,
} from "@/config/app-constant";
import { Routes } from "@/config/page-view-constants";
import { PRODUCT_DOMAIN } from "@/constants";
import useGtmQuestionSet from "@/hooks/gtm/useGtmQuestionSet";
import useReplaceRouterPath from "@/hooks/use-replace-router-path/use-replace-router-path";
import useResetStateTravelerEdit from "@/hooks/use-reset-state-traveler-edit/user-reset-state-traveler-edit";
import { useQuestionsState } from "@/providers/questions-state-provider";
import { useAppDispatch } from "@/store/hook";
import {
  createQuoteOnQuestion,
  updateQuoteOnQuestion,
} from "@/store/slices/question/question.action";
import { setQuestionData } from "@/store/slices/question/question.slice";
import { QuestionPayload, TravellerDetails } from "@/types/QuestionPayloadType";
import {
  ErrorObjEdited,
  FormDataType,
  TravellerContainerProps,
} from "@/types/TravellerType";
import selectLoationHookFlash from "@/utils/common.utils";
import { findAnswerQThreeById } from "@/utils/cruise.utils";
import { modifyOrganiserTraveller } from "@/utils/organiser.utils";
import {
  getRouterPathBackButton,
  getRouterPathMainButton,
} from "@/utils/page-routing.utils";
import {
  generateFromArray,
  generateTravelerDetails,
  getTravellerForm,
  modifiedExsitingArray,
  validateFrom,
} from "@/utils/traveller.utils";
import { showMessenger } from "@/utils/zendesk.utils";

import QuestionBodySection from "./traveller-answer/traveller-form";
import ActionButton from "../../action-button/action-button";
import ConfirmationsModal from "../../quote-section/edit-quote-section/confirmation-modal";
import editQuoteStyle from "../../quote-section/edit-quote-section/edit-quote.module.css";

const initialPayload: QuestionPayload = {
  type: "Quote",
  channel: "ONLINE",
  attributes: {
    domain: PRODUCT_DOMAIN,
    lastVisitedPage: "test",
  },
};

function TravellerContainer({
  usagePage,
  travellerType,
  travellerDetailsEdit,
  tripDetails,
  organizer,
  quoteResponse,
  pageContent,
  ctaRef,
  formRef,
  newMedicalFlowEnabled,
  medicalTermsAndConditions,
}: Readonly<TravellerContainerProps>) {
  const {
    questionText = "Question Text",
    ctaButtonText = "Button Text",
    inputOnePlaceholder,
    inputTwoPlaceholder,
    removeButtonLabel,
    addButtonLabel,
    inputOneErrorNumericvalues,
    inputOneErrorSpecialCharacters,
    inputOneErrorMaximumNumber,
    inputTwoErrorNumericvalues,
    inputTwoErrorSpecialCharacters,
    inputTwoErrorMaximumNumber,
    dobMinAge,
    dobMaxAge,
    popupMessage,
    questionTwoText,
    dobPlaceholder,
    coupleMinAge,
    personalStatusPlaceholder,
    nationalityPlaceholder,
    ageParentFamily,
    ageFamilyFullTimeEducation,
    ageGroupUnderTwo,
    ageSingleParentFamily,
    ageSingleParentFullTimeEducation,
    cancelEditingBtnText,
    medicalQuestionText,
    medicalQuestionInfoText,
    medicalServiceDeniedText,
    medicalYesBtnText,
    medicalNoBtnText,
  } = pageContent;
  const router = useRouter();
  const searchParams = useSearchParams();
  const editPage = searchParams.get(TravellerEdit);
  const organizerEdit = searchParams.get(OrganizerEdit);
  const dispatch = useAppDispatch();

  const { setSelectedState, setHasMedicalConditionState } = useQuestionsState();
  const [travelFormData, setTravelFormData] = useState<{
    formCount: number;
    multipleForm: boolean;
  }>({
    formCount: 1,
    multipleForm: false,
  });
  const [payload, modifyPayload] = useState<QuestionPayload>(initialPayload);
  const [isFormSubmit, setIsFormSubmit] = useState(true);
  const [hasMedicalConditions, setHasMedicalConditions] = useState<
    boolean | undefined
  >(undefined);
  const [userSavedTraveller, setUserSavedTraveller] = useState<
    FormDataType[] | undefined
  >(undefined);
  const [isSubmitBtnClicked, setSubmitBtnClicked] = useState<boolean>(false);
  const [errorObjEdited, setErrorObjEdited] = useState<
    ErrorObjEdited | undefined
  >(undefined);
  const [editModal, setEditModal] = useState(false);
  const { resetQuestionData } = useResetStateTravelerEdit();

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const pathname = usePathname();
  useReplaceRouterPath(pathname);
  const isAddOrganizerToTravellerList =
    searchParams.get(TravellerEdit) || searchParams.get(OrganizerEdit);
  const [isOpenTravelerExceedModal, setIsOpenTravelerExceedModal] =
    useState(false);

  useEffect(() => {
    if (travellerType && organizer) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const data: any = getTravellerForm(travellerType, organizer?.travelling);
      setTravelFormData(data);
    }
  }, [travellerType, organizer]);

  useEffect(() => {
    if (
      travellerDetailsEdit &&
      travellerDetailsEdit.length > 0 &&
      (organizer?.travelling === true || organizer?.travelling === false)
    ) {
      const organiserTravellerId = sessionStorage.getItem(
        "organiserTravellerId"
      );

      let travellersWithOutOrganiser = [...travellerDetailsEdit];
      if (
        organizer?.travelling ||
        (!organizer?.travelling && organiserTravellerId)
      ) {
        travellersWithOutOrganiser = modifyOrganiserTraveller(
          travellersWithOutOrganiser,
          organiserTravellerId || ""
        );
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const data: any = modifiedExsitingArray(
        travellersWithOutOrganiser,
        travellerType || "",
        organizer?.travelling
      );
      const covertToForm = generateFromArray(data, travellerType);
      setUserSavedTraveller(covertToForm);
    }
  }, [organizer, travellerDetailsEdit, travellerType]);

  useEffect(() => {
    if (userSavedTraveller) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const data: any = validateFrom(
        userSavedTraveller || [],
        travellerType || "",
        organizer?.travelling,
        inputOneErrorNumericvalues,
        inputOneErrorSpecialCharacters,
        inputOneErrorMaximumNumber,
        inputTwoErrorNumericvalues || "",
        inputTwoErrorSpecialCharacters || "",
        inputTwoErrorMaximumNumber || "",
        ageGroupUnderTwo || "",
        dobMinAge || "",
        dobMaxAge || "",
        coupleMinAge || "",
        ageSingleParentFamily || "",
        ageSingleParentFullTimeEducation || "",
        ageParentFamily || "",
        ageFamilyFullTimeEducation || ""
      );

      setErrorObjEdited(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSavedTraveller, travellerType]);

  // eslint-disable-next-line consistent-return
  const accessFormData = (formData: FormDataType[]) => {
    if (formData && formData.length > 0) {
      setIsFormSubmit(false);
      setSelectedState(true);
      setUserSavedTraveller(formData);
      const travellerDetails: TravellerDetails = {
        traveller: generateTravelerDetails(
          formData,
          organizer,
          !!isAddOrganizerToTravellerList && !!organizer?.travelling
        ),
      };

      if (quoteResponse?.referenceId) {
        modifyPayload({
          ...quoteResponse,
          attributes: {
            ...quoteResponse.attributes,
            tripDetails,
            travellerDetails,
            organiser: organizer,
            marketingChannelDetails:
              quoteResponse?.attributes?.marketingChannelDetails,
          },
        });
      } else {
        modifyPayload({
          ...payload,
          attributes: {
            ...payload.attributes,
            tripDetails,
            travellerDetails,
            organiser: organizer,
            marketingChannelDetails:
              quoteResponse?.attributes?.marketingChannelDetails,
          },
        });
      }
    } else {
      setSelectedState(false);
      setIsFormSubmit(true);
    }
  };

  useEffect(() => {
    if (newMedicalFlowEnabled) {
      if (medicalTermsAndConditions === undefined) {
        setHasMedicalConditions(undefined);
        setHasMedicalConditionState(undefined);
      } else {
        setHasMedicalConditions(!medicalTermsAndConditions);
        setHasMedicalConditionState(!medicalTermsAndConditions);
      }
    }
  }, [
    medicalTermsAndConditions,
    newMedicalFlowEnabled,
    setHasMedicalConditionState,
  ]);

  const handleMedicalQuestion = (answer: number) => {
    if (answer && newMedicalFlowEnabled) {
      const hasCondition = findAnswerQThreeById(answer)?.value;
      setHasMedicalConditionState(
        hasMedicalConditions === hasCondition ? undefined : hasCondition
      );
      setHasMedicalConditions((prev) =>
        prev === hasCondition ? undefined : hasCondition
      );
    }
  };

  useEffect(() => {
    if (newMedicalFlowEnabled) {
      // if new medical flow enabled
      const updatedMedicalTermsAndConditions =
        hasMedicalConditions === undefined ? undefined : !hasMedicalConditions;
      if (
        payload.attributes.travellerDetails?.medicalTermsAndConditions !==
        updatedMedicalTermsAndConditions
      ) {
        // if medical conditions updated
        const updatedPayload = { ...payload };
        if (updatedPayload.attributes?.travellerDetails) {
          updatedPayload.attributes.travellerDetails.medicalTermsAndConditions =
            updatedMedicalTermsAndConditions;
          modifyPayload(updatedPayload);
        }
      }
    }
  }, [payload, hasMedicalConditions, newMedicalFlowEnabled]);

  const handleSubmitButton = async () => {
    if (
      (organizerEdit || editPage) &&
      organizer?.travelling &&
      userSavedTraveller &&
      userSavedTraveller.length >= 9
    ) {
      setIsOpenTravelerExceedModal(true);
      return;
    }

    if (!isFormSubmit) {
      setSubmitBtnClicked(true);
      setButtonLoading(true);
      try {
        if ((editPage || organizerEdit) && quoteResponse?.referenceId) {
          dispatch(setQuestionData(payload as QuestionPayload));
          const travellerAddPath = `/${MedicalLandingPage}/?${editPage ? TravellerEdit : OrganizerEdit}=true`;
          router.push(travellerAddPath);
        } else {
          const result = await dispatch(
            !editPage && quoteResponse?.referenceId
              ? updateQuoteOnQuestion({
                  ...payload,
                  viewPage: Routes.Traveller,
                })
              : createQuoteOnQuestion({
                  ...payload,
                  viewPage: Routes.Traveller,
                })
          );

          const originalPromiseResult = unwrapResult(result);
          if (originalPromiseResult) {
            router.push(getRouterPathMainButton(QuestionEight));
            sessionStorage.removeItem("initialLoad");
            sessionStorage.removeItem("organiserTravellerId");
          }
        }
        setButtonLoading(false);
      } catch (error) {
        setButtonLoading(false);
      }
    }
  };

  const toLocationData = selectLoationHookFlash(
    payload?.attributes?.tripDetails
  );

  useGtmQuestionSet({
    pageTitle: QuestionEight,
    tripType: payload?.attributes?.tripDetails?.policyType,
    pageUrl: typeof window !== "undefined" ? window.location.href : "",
    destination: toLocationData.destination,
    destinationAdditions: toLocationData.destinationAdditions,
    tripExtra: payload?.attributes?.tripDetails?.goingOnCruise
      ? QuestionFour
      : undefined,
    coverStartDate: payload?.attributes?.tripDetails?.startDate || undefined,
    coverEndDate: payload?.attributes?.tripDetails?.endDate || undefined,
    travelFrom: payload?.attributes?.tripDetails?.fromLocation || undefined,
    travelGroupType: payload?.attributes?.tripDetails?.partyType || undefined,
    isSubmitBtnClicked,
  });

  const handleBackButton = () => {
    let path = getRouterPathBackButton(QuestionEight);
    if (editPage || organizerEdit) {
      path = `${path}/?${editPage ? TravellerEdit : OrganizerEdit}=true`;
    }
    router.push(path);
  };

  const handleCancelEdit = () => {
    setEditModal(true);
  };

  const renderTravelerExceedingModal = useMemo(() => {
    return (
      <ConfirmationsModal
        isOpen={isOpenTravelerExceedModal}
        handleConfirmBtnClick={() => {
          setIsOpenTravelerExceedModal(false);
        }}
        handleCancelmBtnClick={() => {
          setIsOpenTravelerExceedModal(false);
          showMessenger();
        }}
        modalTitle={travelerExceedingConfirmationModalTitle}
        modalDescription={travelerExceedingConfirmationModalDesc}
        ctaButtonLabel={travelerExceedingConfirmationModalCTABtnOne}
        backButtonLabel={travelerExceedingConfirmationModalCTABtnTwo}
      />
    );
  }, [isOpenTravelerExceedModal]);

  const renderQuestionOneSection = () => {
    const questionTextFilter =
      // eslint-disable-next-line no-nested-ternary
      organizer?.travelling === undefined
        ? ""
        : organizer?.travelling
          ? questionText
          : questionTwoText;
    return (
      <div
        data-testid="traveller-container-id"
        className=" md:ml-0 md:mr-0 ml-[-16px] mr-[-16px]"
      >
        {" "}
        <h1
          className=" font-header-font lg:text-[39px] md:text-3xl text-[26px] lg:leading-[44.85px] 
                md:leading-[34.1px] leading-[29.9px] text-text-title md:text-left text-center"
        >
          {questionTextFilter}
        </h1>
        <QuestionBodySection
          tripDetails={{}}
          inputOnePlaceholder={inputOnePlaceholder}
          inputTwoPlaceholder={inputTwoPlaceholder}
          removeButtonLabel={removeButtonLabel}
          addButtonLabel={addButtonLabel}
          formNo={travelFormData?.formCount}
          multipleForm={travelFormData?.multipleForm}
          handleSubmitButton={accessFormData}
          inputOneErrorNumericvalues={inputOneErrorNumericvalues}
          inputOneErrorSpecialCharacters={inputOneErrorSpecialCharacters}
          inputOneErrorMaximumNumber={inputOneErrorMaximumNumber}
          inputTwoErrorNumericvalues={inputTwoErrorNumericvalues}
          inputTwoErrorSpecialCharacters={inputTwoErrorSpecialCharacters}
          inputTwoErrorMaximumNumber={inputTwoErrorMaximumNumber}
          dobMinAge={dobMinAge}
          dobMaxAge={dobMaxAge}
          popupMessage={popupMessage}
          dobPlaceholder={dobPlaceholder}
          travellerType={travellerType}
          coupleMinAge={coupleMinAge}
          personalStatusPlaceholder={personalStatusPlaceholder}
          nationalityPlaceholder={nationalityPlaceholder}
          ageParentFamily={ageParentFamily}
          ageFamilyFullTimeEducation={ageFamilyFullTimeEducation}
          ageGroupUnderTwo={ageGroupUnderTwo}
          travellerDetailsEdit={userSavedTraveller}
          organizer={organizer}
          ageSingleParentFamily={ageSingleParentFamily}
          ageSingleParentFullTimeEducation={ageSingleParentFullTimeEducation}
          errorObjEdited={errorObjEdited}
          newMedicalFlowEnabled={newMedicalFlowEnabled}
          hasMedicalConditions={hasMedicalConditions}
          handleMedicalQuestion={handleMedicalQuestion}
          medicalQuestionText={medicalQuestionText ?? ""}
          medicalQuestionInfoText={medicalQuestionInfoText ?? ""}
          medicalServiceDeniedText={medicalServiceDeniedText ?? ""}
          medicalYesBtnText={medicalYesBtnText ?? ""}
          medicalNoBtnText={medicalNoBtnText ?? ""}
        />
      </div>
    );
  };

  return (
    <div
      data-testid="traveller-test-id"
      className={`rounded-radius-xxl 
        bg-surface-neutral-option-1 rounded-b-radius-xxl  
        md:pt-spacing-xxl md:pb-spacing-xxl pt-spacing-m pb-0 px-spacing-m h-full`}
    >
      <div className="lg:w-[414px] md:w-[400px] w-full mx-auto relative h-full flex flex-col justify-between">
        {(editPage || organizerEdit) && (
          <div>
            <LabelButtonWrapper
              hierarchy="tertiary"
              label={cancelEditingBtnText ?? "Cancel editing"}
              id="cancel-edit-travellers"
              className={`${editQuoteStyle.cancelEditButton}`}
              onClick={handleCancelEdit}
            />
            <ConfirmationsModal
              isOpen={editModal}
              handleConfirmBtnClick={() => {
                router.push(`/${Quote}/?${QuoteEdit}=true`);
                resetQuestionData();
              }}
              handleCancelmBtnClick={() => {
                setEditModal(false);
              }}
            />
          </div>
        )}
        <TransformAnimation animationRef={formRef} currentPage={usagePage}>
          {renderQuestionOneSection()}
          {renderTravelerExceedingModal}
        </TransformAnimation>

        <FadeAnimation animationRef={ctaRef}>
          <ActionButton
            page={usagePage}
            ctaButtonText={ctaButtonText}
            disableMain={
              newMedicalFlowEnabled
                ? !(!isFormSubmit && hasMedicalConditions === false)
                : isFormSubmit
            }
            isLoading={buttonLoading}
            submitButtonClick={handleSubmitButton}
            backButtonClick={handleBackButton}
          />
        </FadeAnimation>
      </div>
    </div>
  );
}
export default withMedicalFlow(TravellerContainer);
