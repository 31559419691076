import axios from "axios";

import { BASE_URL } from "@/constants";

const baseURL = BASE_URL;

const axiosCMSInstance = axios.create({
  baseURL,
});

export default axiosCMSInstance;
