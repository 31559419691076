import Image from "next/image";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Dropdown, DropdownItem } from "staysure-component-library";

import { RootState } from "@/store/store";
import { LocationFromContainerProps } from "@/types/LocationFromType";
import {
  travelFromCountryData,
  getSubmitButtonState,
} from "@/utils/question-location-from.utils";

import classes from "./location-from-container.module.css";
import styles from "../question.module.css";

function LocationFromContainer({
  questionOneText,
  questionOnePlaceHolder,
  buttonDisable,
  setFromCountry,
}: Readonly<LocationFromContainerProps>) {
  const question = useSelector((state: RootState) => state.question);
  const fromCountry =
    question.quoteResponse?.attributes.tripDetails?.fromLocation;

  const [travellingFrom, setTravellingFrom] = useState<string | undefined>("");

  useEffect(() => {
    if (fromCountry && fromCountry !== "") {
      const selectedCountry = travelFromCountryData.find(
        (country) => country.code === fromCountry
      );
      if (selectedCountry) {
        setTravellingFrom(selectedCountry?.displayName);
      } else {
        setTravellingFrom("");
      }
    }
  }, [fromCountry]);

  const handleCountryChange = useCallback(
    (selectedCountry: string) => {
      setTravellingFrom(selectedCountry);
      setFromCountry(selectedCountry);
      buttonDisable(getSubmitButtonState(selectedCountry));
    },
    [setFromCountry, buttonDisable]
  );

  const renderQuestionTwoSection = () => {
    return (
      <div className=" w-full" data-testid="renderQuestionTwoSection-test-id">
        <div className=" ">
          <h1
            className=" font-header-font lg:text-[39px] md:text-3xl text-[26px] lg:leading-[44.85px]
                md:leading-[34.1px] leading-[29.9px] text-text-title md:text-left text-center"
          >
            {(questionOneText && questionOneText) ??
              "Where are you travelling from???"}
          </h1>
          <div
            className={` ${styles.country_wrapper} lg:w-[414px] md:w-[400px] w-full mx-auto md:h-[56px] mt-spacing-l`}
          >
            <Dropdown
              id="country-select-dropdown"
              isDispTextValueAfterSelect
              defaultValue={travellingFrom && travellingFrom}
              onSelectionChange={(value) =>
                handleCountryChange(value as string)
              }
              placeholderText={questionOnePlaceHolder ?? "Select a country"}
              className={`${classes.dropdown_btn} py-spacing-m py px-spacing-m flex space-between w-full`}
              listBoxStyle={{
                outline: "none",
                margin: "0px 0px 0px 0px",
                minWidth: "unset !important",
                boxShadow: "0px 0px 12px 0px rgba(15, 23, 42, 0.11)",
              }}
              listClassName={classes.listStyle}
              unorderedListClassName="lg:w-[414px] md:w-[400px] w-[calc(100vw-80px)] !md:ml-0 !ml-[-1px] !mt-[-4px] !border-border-subdued !rounded-radius-lg
             !px-spacing-m !py-spacing-xs"
            >
              {travelFromCountryData?.map((country) => (
                <DropdownItem value={country.displayName} key={country.code}>
                  <div className="flex gap-spacing-xs items-start leading-[27px] text-[18px] font-bold p-spacing-xs text-text-on-primary">
                    <Image
                      src={country.flag}
                      alt={country.displayName}
                      width={24}
                      height={24}
                    />
                    <span>
                      {country.displayName}

                      {" - "}
                      <span className="font-normal">{country.description}</span>
                    </span>
                  </div>
                </DropdownItem>
              ))}
            </Dropdown>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      data-testid="question-two-from-test-id"
      className={` rounded-radius-xxl bg-surface-neutral-option-1 rounded-b-radius-xxl pb-spacing-l md:pb-spacing-xxl `}
    >
      <div
        className=" lg:max-w-[414px] md:max-w-[400px] max-w-[unset] w-full mx-auto relative
      flex flex-col justify-between lg:min-h-fit"
      >
        {renderQuestionTwoSection()}
      </div>
    </div>
  );
}
export default LocationFromContainer;
