/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react";

import { ADDRESS_API_KEY, ADDRESS_API_URL } from "@/constants";
import axioInstance from "@/libs/axios";
import { AddressType, QuoteCountryType } from "@/types/AddressTypes";
import { Nationality } from "@/types/QuestionPayloadType";
import { QuoteData } from "@/types/quote-data";
import { sendTargetCountryToAddressService } from "@/utils/organiser.utils";

export const useAddressApi = (
  searchQuery: string | null,
  targetCountry: string | null | undefined
) => {
  const [addresses, setAddresses] = useState<AddressType[]>([]);

  const fetchAddresses = async () => {
    try {
      const requestBody = {
        id: "12233444455",
        type: "addressLookup",
        attributes: {
          search: searchQuery,
          disablePostCodeFilter: true,
        },
      };
      if (sendTargetCountryToAddressService) {
        Object.assign(requestBody.attributes, {
          targetCountry: targetCountry,
        });
      }
      const config = {
        headers: {
          "x-api-key": `${ADDRESS_API_KEY}`,
        },
        withCredentials: false,
      };

      const response = await axioInstance.post(
        `${ADDRESS_API_URL}/address/find`,
        requestBody,
        config
      );
      setAddresses(response.data.data);
    } catch (error) {
      setAddresses([]);
    }
  };

  return [addresses, fetchAddresses] as [AddressType[], () => void];
};

export const useSearchCountry = (searchQuery: string | null) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [addresses, setAddresses] = useState<QuoteCountryType[]>([]);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [loading, setLoading] = useState<boolean>(true);

  const fetchAddresses = async () => {
    try {
      const requestBody = {
        type: "countryLookup",
        id: "12345",
        attributes: {
          search: searchQuery,
          filter: "*",
          limit: 20,
        },
      };

      const config = {
        headers: {
          "x-api-key": `${ADDRESS_API_KEY}`,
        },
        withCredentials: false,
      };
      setLoading(true);
      const response = await axioInstance.post(
        `${ADDRESS_API_URL}/country/find`,
        requestBody,
        config
      );
      setAddresses(response.data.data);
      setLoading(false);
    } catch (error) {
      setAddresses([]);
      setLoading(false);
    }
  };

  return [addresses, loading, fetchAddresses] as [
    QuoteCountryType[],
    boolean,
    () => void,
  ];
};

export const useFetchAddressData = (searchQuery: string) => {
  const [addresse, setAddresse] = useState<AddressType[]>([]);

  const fetchAddresses = async () => {
    try {
      const requestBody = {
        id: "randomUUID",
        type: "addressLookup",
        attributes: {
          search: searchQuery,
          disablePostCodeFilter: true,
        },
      };
      const config = {
        headers: {
          "x-api-key": `${ADDRESS_API_KEY}`,
        },
        withCredentials: false,
        data: requestBody,
      };
      const response = await axioInstance.get(
        `${ADDRESS_API_URL}/address/${searchQuery}`,
        config
      );
      setAddresse(response.data.data);
    } catch (error) {
      setAddresse([]);
    }
  };

  return [addresse, fetchAddresses] as [AddressType[], () => void];
};

// for organiser and traveller nationality list
export const useFetchNationalityData = (isFetchForExpat: boolean) => {
  const [countries, setCountries] = useState<Nationality[]>([]);

  // current date
  const currentDate = new Date();
  const formattedDate = currentDate.toISOString().split("T")[0];

  useEffect(() => {
    if (!isFetchForExpat) return;

    const fetchCountries = async () => {
      try {
        const response = await axioInstance.get(
          `/referenceData/${formattedDate}`
        );
        setCountries(response.data.data.attributes.nationality);
      } catch (error) {
        setCountries([]);
      }
    };

    fetchCountries();
  }, [isFetchForExpat]);

  return countries;
};

export default function useSaveAddressDeatails(
  payloadData: AddressType,
  quote: QuoteData
) {
  const [response, setResponse] = useState<any>(null);

  const saveAddressDeatails = async () => {
    if (payloadData && quote) {
      const payLoad: any = {
        data: {
          type: quote.type,
          channel: "ONLINE",
          version: quote?.version,
          attributes: {
            domain: quote?.attributes?.domain,
            organiser: {
              postCode: payloadData.attributes.postalCode,
              firstLineOfAddress: payloadData.attributes.line1,
              secondLineOfAddress: payloadData.attributes?.line2
                ? payloadData.attributes.line2
                : "",
              city: payloadData.attributes.city,
              country: payloadData.attributes.countryIso2,
            },
          },
        },
      };
      try {
        const res = await axioInstance.patch(
          `quote/${quote.referenceId}/organiser`,
          payLoad
        );
        setResponse(res.data.data);
      } catch (err) {
        throw new Error("Error");
      }
    }
  };

  return [response, saveAddressDeatails];
}
