"use client";

import { unwrapResult } from "@reduxjs/toolkit";
import dynamic from "next/dynamic";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { useEffect, useMemo, useState } from "react";

import FadeAnimation from "@/components/atoms/animation/fade-animation";
import TransformAnimation from "@/components/atoms/animation/transform-animation";
import {
  AnnualMultiTrip,
  CoverEdit,
  NotSure,
  QuestionTwo,
  SingleTrip,
} from "@/config/app-constant";
import { Routes } from "@/config/page-view-constants";
import { PRODUCT_DOMAIN } from "@/constants";
import useGtmQuestionSet from "@/hooks/gtm/useGtmQuestionSet";
import useReplaceRouterPath from "@/hooks/use-replace-router-path/use-replace-router-path";
import useResetStateTravelerEdit from "@/hooks/use-reset-state-traveler-edit/user-reset-state-traveler-edit";
import useIsClient from "@/hooks/useIsClient/use-is-client";
import useMediaQuery from "@/hooks/useMediaQuery/use-media-query";
import { useQuestionsState } from "@/providers/questions-state-provider";
import { useAppDispatch } from "@/store/hook";
import {
  saveQuestionDataBFF,
  sessionCheck,
} from "@/store/slices/question/question.action";
import { setQuestionData } from "@/store/slices/question/question.slice";
import { CoverTypeContainerProps } from "@/types/CoverType";
import { QuestionPayload, TripDetails } from "@/types/QuestionPayloadType";
import { modifyPayloadSave } from "@/utils/common.utils";
import {
  findAnswerById,
  modifyCoverTypeButtonArray,
} from "@/utils/cover-type.utils";
import { getRouterPathMainButton } from "@/utils/page-routing.utils";

import QuestionOneSection from "./question-one-section/question-one-section";
import ActionButton from "../../action-button/action-button";
import editQuoteStyle from "../../quote-section/edit-quote-section/edit-quote.module.css";

const CoverTypeModal = dynamic(
  () => import("./cover-type-modal/cover-type-modal")
);
const initialPayload: QuestionPayload = {
  type: "IntermediateData",
  channel: "ONLINE",
  attributes: {
    domain: PRODUCT_DOMAIN,
    lastVisitedPage: "test",
  },
};

function CoverTypeContainer({
  tripDetails,
  quoteResponse,
  formRef,
  ctaRef,
  usagePage,
  pageContent,
}: Readonly<CoverTypeContainerProps>) {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const editPage = searchParams.get(CoverEdit);
  const partnership = searchParams.get("partnership");
  const isBcuk = partnership === "bcuk";

  const {
    removeNotSureButton,
    ctaButtonOneLabel,
    ctaButtonTwoLabel,
    ctaButtonThreeLabel,
    cancelEditingBtnText = "Cancel Editing",
    questionText,
    modalEnable,
    modalTitle,
    backgroundModalImageUrl,
    primaryButtonText,
    secondaryButtonText,
    detailTitleOne,
    detailDescriptionOne,
    detailTitleTwo,
    detailDescriptionTwo,
    detailTitleThree,
    detailDescriptionThree,
    coverTypeSupportText,
    backgroundModalMobileImageUrl,
    ctaButtonText,
    modalDescription,
  } = pageContent;

  // State
  const [payload, setPayload] = useState<QuestionPayload>(initialPayload);
  const [isSubmitBtnClicked, setIsSubmitBtnClicked] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [singleTypeModalOpen, setSingleTypeModalOpen] = useState(false);

  // Hooks
  useReplaceRouterPath(pathname);
  useGtmQuestionSet({
    pageTitle: QuestionTwo,
    tripType: payload?.attributes?.tripDetails?.policyType ?? "",
    pageUrl: typeof window !== "undefined" ? window.location.href : "",
    isSubmitBtnClicked,
  });
  const dispatch = useAppDispatch();
  const { selected, setSelectedState } = useQuestionsState();
  const { resetQuestionData } = useResetStateTravelerEdit();
  const isClient = useIsClient();
  const isdesktop = useMediaQuery("(min-width: 600px)");

  // Memoized Values
  const showondesktop: string = useMemo(
    () => isdesktop.toString(),
    [isdesktop]
  );
  const {
    marketingChannelUrl,
    searchEngReferrer,
  }: { marketingChannelUrl: string; searchEngReferrer: string } =
    useMemo(() => {
      if (!isClient) {
        return { marketingChannelUrl: "", searchEngReferrer: "" };
      }
      return {
        marketingChannelUrl: window.location.href,
        searchEngReferrer: window.document.referrer,
      };
    }, [isClient]);

  const modifiedCoverTypeButtonArray = useMemo(() => {
    return modifyCoverTypeButtonArray(
      ctaButtonOneLabel,
      ctaButtonTwoLabel,
      ctaButtonThreeLabel,
      removeNotSureButton
    );
  }, [
    ctaButtonOneLabel,
    ctaButtonThreeLabel,
    ctaButtonTwoLabel,
    removeNotSureButton,
  ]);

  // Side Effects
  useEffect(() => {
    const getModifiedQuoteResponse = () => {
      if (isBcuk) {
        return {
          ...quoteResponse,
          attributes: {
            ...quoteResponse?.attributes,
            partnerCode: "BCUK",
          },
        };
      }
      return {
        ...quoteResponse,
        attributes: quoteResponse?.attributes || {
          domain: PRODUCT_DOMAIN,
        },
      };
    };
    const modifyTripDetails = (selectedPolicy: string | undefined) => {
      let tripDetailsModified: TripDetails = {
        ...tripDetails,
        policyType: selectedPolicy,
      };
      if (
        tripDetails?.policyType &&
        tripDetails?.policyType !== selectedPolicy
      ) {
        tripDetailsModified = {
          ...tripDetails,
          policyType: selectedPolicy,
          startDate: undefined,
          endDate: undefined,
        };
        if (selectedPolicy === SingleTrip || selectedPolicy === NotSure) {
          tripDetailsModified.area = undefined;
        }

        if (selectedPolicy === AnnualMultiTrip) {
          tripDetailsModified.toLocation = undefined;
        }
      } else {
        tripDetailsModified = {
          ...tripDetails,
          policyType: selectedPolicy,
        };
        if (tripDetails?.area === "" || tripDetails?.area?.toString() === "0") {
          tripDetailsModified.area = undefined;
        }
      }
      return tripDetailsModified;
    };
    if (selected) {
      const selectedPolicy = findAnswerById(selected as number)?.value;
      const modifiedQuoteResponse = getModifiedQuoteResponse();
      const tripDetailsModified = modifyTripDetails(selectedPolicy);
      const marketingChannelDetails = {
        marketingChannelData: marketingChannelUrl,
        referrer: searchEngReferrer,
      };
      setPayload(
        modifyPayloadSave(
          PRODUCT_DOMAIN === "STS" ? modifiedQuoteResponse : quoteResponse,
          tripDetailsModified,
          marketingChannelDetails,
          isBcuk
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, tripDetails]);

  // Handlers
  const accessSelectedAnswer = (answerNo: number) => {
    // de select the previous selected answer
    if (selected === answerNo) {
      setSelectedState(0);
      return;
    }

    if (answerNo === 1 && modalEnable) {
      setSingleTypeModalOpen(true);
    }

    setSelectedState(answerNo);
  };
  const modalConfirmBtnClick = (answerNo: number) => {
    if (answerNo) {
      setSelectedState(2);
    }
    setSingleTypeModalOpen(false);
  };
  const closeModalSingleType = () => setSingleTypeModalOpen(false);
  const handleRedirectAfterSubmission = async () => {
    if (editPage && quoteResponse?.referenceId) {
      router.push(`${getRouterPathMainButton(QuestionTwo)}/?${CoverEdit}=true`);
    } else if (!quoteResponse?.referenceId) {
      const questionData = await dispatch(
        saveQuestionDataBFF({ ...payload, viewPage: Routes.CoverType })
      );
      const result = unwrapResult(questionData);
      if (result) router.push(getRouterPathMainButton(QuestionTwo));
    } else {
      dispatch(sessionCheck());
      router.push(getRouterPathMainButton(QuestionTwo));
    }
  };
  const handleSubmitButton = async () => {
    if (selected !== 0) {
      setIsSubmitBtnClicked(true);
      setButtonLoading(true);
      try {
        dispatch(setQuestionData(payload));
        await handleRedirectAfterSubmission();
      } finally {
        setButtonLoading(false);
      }
    }
  };
  const handleCancelEdit = () => setModalOpen(true);

  const editFinishStyle = editPage ? editQuoteStyle.editFinishButton : "";

  return (
    <div
      data-testid="question-one-test-id"
      className={` bg-surface-neutral-option-1 rounded-b-radius-xxl  
           md:py-spacing-xxl py-spacing-m px-spacing-m h-full`}
    >
      <div
        data-testid="renderQuestionOneSection-test-id"
        className=" lg:max-w-[414px] md:max-w-[400px] w-full mx-auto relative h-full flex flex-col justify-between"
      >
        <TransformAnimation animationRef={formRef} currentPage={usagePage}>
          <QuestionOneSection
            editPage={editPage}
            cancelEditingBtnText={cancelEditingBtnText}
            questionText={questionText}
            coverTypeSupportText={coverTypeSupportText}
            modalOpen={modalOpen}
            selected={selected}
            // quoteLoading={quoteLoading}
            setModalOpen={setModalOpen}
            handleCancelEdit={handleCancelEdit}
            accessSelectedAnswer={accessSelectedAnswer}
            resetQuestionData={resetQuestionData}
            answerArray={modifiedCoverTypeButtonArray}
            isClient={isClient}
          />
        </TransformAnimation>
        {singleTypeModalOpen && (
          <CoverTypeModal
            isOpen={singleTypeModalOpen}
            modalTitle={modalTitle}
            backgroundModalImageUrl={backgroundModalImageUrl}
            primaryButtonText={primaryButtonText}
            secondaryButtonText={secondaryButtonText}
            detailTitleOne={detailTitleOne}
            detailDescriptionOne={detailDescriptionOne}
            detailTitleTwo={detailTitleTwo}
            detailDescriptionTwo={detailDescriptionTwo}
            detailTitleThree={detailTitleThree}
            detailDescriptionThree={detailDescriptionThree}
            backgroundModalMobileImageUrl={backgroundModalMobileImageUrl}
            showOnDesktop={showondesktop ?? "false"}
            modalDescription={modalDescription ?? ""}
            modalConfirmBtnClick={modalConfirmBtnClick}
            closeModal={closeModalSingleType}
          />
        )}
        <FadeAnimation animationRef={ctaRef}>
          <div className={`${editFinishStyle}`}>
            <ActionButton
              page={usagePage}
              ctaButtonText={ctaButtonText}
              disableMain={selected === 0}
              isLoading={buttonLoading}
              showBackBtn={false}
              submitButtonClick={handleSubmitButton}
            />
          </div>
        </FadeAnimation>
      </div>
    </div>
  );
}
export default CoverTypeContainer;
