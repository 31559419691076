import { unwrapResult } from "@reduxjs/toolkit";
import { useRouter, useSearchParams } from "next/navigation";
import { useEffect, useState } from "react";

import FadeAnimation from "@/components/atoms/animation/fade-animation";
import TransformAnimation from "@/components/atoms/animation/transform-animation";
import LabelButtonWrapper from "@/components/atoms/button-wrapper/button-wrapper";
import {
  AnnualMultiTrip,
  DatesEdit,
  QuestionFive,
  QuestionFour,
  Quote,
  QuoteEdit,
  CoverEdit,
  FinishEdit,
} from "@/config/app-constant";
import { Routes } from "@/config/page-view-constants";
import { PRODUCT_DOMAIN } from "@/constants";
import useGtmQuestionSet from "@/hooks/gtm/useGtmQuestionSet";
import useResetStateTravelerEdit from "@/hooks/use-reset-state-traveler-edit/user-reset-state-traveler-edit";
import { useQuestionsState } from "@/providers/questions-state-provider";
import { useAppDispatch } from "@/store/hook";
import {
  saveQuestionDataBFF,
  sessionCheck,
  updateQuoteOnQuestion,
} from "@/store/slices/question/question.action";
import { setQuestionData } from "@/store/slices/question/question.slice";
import { QuestionPayload, TripDetails } from "@/types/QuestionPayloadType";
import {
  TravelDateAnswer,
  TravelDateContainerProps,
} from "@/types/TravelDateType";
import selectLoationHookFlash, {
  modifyPayloadSave,
} from "@/utils/common.utils";
import {
  getRouterPathBackButton,
  getRouterPathMainButton,
} from "@/utils/page-routing.utils";
import { formatDate } from "@/utils/travel-date.utils";

import TravelDateAnswerPage from "./travel-date-answer/travel-date-answer-page";
import ActionButton from "../../action-button/action-button";
import ConfirmationsModal from "../../quote-section/edit-quote-section/confirmation-modal";
import editQuoteStyle from "../../quote-section/edit-quote-section/edit-quote.module.css";

const initialPayload: QuestionPayload = {
  type: "IntermediateData",
  channel: "ONLINE",
  attributes: {
    domain: PRODUCT_DOMAIN,
    lastVisitedPage: "test",
  },
};

function TravelDateContainer({
  formRef,
  ctaRef,
  ...props
}: Readonly<TravelDateContainerProps>) {
  const router = useRouter();
  const searchParams = useSearchParams();
  const editPage = searchParams.get(DatesEdit);
  const coverEdit = searchParams.get(CoverEdit);
  const [editModal, setEditModal] = useState(false);
  const dispatch = useAppDispatch();

  const { setSelectedState } = useQuestionsState();

  const [payload, modifyPayload] = useState<QuestionPayload>(initialPayload);
  const [buttonStatus, setButtonState] = useState<boolean>(true);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [isSubmitBtnClicked, setSubmitBtnClicked] = useState<boolean>(false);
  const { resetQuestionData } = useResetStateTravelerEdit();

  const accessSelectedAnswer = (answerNo: TravelDateAnswer) => {
    if (answerNo) {
      let startDateModified = "";
      let endDateModified = "";
      const { validate, startDate, endDate } = answerNo;
      if (endDate && startDate && validate) {
        setButtonState(false);
        setSelectedState(formatDate(startDate));
        startDateModified = formatDate(startDate);
        endDateModified = formatDate(endDate);
      } else {
        setSelectedState("");
        setButtonState(true);
      }
      const tripDetails: TripDetails = {
        ...props?.tripDetails,
        startDate: startDateModified,
        endDate: endDateModified,
      };

      modifyPayload(modifyPayloadSave(props?.quoteResponse, tripDetails));
    }
  };

  useEffect(() => {
    if (props?.tripDetails) {
      modifyPayload(
        modifyPayloadSave(props?.quoteResponse, props?.tripDetails)
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.tripDetails]);

  const handleSubmitButton = async () => {
    if (!buttonStatus) {
      setSubmitBtnClicked(true);
      setButtonLoading(true);
      try {
        dispatch(setQuestionData(payload as QuestionPayload));
        if ((editPage || coverEdit) && props?.quoteResponse?.referenceId) {
          const result = await dispatch(
            updateQuoteOnQuestion({ ...payload, viewPage: Routes.TravelDates })
          );
          const originalPromiseResult = unwrapResult(result);
          if (originalPromiseResult) {
            router.push(`/${Quote}`);
          }
        } else if (!props?.quoteResponse?.referenceId) {
          const questionData = await dispatch(
            saveQuestionDataBFF({ ...payload, viewPage: Routes.TravelDates })
          );
          const originalPromiseResult = unwrapResult(questionData);
          if (originalPromiseResult) {
            router.push(getRouterPathMainButton(QuestionFive));
          }
        } else {
          dispatch(sessionCheck());
          router.push(getRouterPathMainButton(QuestionFive));
        }

        setButtonLoading(false);
      } catch (error) {
        setButtonLoading(false);
      }
    }
  };
  const toLocationData = selectLoationHookFlash(
    payload?.attributes?.tripDetails
  );
  useGtmQuestionSet({
    pageTitle: QuestionFive,
    tripType: props?.policyType,
    pageUrl: typeof window !== "undefined" ? window.location.href : "",
    destination: toLocationData.destination,
    destinationAdditions: toLocationData.destinationAdditions,
    tripExtra: payload?.attributes?.tripDetails?.goingOnCruise
      ? QuestionFour
      : undefined,
    coverStartDate: payload?.attributes?.tripDetails?.startDate || undefined,
    coverEndDate: payload?.attributes?.tripDetails?.endDate || undefined,
    travelFrom: payload?.attributes?.tripDetails?.fromLocation || undefined,
    isSubmitBtnClicked,
  });

  const handleBackButton = () => {
    if (coverEdit) {
      const path = getRouterPathBackButton(QuestionFour);
      router.push(`${path}/?${CoverEdit}=true`);
    } else {
      router.push(getRouterPathBackButton(QuestionFive));
    }
  };
  const handleCancelEdit = () => {
    setEditModal(true);
  };

  const renderQuestionFourSection = () => {
    return (
      <TransformAnimation animationRef={formRef} currentPage={props.usagePage}>
        <div data-testid="traveller-date-content" className="">
          {" "}
          {(editPage || coverEdit) && (
            <LabelButtonWrapper
              hierarchy="tertiary"
              label={
                props?.pageContent?.cancelEditingBtnText ?? "Cancel Editing"
              }
              id="cancel-edit-travel-date"
              className={`${editQuoteStyle.cancelEditButton}`}
              onClick={handleCancelEdit}
            />
          )}
          <h1
            className=" font-header-font lg:text-[39px] md:text-3xl text-[26px] lg:leading-[44.85px] 
                md:leading-[34.1px] leading-[29.9px] text-text-title md:text-left text-center"
          >
            {props?.policyType === AnnualMultiTrip
              ? props?.pageContent?.amtQuestionText
              : props?.pageContent?.stQuestionText}
          </h1>
          <TravelDateAnswerPage
            policyType={props?.policyType}
            toLocation={props?.toCountry}
            fromLocation={props?.fromCountry}
            setSelectedAnswer={(answer: TravelDateAnswer) => {
              accessSelectedAnswer(answer);
            }}
            sdtButtonText={props?.pageContent?.sdtButtonText}
            sdtHeaderText={props?.pageContent?.sdtHeaderText}
            sdtSubText={props?.pageContent?.sdtSubText}
            thcButtonText={props?.pageContent?.thcButtonText}
            thcHeaderText={props?.pageContent?.thcHeaderText}
            thcSubText={props?.pageContent?.thcSubText}
            tripStartDateSaved={props?.tripStartDate}
            tripEndDateSaved={props?.tripEndDate}
            departureDateErrorPastDateST={
              props?.pageContent?.departureDateErrorPastDateST
            }
            departureDateErrorFutureDateST={
              props?.pageContent?.departureDateErrorFutureDateST
            }
            returnDateErrorPastDateST={
              props?.pageContent?.returnDateErrorPastDateST
            }
            returnDateErrorFutureDateST={
              props?.pageContent?.returnDateErrorFutureDateST
            }
            departureDateErrorPastDateAMT={
              props?.pageContent?.departureDateErrorPastDateAMT
            }
            departureDateErrorFutureDateAMT={
              props?.pageContent?.departureDateErrorFutureDateAMT
            }
            departureReturnDateError={
              props?.pageContent?.departureReturnDateError
            }
            cancelBtnText={props?.pageContent?.cancelBtnText}
          />
        </div>
      </TransformAnimation>
    );
  };
  const editFinishStyle = editPage ? editQuoteStyle.editFinishButton : "";
  const ctaBtnLabel =
    editPage || coverEdit
      ? props?.pageContent?.finishEditingBtnText || FinishEdit
      : props?.pageContent?.ctaButtonText || "Continue";

  return (
    <div
      data-testid="traveller-date-test-id"
      className={`rounded-radius-xxl 
        bg-surface-neutral-option-1 rounded-b-radius-xxl  
             md:py-spacing-xxl py-spacing-m px-spacing-m h-full`}
    >
      <div className=" md:max-w-[414px] w-full mx-auto relative h-full flex flex-col justify-between">
        {renderQuestionFourSection()}

        <FadeAnimation animationRef={ctaRef}>
          <span className={`${editFinishStyle}`}>
            <ActionButton
              page={props?.usagePage}
              ctaButtonText={ctaBtnLabel}
              disableMain={buttonStatus}
              isLoading={buttonLoading}
              showBackBtn={!editPage}
              submitButtonClick={handleSubmitButton}
              backButtonClick={handleBackButton}
            />
          </span>
        </FadeAnimation>
      </div>
      <ConfirmationsModal
        isOpen={editModal}
        handleConfirmBtnClick={() => {
          router.push(`/${Quote}/?${QuoteEdit}=true`);
          resetQuestionData();
        }}
        handleCancelmBtnClick={() => {
          setEditModal(false);
        }}
      />
    </div>
  );
}
export default TravelDateContainer;
