import { CruiseContent } from "./schema/cruise-page-schema.utils";

export const answersQuestionThree = [
  {
    id: 1,
    label: "Yes",
    value: true,
  },
  {
    id: 2,
    label: "No",
    value: false,
  },
];

export function findAnswerQThreeById(id: number) {
  return answersQuestionThree.find((answer) => answer.id === id);
}

export function findAnswerQThreeByValue(value: boolean | undefined) {
  return answersQuestionThree.find((answer) => answer.value === value);
}

export function getSelectedSupportContent(
  selectedAnswer: string | number | undefined,
  props: CruiseContent
) {
  let supportSectionContent: {
    drawerContent: object | null | undefined;
    drawerEnable: boolean | null;
    rightContent: object | null | undefined;
    loadMoreButtonText: string;
  } = {
    drawerContent: null,
    drawerEnable: false,
    rightContent: null,
    loadMoreButtonText: "",
  };

  if (selectedAnswer === 0) {
    supportSectionContent = {
      drawerContent: props.defaultSupportDrawer,
      drawerEnable: false,
      rightContent: props.assistantDescription,
      loadMoreButtonText: props.defaultLoadMoreButtonText,
    };
  }
  if (selectedAnswer === 1) {
    supportSectionContent = {
      drawerContent: props.answerOneSupportDrawer,
      drawerEnable: false,
      rightContent: props.answerOneDescription,
      loadMoreButtonText: props.answerOneLoadMoreButtonText,
    };
  }
  if (selectedAnswer === 2) {
    supportSectionContent = {
      drawerContent: props.answerTwoSupportDrawer,
      drawerEnable: false,
      rightContent: props.answerTwoDescription,
      loadMoreButtonText: props.answerTwoLoadMoreButtonText,
    };
  }
  return supportSectionContent;
}
