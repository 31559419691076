/* eslint-disable check-file/filename-naming-convention */
/* eslint-disable no-restricted-syntax */

"use client";

import { unwrapResult } from "@reduxjs/toolkit";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { useEffect, useState } from "react";

import FadeAnimation from "@/components/atoms/animation/fade-animation";
import TransformAnimation from "@/components/atoms/animation/transform-animation";
import LabelButtonWrapper from "@/components/atoms/button-wrapper/button-wrapper";
import withMedicalFlow from "@/components/with-medical-flow-v2";
import {
  Individual,
  MedicalLandingPage,
  QuestionEight,
  QuestionFour,
  QuestionSeven,
  TravellerEdit,
  Quote,
  QuoteEdit,
  OrganizerEdit,
  FinishEdit,
} from "@/config/app-constant";
import { Routes } from "@/config/page-view-constants";
import { PRODUCT_DOMAIN } from "@/constants";
import useGtmQuestionSet from "@/hooks/gtm/useGtmQuestionSet";
import useReplaceRouterPath from "@/hooks/use-replace-router-path/use-replace-router-path";
import useResetStateTravelerEdit from "@/hooks/use-reset-state-traveler-edit/user-reset-state-traveler-edit";
import { useQuestionsState } from "@/providers/questions-state-provider";
import { useAppDispatch } from "@/store/hook";
import {
  createQuoteOnQuestion,
  saveQuestionDataBFF,
  sessionCheck,
  updateQuoteOnQuestion,
} from "@/store/slices/question/question.action";
import { setQuestionData } from "@/store/slices/question/question.slice";
import {
  FormDataTypeOrganiser,
  OrganiserContainerProps,
} from "@/types/OrganiserType";
import {
  Organiser,
  QuestionPayload,
  TravellerDetails,
} from "@/types/QuestionPayloadType";
import selectLoationHookFlash from "@/utils/common.utils";
import {
  isFormDataCompleteOrganiser,
  modifyOrganiserForm,
} from "@/utils/organiser.utils";
import {
  getRouterPathBackButton,
  getRouterPathMainButton,
} from "@/utils/page-routing.utils";
import {
  jeCodes,
  gyCodes,
  imCodes,
  showLocationFrom,
} from "@/utils/question-location-from.utils";
import { formatDate, generateTravelerDetails } from "@/utils/traveller.utils";

import OrganiserForm from "./organiser-answer/organiser-form";
import ActionButton from "../../action-button/action-button";
import ConfirmationsModal from "../../quote-section/edit-quote-section/confirmation-modal";
import editQuoteStyle from "../../quote-section/edit-quote-section/edit-quote.module.css";

const initialPayload: QuestionPayload = {
  type: "Quote",
  channel: "ONLINE",
  attributes: {
    domain: PRODUCT_DOMAIN,
    lastVisitedPage: "test",
  },
};

function OrganiserContainer(props: Readonly<OrganiserContainerProps>) {
  const router = useRouter();
  const searchParams = useSearchParams();
  const editPage = searchParams.get(TravellerEdit);
  const organizerEdit = searchParams.get(OrganizerEdit);
  const [editModal, setEditModal] = useState(false);
  const dispatch = useAppDispatch();
  const [payload, modifyPayload] = useState<QuestionPayload>(initialPayload);
  const [isFormSubmit, setIsFormSubmit] = useState(true);
  const [isSubmitBtnClicked, setSubmitBtnClicked] = useState<boolean>(false);
  const [showErrors, setShowErrors] = useState<boolean>(false);
  const { resetQuestionData } = useResetStateTravelerEdit();
  const { usagePage, ctaRef, formRef, newMedicalFlowEnabled } = props;

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [organiserFormSaved, setOrganiserFormSaved] = useState<
    FormDataTypeOrganiser | undefined
  >(undefined);

  const [isOrganizerTraveling, setIsOrganizerTraveling] = useState(false);
  const pathname = usePathname();
  useReplaceRouterPath(pathname);
  const isAddOrganizerToTravellerList =
    searchParams.get(TravellerEdit) || searchParams.get(OrganizerEdit);

  useEffect(() => {
    if (props?.organiser) {
      const modifiedOrganiserData: FormDataTypeOrganiser = modifyOrganiserForm(
        props?.organiser
      );
      if (newMedicalFlowEnabled) {
        if (props?.travellerDetails?.medicalTermsAndConditions) {
          modifiedOrganiserData.hasMedicalConditions = false;
        }
      }
      setOrganiserFormSaved(modifiedOrganiserData);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props?.organiser,
    props?.travellerDetails?.medicalTermsAndConditions,
    newMedicalFlowEnabled,
  ]);

  useEffect(() => {
    if (props?.tripDetails) {
      // eslint-disable-next-line react/destructuring-assignment
      const { tripDetails, organiser } = props;
      let payloadModify: QuestionPayload;
      if (props?.quoteResponse?.referenceId) {
        let attributes = {
          ...props?.quoteResponse?.attributes,
          tripDetails,
          organiser,
        };

        if (props?.travellerType === Individual && organiser?.travelling) {
          const travellerDetails = {
            traveller: generateTravelerDetails(
              [],
              organiser,
              !!isAddOrganizerToTravellerList
            ),
          };
          attributes = {
            ...attributes,
            ...{ travellerDetails },
          };
        }

        payloadModify = {
          ...props?.quoteResponse,
          attributes: {
            ...attributes,
          },
        };
      } else {
        payloadModify = {
          ...initialPayload,
          attributes: {
            ...initialPayload.attributes,
            partnerCode: props?.quoteResponse?.attributes?.partnerCode,
            tripDetails,
            organiser,
          },
        };
      }
      modifyPayload(payloadModify);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.organiser, props?.tripDetails]);

  const handleTravelingFromLocation = (e: string): string => {
    const getLocation = (code: string) => {
      switch (true) {
        case jeCodes.some((jeCode) => code.startsWith(jeCode)):
          return "UK5";
        case gyCodes.some((gyCode) => code.startsWith(gyCode)):
          return "UK4";
        case imCodes.some((imCode) => code.startsWith(imCode)):
          return "UK3";
        default:
          return "UK1";
      }
    };
    return getLocation(e);
  };

  // eslint-disable-next-line consistent-return
  const accessFormData = (formData: FormDataTypeOrganiser) => {
    if (isFormDataCompleteOrganiser(formData, props?.travellerType)) {
      setIsFormSubmit(false);

      const fromCountryLocation = handleTravelingFromLocation(
        formData.postCode
      );
      const organiser: Organiser = {
        title: formData.value1,
        firstName: formData.value2,
        lastName: formData.value3,
        nationalityId: formData?.nationalityValue,
        dateOfBirth: formatDate(formData?.dateValue),
        city: formData.city,
        country: formData.country,
        postCode: formData.postCode,
        firstLineOfAddress: formData.firstLineOfAddress,
        secondLineOfAddress: formData.secondLineOfAddress,
        travelling: formData.travelling,
        email: formData?.email,
        telephone: formData?.telephone,
      };

      setIsOrganizerTraveling(!!formData.travelling);
      const { travellerType } = props;
      const { quoteResponse, tripDetails } = props;
      const { attributes: payloadAttributes } = payload;
      let attributes = {
        tripDetails: {
          ...tripDetails,
          ...(showLocationFrom === false && {
            fromLocation: fromCountryLocation,
          }),
        },
        organiser,
        marketingChannelDetails:
          quoteResponse?.attributes?.marketingChannelDetails,
      };
      const organiserTravellerId = sessionStorage.getItem(
        "organiserTravellerId"
      );

      if (travellerType === Individual && organiser.travelling) {
        if (quoteResponse?.referenceId) {
          const traveller =
            quoteResponse?.attributes?.travellerDetails?.traveller;
          if (traveller && traveller.length > 0) {
            const travellingOrganiser = traveller.find(
              (obj) => obj?.sequence === 1
            );
            sessionStorage.setItem(
              "organiserTravellerId",
              travellingOrganiser?.encryptedId || ""
            );
          }
        }
        const travellerDetails: TravellerDetails = {
          traveller: generateTravelerDetails(
            [],
            organiser,
            !!isAddOrganizerToTravellerList
          ),
        };
        if (newMedicalFlowEnabled) {
          travellerDetails.medicalTermsAndConditions =
            formData.hasMedicalConditions === undefined
              ? undefined
              : formData.hasMedicalConditions === false;
        }

        attributes = {
          ...attributes,
          ...{ travellerDetails },
        };
      } else if (travellerType === Individual && !organiser.travelling) {
        const travellerDetails: TravellerDetails = {
          traveller:
            quoteResponse?.attributes?.travellerDetails?.traveller ?? [],
        };
        if (newMedicalFlowEnabled) {
          travellerDetails.medicalTermsAndConditions = quoteResponse?.attributes
            ?.organiser?.travelling
            ? undefined
            : quoteResponse?.attributes?.travellerDetails
                ?.medicalTermsAndConditions;
        }

        attributes = {
          ...attributes,
          ...{ travellerDetails },
        };
      } else if (quoteResponse?.referenceId) {
        attributes = {
          ...quoteResponse.attributes,
          ...attributes,
        };
      }

      if (
        quoteResponse?.attributes.organiser?.travelling &&
        organiser.travelling === false &&
        !organiserTravellerId
      ) {
        if (quoteResponse?.attributes?.travellerDetails) {
          const traveller =
            quoteResponse?.attributes?.travellerDetails?.traveller;
          if (traveller && traveller.length > 0) {
            const travellingOrganiser = traveller.find(
              (obj) => obj?.sequence === 1
            );
            if (travellingOrganiser) {
              sessionStorage.setItem(
                "organiserTravellerId",
                travellingOrganiser?.encryptedId || ""
              );
            }
          }
        }
      }

      if (
        !quoteResponse?.attributes.organiser?.travelling &&
        organiser.travelling &&
        !organiserTravellerId
      ) {
        if (quoteResponse?.attributes?.travellerDetails) {
          const traveller =
            quoteResponse?.attributes?.travellerDetails?.traveller;
          if (traveller && traveller.length > 0) {
            sessionStorage.setItem("organiserTravellerId", "notapply");
          }
        }
      }

      const modifiedAttributes = {
        ...payloadAttributes,
        ...attributes,
      };
      modifyPayload({
        ...(quoteResponse?.referenceId ? quoteResponse : payload),
        attributes: modifiedAttributes,
      });
    } else {
      setIsFormSubmit(true);
    }
  };

  const handleSubmitButton = async () => {
    if (!isFormSubmit) {
      setSubmitBtnClicked(true);
      setButtonLoading(true);
      try {
        const isIndividualTraveller = props?.travellerType === Individual;
        const hasOrganiserTravelling =
          payload?.attributes?.organiser?.travelling;
        const hasReferenceId = props?.quoteResponse?.referenceId;

        if (isIndividualTraveller && hasOrganiserTravelling) {
          if ((editPage || organizerEdit) && hasReferenceId) {
            dispatch(setQuestionData(payload as QuestionPayload));
            router.push(
              `/${MedicalLandingPage}/?${editPage ? TravellerEdit : OrganizerEdit}=true`
            );
          } else {
            const result = await dispatch(
              !editPage && !organizerEdit && hasReferenceId
                ? updateQuoteOnQuestion({
                    ...payload,
                    viewPage: Routes.Organiser,
                  })
                : createQuoteOnQuestion({
                    ...payload,
                    viewPage: Routes.Organiser,
                  })
            );
            const originalPromiseResult = unwrapResult(result);
            if (originalPromiseResult) {
              router.push(getRouterPathMainButton(QuestionEight));
              sessionStorage.removeItem("initialLoad");
              sessionStorage.removeItem("organiserTravellerId");
            }
          }
        } else if (organizerEdit && hasReferenceId && !hasOrganiserTravelling) {
          const result = await dispatch(
            updateQuoteOnQuestion({
              ...payload,
              viewPage: Routes.Organiser,
            })
          );
          const originalPromiseResult = unwrapResult(result);
          if (originalPromiseResult) {
            router.push(`/${Quote}`);
          }
        } else if (!hasReferenceId) {
          dispatch(setQuestionData(payload as QuestionPayload));
          const questionData = await dispatch(
            saveQuestionDataBFF({ ...payload, viewPage: Routes.Organiser })
          );
          const originalPromiseResult = unwrapResult(questionData);
          if (originalPromiseResult) {
            router.push(getRouterPathMainButton(QuestionSeven));
          }
        } else {
          if (hasReferenceId && !(editPage || organizerEdit)) {
            dispatch(setQuestionData(payload as QuestionPayload));
            const questionData = await dispatch(
              saveQuestionDataBFF({ ...payload, viewPage: Routes.Organiser })
            );
            const originalPromiseResult = unwrapResult(questionData);
            if (originalPromiseResult) {
              router.push(getRouterPathMainButton(QuestionSeven));
            }
          } else {
            dispatch(setQuestionData(payload as QuestionPayload));
          }
          dispatch(sessionCheck());
          let path = getRouterPathMainButton(QuestionSeven);
          if (editPage || organizerEdit) {
            path = `${path}/?${editPage ? TravellerEdit : OrganizerEdit}=true`;
          }
          router.push(path);
        }
        setButtonLoading(false);
      } catch (error) {
        setButtonLoading(false);
      }
    } else {
      setShowErrors(true);
    }
  };

  const toLocationData = selectLoationHookFlash(
    payload?.attributes?.tripDetails
  );

  useGtmQuestionSet({
    pageTitle: QuestionSeven,
    tripType: payload?.attributes?.tripDetails?.policyType,
    pageUrl: typeof window !== "undefined" ? window.location.href : "",
    destination: toLocationData.destination,
    destinationAdditions: toLocationData.destinationAdditions,
    tripExtra: payload?.attributes?.tripDetails?.goingOnCruise
      ? QuestionFour
      : undefined,
    coverStartDate: payload?.attributes?.tripDetails?.startDate || undefined,
    coverEndDate: payload?.attributes?.tripDetails?.endDate || undefined,
    travelFrom: payload?.attributes?.tripDetails?.fromLocation || undefined,
    travelGroupType: payload?.attributes?.tripDetails?.partyType || undefined,
    isSubmitBtnClicked,
  });

  const handleBackButton = () => {
    let path = getRouterPathBackButton(QuestionSeven);
    if (editPage) {
      path = `${path}/?${TravellerEdit}=true`;
    }
    router.push(path);
  };

  const handleCancelEdit = () => {
    setEditModal(true);
  };

  const renderQuestionOneSection = () => {
    return (
      <div data-testid="organiser-support-content">
        {" "}
        {(editPage || organizerEdit) && (
          <div>
            <LabelButtonWrapper
              hierarchy="tertiary"
              label={
                props?.pageContent?.cancelEditingBtnText || "Cancel editing"
              }
              id="cancel-edit-organiser"
              className={`${editQuoteStyle.cancelEditButton}`}
              onClick={handleCancelEdit}
            />
            <ConfirmationsModal
              isOpen={editModal}
              handleConfirmBtnClick={() => {
                router.push(`/${Quote}/?${QuoteEdit}=true`);
                resetQuestionData();
              }}
              handleCancelmBtnClick={() => {
                setEditModal(false);
              }}
            />
          </div>
        )}
        <h1
          className=" font-header-font lg:text-[39px] md:text-3xl text-[26px] lg:leading-[44.85px] 
          md:leading-[34.1px] leading-[29.9px] text-text-title md:text-left text-center md:px-0 px-spacing-m"
        >
          {props?.pageContent?.questionText && props?.pageContent?.questionText}
        </h1>
        <OrganiserForm
          inputOnePlaceholder={props?.pageContent?.inputOnePlaceholder}
          inputTwoPlaceholder={props?.pageContent?.inputTwoPlaceholder}
          handleSubmitButton={accessFormData}
          inputOneErrorNumericvalues={
            props?.pageContent?.inputOneErrorNumericvalues
          }
          inputOneErrorSpecialCharacters={
            props?.pageContent?.inputOneErrorSpecialCharacters
          }
          inputOneErrorMaximumNumber={
            props?.pageContent?.inputOneErrorMaximumNumber
          }
          inputTwoErrorNumericvalues={
            props?.pageContent?.inputTwoErrorNumericvalues
          }
          inputTwoErrorSpecialCharacters={
            props?.pageContent?.inputTwoErrorSpecialCharacters
          }
          inputTwoErrorMaximumNumber={
            props?.pageContent?.inputTwoErrorMaximumNumber
          }
          dobMinAge={props?.pageContent?.dobMinAge}
          dobMaxAge={props?.pageContent?.dobMaxAge}
          dobPlaceholder={props?.pageContent?.dobPlaceholder}
          travellerType={props?.travellerType}
          personalStatusPlaceholder={
            props?.pageContent?.personalStatusPlaceholder
          }
          nationalityPlaceholder={props?.pageContent?.nationalityPlaceholder}
          ageFamilyFullTimeEducation={
            props?.pageContent?.ageFamilyFullTimeEducation
          }
          ageSingleParentFullTimeEducation={
            props?.pageContent?.ageSingleParentFullTimeEducation
          }
          addressLineOne={props?.pageContent?.addressLineOne}
          addressLineTwo={props?.pageContent?.addressLineTwo}
          TownCityPlaceholder={props?.pageContent?.TownCityPlaceholder}
          countryPlaceholder={props?.pageContent?.countryPlaceholder}
          postcodePlaceholder={props?.pageContent?.postcodePlaceholder}
          addressBlockButton={props?.addressBlockButton}
          addressLineOneErrorMasg={props?.pageContent?.addressLineOneErrorMasg}
          addressLineTwoErrorMasg={props?.pageContent?.addressLineTwoErrorMasg}
          adressInputPlaceholder={props?.pageContent?.adressInputPlaceholder}
          TownCityErrorMasg={props?.pageContent?.TownCityErrorMasg}
          postcodeErrorMasg={props?.pageContent?.postcodeErrorMasg}
          questionTwoText={props?.pageContent?.questionTwoText || ""}
          enterAddressButton={props?.pageContent?.enterAddressButton}
          searchByPostCodeButton={props?.pageContent?.searchByPostCodeButton}
          organiserFormSaved={organiserFormSaved}
          dobMaxLimit={props?.pageContent?.dobMaxLimit}
          usagePage={usagePage}
          newMedicalFlowEnabled={newMedicalFlowEnabled}
          medicalQuestionText={props?.pageContent?.medicalQuestionText ?? ""}
          medicalQuestionInfoText={
            props?.pageContent?.medicalQuestionInfoText ?? ""
          }
          medicalServiceDeniedText={
            props?.pageContent?.medicalServiceDeniedText ?? ""
          }
          medicalYesBtnText={props?.pageContent?.medicalYesBtnText ?? ""}
          medicalNoBtnText={props?.pageContent?.medicalNoBtnText ?? ""}
          alertMessageIncompleteSectionSingle={
            props?.pageContent?.alertMessageIncompleteSectionSingle
          }
          alertMessageIncompleteSection={
            props?.pageContent?.alertMessageIncompleteSection
          }
          alertMessageLinkSectionSingle={
            props?.pageContent?.alertMessageLinkSectionSingle
          }
          alertMessageLinkSection={props?.pageContent?.alertMessageLinkSection}
          showErrors={showErrors}
        />
      </div>
    );
  };

  const { hasMedicalCondition } = useQuestionsState();

  return (
    <div
      data-testid="question-organiser-test-id"
      className={`rounded-radius-xxl 
        bg-surface-neutral-option-1 rounded-b-radius-xxl  
        md:pt-spacing-xxl md:pb-spacing-xxl pt-spacing-m pb-0 px-spacing-m h-full`}
    >
      <div className="lg:w-[414px] md:w-[400px] w-full mx-auto relative h-full flex flex-col justify-between">
        <TransformAnimation animationRef={formRef} currentPage={usagePage}>
          {renderQuestionOneSection()}
        </TransformAnimation>
        <FadeAnimation animationRef={ctaRef}>
          <ActionButton
            page={props?.usagePage}
            ctaButtonText={
              organizerEdit && !isOrganizerTraveling
                ? props?.pageContent?.finishEditingBtnText || FinishEdit
                : props?.pageContent?.ctaButtonText || ""
            }
            disableMain={hasMedicalCondition}
            isLoading={buttonLoading}
            submitButtonClick={handleSubmitButton}
            backButtonClick={handleBackButton}
            showBackBtn={!organizerEdit}
          />
        </FadeAnimation>
      </div>
    </div>
  );
}
export default withMedicalFlow(OrganiserContainer);
