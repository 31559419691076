import {
  AnnualMultiTrip,
  Europe,
  EuropeHR,
  EuropeLR,
  NotSure,
  SingleTrip,
  UKOnly,
  Worldwide,
  WorldwideHR,
  WorldwideLR,
  fcdoCountryList,
} from "@/config/app-constant";
import { PRODUCT, SECONDARY_PRODUCT } from "@/constants";
import { QuoteCountryType } from "@/types/AddressTypes";
import { OptionListProps, LocationToProps } from "@/types/LocationToType";
import { STCountryArray } from "@/types/QuestionPayloadType";

export const brand: "staysure" | "avanti" | "expat" =
  SECONDARY_PRODUCT === "expat"
    ? "expat"
    : ((PRODUCT as "staysure" | "avanti") ?? "staysure");

export const countryData = [
  {
    code: "UK1",
    displayName: "United Kingdom",
    description: "United Kingdom",
  },
  {
    code: "UK3",
    displayName: "Isle of Man",
    description: "Isle of Man",
  },
  {
    code: "UK4",
    displayName: "Guernsey",
    description: "Guernsey",
  },
  {
    code: "UK5",
    displayName: "Jersey",
    description: "Jersey",
  },
  {
    code: "spain",
    displayName: "Spain",
    description: "Spain",
  },
  {
    code: "france",
    displayName: "France",
    description: "France",
  },
  {
    code: "portugal",
    displayName: "Portugal",
    description: "Portugal",
  },
];
// eslint-disable-next-line import/prefer-default-export, @typescript-eslint/no-explicit-any, consistent-return
export function getSelectedLocationToSupport(
  toLocation: string | undefined,
  prop: LocationToProps,
  toLocationText: string | undefined,
  policyType: string
) {
  let drawerObj: {
    drawerContent: object | null | undefined;
    drawerEnable: boolean | undefined;
    rightContent: object | null | undefined;
    loadMoreButtonText: string | undefined;
  } = {
    drawerContent: null,
    drawerEnable: false,
    rightContent: null,
    loadMoreButtonText: "",
  };
  if (toLocation === UKOnly && policyType === AnnualMultiTrip) {
    drawerObj = {
      drawerContent: prop?.ukOnlyAMTSupportDrawer,
      drawerEnable: prop?.ukOnlyAMTLoadMoreButton,
      rightContent: prop?.ukOnlyAMTDescription,
      loadMoreButtonText: prop?.loadMoreButtonText,
    };
  }

  if (toLocation === Europe && policyType === AnnualMultiTrip) {
    drawerObj = {
      drawerContent: prop?.europeAMTSupportDrawer,
      drawerEnable: prop?.europeLoadMoreButton,
      rightContent: prop?.europeAMTDescription,
      loadMoreButtonText: prop?.loadMoreButtonText,
    };
  }

  if (toLocation === Worldwide && policyType === AnnualMultiTrip) {
    drawerObj = {
      drawerContent: prop?.worldWideAMTSupportDrawer,
      drawerEnable: prop?.worldWideLoadMoreButton,
      rightContent: prop?.worldWideAMTDescription,
      loadMoreButtonText: prop?.loadMoreButtonText,
    };
  }

  if (toLocation === EuropeHR && policyType === AnnualMultiTrip) {
    drawerObj = {
      drawerContent: prop?.europeYesAMTSupportDrawer,
      drawerEnable: prop?.europeYesLoadMoreButton,
      rightContent: prop?.europeYesAMTDescription,
      loadMoreButtonText: prop?.loadMoreButtonText,
    };
  }

  if (toLocation === EuropeLR && policyType === AnnualMultiTrip) {
    drawerObj = {
      drawerContent: prop?.europeNoAMTSupportDrawer,
      drawerEnable: prop?.europeNoLoadMoreButton,
      rightContent: prop?.europeNoAMTDescription,
      loadMoreButtonText: prop?.loadMoreButtonText,
    };
  }

  if (toLocation === WorldwideHR && policyType === AnnualMultiTrip) {
    drawerObj = {
      drawerContent: prop?.worldWideYesAMTSupportDrawer,
      drawerEnable: prop?.worldWideYesLoadMoreButton,
      rightContent: prop?.worldWideYesAMTDescription,
      loadMoreButtonText: prop?.loadMoreButtonText,
    };
  }

  if (toLocation === WorldwideLR && policyType === AnnualMultiTrip) {
    drawerObj = {
      drawerContent: prop?.worldWideNoAMTSupportDrawer,
      drawerEnable: prop?.worldWideNoLoadMoreButton,
      rightContent: prop?.worldWideNoAMTDescription,
      loadMoreButtonText: prop?.loadMoreButtonText,
    };
  }
  if ((toLocation || toLocationText) && policyType !== AnnualMultiTrip) {
    drawerObj = {
      drawerContent: prop?.supportSTDrawer,
      drawerEnable: prop?.supportSTLoadMoreButton,
      rightContent: prop?.assistantSTDescription,
      loadMoreButtonText: prop?.loadMoreButtonText,
    };
  }

  if (policyType === AnnualMultiTrip && !toLocation) {
    drawerObj = {
      drawerContent: prop?.amtSupportDrawer,
      drawerEnable: prop?.amtLoadMoreButton,
      rightContent: prop?.amtAssistantDescription,
      loadMoreButtonText: prop?.loadMoreButtonText,
    };
  }

  if (!toLocation && !toLocationText && policyType !== AnnualMultiTrip) {
    drawerObj = {
      drawerContent: prop?.locationToSupportDrawer,
      drawerEnable: prop?.supportSTLoadMoreButton,
      rightContent: prop?.locationToAssistantDescription,
      loadMoreButtonText: prop?.loadMoreButtonText,
    };
  }

  return drawerObj;
}

export function getSubmitButtonState(
  policyType: string,
  selectedToCountry: string
) {
  let buttonDisable = true;
  if (
    selectedToCountry &&
    selectedToCountry !== "" &&
    selectedToCountry !== Worldwide &&
    selectedToCountry !== Europe
  ) {
    buttonDisable = false;
  }
  if (policyType === AnnualMultiTrip) {
    if (
      selectedToCountry &&
      (selectedToCountry === UKOnly ||
        selectedToCountry === WorldwideHR ||
        selectedToCountry === WorldwideLR ||
        selectedToCountry === EuropeHR ||
        selectedToCountry === EuropeLR)
    ) {
      buttonDisable = false;
    }
  }

  return buttonDisable;
}

// eslint-disable-next-line import/prefer-default-export
export const answersQTwoWhereGoing = [
  {
    id: 1,
    label: "UK only",
    value: "UK",
  },
  {
    id: 2,
    label: "Europe",
    value: "EUROPE",
  },
  {
    id: 3,
    label: "Worldwide",
    value: "WORLD",
  },
];

// Filter answers based on brand
export const filteredAnswersQTwoWhereGoing =
  brand === "expat"
    ? answersQTwoWhereGoing.filter((answer) => answer.value !== "UK")
    : answersQTwoWhereGoing;

export function findAnswerById(id: number) {
  return answersQTwoWhereGoing.find((answer) => answer.id === id);
}

export function findCountryIdByValue(country: string | undefined) {
  return answersQTwoWhereGoing.find((answer) => answer.value === country);
}

export const answersQTwoEurope = [
  {
    id: 1,
    label: "Yes",
    value: "EUROPE_HR",
  },
  {
    id: 2,
    label: "No",
    value: "EUROPE_LR",
  },
];

export function findAnswerForEuropeId(id: number) {
  return answersQTwoEurope.find((answer) => answer.id === id);
}
export function findIdByEuropeValue(value: string | undefined) {
  return answersQTwoEurope.find((answer) => answer.value === value);
}

export const answersQTwoWorldwide = [
  {
    id: 1,
    label: "Yes",
    value: "WORLD_HR",
  },
  {
    id: 2,
    label: "No",
    value: "WORLD_LR",
  },
];

export function findAnswerWorldwideById(id: number) {
  return answersQTwoWorldwide.find((answer) => answer.id === id);
}

export function findIdByWorldWideValue(value: string | undefined) {
  return answersQTwoWorldwide.find((answer) => answer.value === value);
}

export function generateTagSearchArray(countryArray: QuoteCountryType[]) {
  const modifyCountryList: OptionListProps[] = [];
  // eslint-disable-next-line array-callback-return
  if (countryArray) {
    // eslint-disable-next-line array-callback-return
    countryArray.map((country) => {
      const object: OptionListProps = {
        displayLabel: country?.attributes?.displayName,
        parentName: country?.attributes?.countryName,
        parentCode: country?.attributes?.countryCode,
        type: country?.attributes?.countryCode,
      };
      modifyCountryList.push(object);
    });
  }

  return modifyCountryList;
}

export function checkSupportSectionEnablePolicy(
  policyType: string,
  selectedToCountry: string | undefined,
  tagSearchField: string | undefined
) {
  let status = false;
  if (!selectedToCountry && !tagSearchField) {
    if (policyType === SingleTrip) {
      status = true;
    }
    if (policyType === NotSure) {
      status = true;
    }
  }

  return status;
}

export function getQuestionTwoState(
  toLocation: string | undefined,
  policyType: string | undefined,
  countryList: STCountryArray | undefined
) {
  let objectModified = {};

  if (toLocation && policyType === AnnualMultiTrip) {
    const selectedToCountry = toLocation.split("_")[0];

    if (!selectedToCountry) {
      objectModified = {
        toCountrySelected: "Uk only",
        valueSelected: toLocation,
        countryArray: [],
      };
    } else {
      objectModified = {
        toCountrySelected: selectedToCountry,
        valueSelected: toLocation,
        countryArray: [],
      };
    }
  } else {
    const countryArray: OptionListProps[] = [];
    const countryArry = toLocation?.split(",");
    if (countryList && countryList.length > 0) {
      // eslint-disable-next-line array-callback-return
      countryArry?.map((country: string, index: number) => {
        const object: OptionListProps = {
          displayLabel: countryList?.[index]?.attributes?.countryName || "",
          parentName: countryList?.[index]?.attributes?.countryName,
          parentCode: countryList?.[index]?.attributes?.countryCode,
          type: "Country",
        };
        countryArray.push(object);
      });
      objectModified = {
        toCountrySelected: null,
        valueSelected: null,
        countryArray,
      };
    }
  }

  return objectModified;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function findDuplicatesByProperty<T extends { [key: string]: any }>(
  arr: T[],
  property: keyof T
): T[] {
  const seen: { [key: string]: boolean } = {};
  const duplicates: T[] = [];

  arr.forEach((obj) => {
    const key = obj[property];
    if (seen[key]) {
      duplicates.push(obj);
    } else {
      seen[key] = true;
    }
  });

  return duplicates;
}

export function findFCDOCountry(value: string | undefined) {
  return fcdoCountryList.find((country: string) => country === value);
}

export function checkArrayContainString(array: [] = []) {
  let containsString = false;

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < array.length; i++) {
    if (typeof array[i] === "string") {
      containsString = true;
      break;
    }
  }

  return containsString;
}

const brandImpact = {
  staysure: true,
  avanti: true,
  expat: false,
};

const selectedBrandImpact = brandImpact[brand];

export default selectedBrandImpact;
